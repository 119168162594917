import React from 'react'
import { Modal, ModalBody, Spinner } from 'reactstrap'
import { connect } from 'react-redux'

function SyncConversations(props) {
  return (
    <Modal tabIndex="-1" isOpen={props.modalSyncConversations} centered>
      <ModalBody>
        <div className="modal_encerrar text-center p-4">
          <p className="font-size-12 mb-3 text-truncate">
            Sincronizando conversa do Whatsapp. Por favor, aguarde.
          </p>
          <Spinner color="dark" name="mySpinner">
            {''}
          </Spinner>
        </div>
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  const { modalSyncConversations } = state.Layout
  return { modalSyncConversations }
}

export default connect(mapStateToProps, {})(SyncConversations)
