import { setAlertMessage } from "../../redux/actions"
import store from "../../redux/store"

export const checkEmailProviderForActiveInbox = (emailConversations) => {

    let newEmailsActiveInbox = []
    const state = store.getState()
    const { activeEmailInbox } = state.Email

    const newEmailsNotActiveInbox = emailConversations.filter(emailConversation => {
        if (emailConversation.identifier_email !== activeEmailInbox) {
            return emailConversation
        }
        newEmailsActiveInbox.push(emailConversation)
    })


    if (newEmailsNotActiveInbox.length > 0) {
        const identifier = newEmailsNotActiveInbox[0].identifier_email
        const totalEmails = newEmailsNotActiveInbox.length 
        const message = `Você recebou ${totalEmails} novos emails para conta ${identifier}`
        store.dispatch(setAlertMessage(message, 'info'))
        console.log(message)
    }

    return newEmailsActiveInbox
}
