import { setAlertMessage, setStateChange } from '../../redux/actions'
import store from '../../redux/store'

const getChannelIndex = (identifier, idIntegracao) => {
  let state = store.getState()
  const { id_empresa } = state.Auth.user
  const { websockets } = state.WebService
  const channelIndex = websockets.findIndex((socket) => {
    return (
      socket.options.query.identifier === identifier &&
      socket.options.query.id_empresa === id_empresa &&
      socket.options.query.id_integracao === idIntegracao
    )
  })

  if (channelIndex === -1) {
    store.dispatch(setStateChange('DISCONNECTED'))
  }

  console.log('getChannelIndex', {identifier, idIntegracao})

  return channelIndex
}

export default getChannelIndex
