import store from '../redux/store'
const checkAckTabRestriction = (tabName, message) => {
  const state = store.getState()
  const user = state.Auth.user

  let stateName = 'users'

  if(tabName === 'myConversationsTab'){
    stateName = 'my_chats'
  }

  if(tabName === 'waitingRoomTab'){
    stateName = 'waiting_room'
  }

  
  console.log('stateName', stateName)

  let indexChat = undefined
  let chatId = ''

  if (message.id.fromMe) {
    chatId = message.to
  } else if (message.from) {
    chatId = message.from
  } else {
    chatId = message.chatId
  }

  indexChat = state.Chat[stateName].findIndex(
    (user) =>
      user.id === chatId &&
      user.idEmpresa === message.idEmpresa &&
      user.idIntegracao === message.idIntegracao,
  )
  

  console.log('indexChat', indexChat)


  if (indexChat !== -1) {
    const userChat = state.Chat[stateName][indexChat]


    console.log('userChat', userChat)

    switch (tabName) {
      case 'allChatsTab':
        const data = {indexChat, stateName}
        console.log('data allChatsTab=>',data)
        return data
      case 'myConversationsTab':
        if (userChat && userChat.idResponsavel === user.id_usuario) {
          const data = {indexChat, stateName}
          console.log('data myConversationsTab=>',data)
          return data
        }
        break
      case 'waitingRoomTab':
        if (userChat && userChat.idResponsavel === null) {
          const data = {indexChat, stateName}
          console.log('data waitingRoomTab=>',data)
          return data
        }
        break
      default:
        break
    }
  }
  return false
}

export default checkAckTabRestriction
