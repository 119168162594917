export const CHAT_USER = "CHAT_USER";
export const CHAT_INIT = "CHAT_INIT";

export const REQUEST_CHECK_LEAD = "REQUEST_CHECK_LEAD";
export const REQUEST_CHECK_EMAIL_LEAD = "REQUEST_CHECK_EMAIL_LEAD";

export const ACTIVE_CHAT_INDEX = "ACTIVE_CHAT_INDEX";
export const ACTIVE_USER_ID = "ACTIVE_USER_ID";
export const ACTIVE_RECIPIENT_ID = "ACTIVE_RECIPIENT_ID";
export const ACTIVE_CHAT_MONGO_ID = "ACTIVE_CHAT_MONGO_ID";

export const FULL_USER = "FULL_USER";
export const SET_MY_CHATS = "SET_MY_CHATS";
export const SET_WAITING_ROOM_CHATS = "SET_WAITING_ROOM_CHATS";
export const NEW_MESSAGE = "NEW_MESSAGE";
export const SET_NEW_CHAT_MESSAGES = "SET_NEW_CHAT_MESSAGES";
export const SET_EARLIER_MESSAGES = "SET_EARLIER_MESSAGES";
export const SET_ALL_MESSAGES = "SET_ALL_MESSAGES";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const NEW_CHAT = "NEW_CHAT";

export const SET_NEW_LEAD_CONVERSATION = "SET_NEW_LEAD_CONVERSATION";

export const ERROR_SENDING_MESSAGE = "ERROR_SENDING_MESSAGE";

export const PLAY_NOTIFICATION_SOUND = "PLAY_NOTIFICATION_SOUND";

export const REMOVE_CHATS = "REMOVE_CHATS";
export const REMOVE_CHAT = "REMOVE_CHAT";
export const REMOVE_MY_CHAT = "REMOVE_MY_CHAT";
export const REMOVE_WAITING_ROOM_CHAT = "REMOVE_WAITING_ROOM_CHAT";

export const CONVERSATION_UPDATE_IN_DATABASE =
  "CONVERSATION_UPDATE_IN_DATABASE";
export const CONVERSATION_UPDATE_WAITING_ROOM_IN_DATABASE =
  "CONVERSATION_UPDATE_WAITING_ROOM_IN_DATABASE";
export const CONVERSATION_UPDATE_MY_CHAT_IN_DATABASE =
  "CONVERSATION_UPDATE_MY_CHAT_IN_DATABASE";
export const CHAT_UPDATE_IN_DATABASE = "CHAT_UPDATE_IN_DATABASE";

export const REMOVE_DELETED_MESSAGE = "REMOVE_DELETED_MESSAGE";

export const ADD_LOGGED_USER = "ADD_LOGGED_USER";

export const CREATE_GROUP = "CREATE_GROUP";

export const SET_PROFILE_USER_WHATSAPP = "SET_PROFILE_USER_WHATSAPP";

export const STATE_CHANGE = "STATE_CHANGE";

export const ADD_AUDIO_SOURCE = "ADD_AUDIO_SOURCE";
export const ADD_IMAGE_SOURCE = "ADD_IMAGE_SOURCE";

export const ACK_CHANGE = "ACK_CHANGE";

export const SET_COUNT_MY_CHATS = "SET_COUNT_MY_CHATS";
export const SET_COUNT_WAITING_ROOM = "SET_COUNT_WAITING_ROOM";

export const REPLY_CHAT_ID = "REPLY_CHAT_ID";

export const SET_UNSENT_MESSAGE = "SET_UNSENT_MESSAGE";

export const DELETE_UNSENT_MESSAGE = "DELETE_UNSENT_MESSAGE";

export const SET_FAILURE_EVENT = "SET_FAILURE_EVENT";

export const EMIT_MESSAGE_WEBSOCKET = "EMIT_MESSAGE_WEBSOCKET";

export const REQUEST_FILE = "REQUEST_FILE";

export const REQUEST_CHAT_MESSAGES = "REQUEST_CHAT_MESSAGES";
export const REQUEST_PROFILE_PICTUTE_TO_CHAT =
  "REQUEST_PROFILE_PICTUTE_TO_CHAT";

export const REQUEST_EARLIER_MESSAGES = "REQUEST_EARLIER_MESSAGES";
export const REQUEST_LOAD_ALL_MESSAGES = "REQUEST_LOAD_ALL_MESSAGES";

export const FILTERED_CHAT = "FILTERED_CHAT";

export const ADD_TAGS = "ADD_TAGS";

export const FETCH_CHATS_IN_DB = "FETCH_CHATS_IN_DB";

export const FETCH_MY_CHATS_IN_DB = "FETCH_MY_CHATS_IN_DB";

export const FETCH_WAITING_ROOM_CHATS_IN_DB = "FETCH_WAITING_ROOM_CHATS_IN_DB";

export const FETCH_NEW_CHAT = "FETCH_NEW_CHAT";
export const CLEAR_FETCH_NEW_CHAT = "CLEAR_FETCH_NEW_CHAT";

export const UPDATE_CHAT_IN_DB = "UPDATE_CHAT_IN_DB";
export const UPDATE_CHAT_LEAD_IN_DB = "UPDATE_CHAT_LEAD_IN_DB";

export const END_CONVERSATION = "END_CONVERSATION";
export const END_CONVERSATION_BOT = "END_CONVERSATION_BOT";
export const TAKE_UP_CONVERSATION = "TAKE_UP_CONVERSATION";
export const MARK_UNSEEN_MESSAGE = "MARK_UNSEEN_MESSAGE";

export const SET_LEAD_DATA = "SET_LEAD_DATA";

export const REQUEST_PARSE_ATTENDANT_OPTIONS =
  "REQUEST_PARSE_ATTENDANT_OPTIONS";

export const SET_SELECT_TEAMS_OPTIONS = "SET_SELECT_TEAMS_OPTIONS";
export const SET_SELECT_ATTENDANT_OPTIONS = "SET_SELECT_ATTENDANT_OPTIONS";

export const SET_CURRENT_TEAM_SELECTED = "SET_CURRENT_TEAM_SELECTED";
export const SET_CURRENT_ATTENDANT_SELECTED = "SET_CURRENT_ATTENDANT_SELECTED";

export const SET_UNREAD = "SET_UNREAD";
export const SET_ALL_ROOM_TAGS = "SET_ALL_ROOM_TAGS";
export const SET_TEMPLATE_TYPE_MESSAGE = "SET_TEMPLATE_TYPE_MESSAGE";
export const MOVE_CHAT = "MOVE_CHAT";

export const REQUEST_OPEN_NEW_CHAT_ACTION = "REQUEST_OPEN_NEW_CHAT_ACTION";
export const ORDER_CHATS_BY_TAB = "ORDER_CHATS_BY_TAB";
export const CLEAR_CHAT_ACTIVED = "CLEAR_CHAT_ACTIVED";

export const SET_CHAT_MONGO_ID_FOR_BATCH_ACTION =
  "SET_CHAT_MONGO_ID_FOR_BATCH_ACTION";
export const SET_ALL_CHAT_MONGO_ID_FOR_BATCH_ACTION =
  "SET_ALL_CHAT_MONGO_ID_FOR_BATCH_ACTION";
export const CLEAR_CHAT_MONGO_ID_FOR_BATCH_ACTION =
  "CLEAR_CHAT_MONGO_ID_FOR_BATCH_ACTION";

export const REQUEST_CHAT_BATCH_ACTION = "REQUEST_CHAT_BATCH_ACTION";
export const UPDATE_CHAT = "UPDATE_CHAT";

export const FETCH_WAITING_ROOM_CHATS_COUNT_IN_DB =
  "FETCH_WAITING_ROOM_CHATS_COUNT_IN_DB";

export const ON_NEW_CHAT_EVENT = "ON_NEW_CHAT_EVENT";
export const ON_CONVERSATION_UPDATE_IN_DATABASE =
  "ON_CONVERSATION_UPDATE_IN_DATABASE";

export const REQUEST_CHECK_CHATS_OPEN_TO_SEND_NOTE =
  "REQUEST_CHECK_CHATS_OPEN_TO_SEND_NOTE";
