import React from "react";
import Routes from "./routes/";

//Import Scss
import "./assets/scss/themes.scss";
import { useLottie } from "lottie-react";
import loadingAnimation from "./assets/animations/98288-loading.json";
import { connect } from "react-redux";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import "./assets/css/App.css";
import { useLocation } from "react-router-dom";
import { setCentralLoaded } from "./redux/actions";

import { publicRoutes } from "./routes/routes";
import { useEffect } from "react";

const loadingOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingAnimation,
};



function App(props) {
  const { View } = useLottie(loadingOptions, { height: 150, width: 150 });

  useEffect(() => {
    const location = window.location;
    const pathname = location.pathname;

    const hasRoute = publicRoutes.find((route) => route.path === pathname);
    console.log(hasRoute);
    if (hasRoute) {
      props.setCentralLoaded(true);
    }
  }, []);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {!props.centralLoaded && (
          <div className="loader-container">
            {View}
            Carregando Central de Atendimento
          </div>
        )}
        <Routes />
      </DndProvider>
    </>
  );
}
const mapStateToProps = (state) => {
  const { centralLoaded } = state.Layout;
  return {
    centralLoaded,
  };
};
export default connect(mapStateToProps, { setCentralLoaded })(App);
