import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_GEDADOR,
});

const apiCRM = axios.create({
  baseURL: process.env.REACT_APP_API_CRM,
});
apiCRM.defaults.headers.common["Authorization"] =
  process.env?.REACT_APP_TOKEN_CRM;

const apiNova = axios.create({
  baseURL: process.env.REACT_APP_API_NOVA,
});

const setCRMAuthorization = (token) => {
  apiCRM.defaults.headers.common["Authorization"] = token;
};

const setAuthorization = (token) => {
  api.defaults.headers.common["Authorization"] = "Bearer " + token;
};

export {
  api,
  apiCRM,
  apiNova,
  setAuthorization,
  setCRMAuthorization,
};
