import { all, put, takeEvery, call, fork, delay } from "redux-saga/effects";

import {
  REQUEST_FILE,
  FETCH_CHATS_IN_DB,
  REQUEST_CHAT_MESSAGES,
  END_CONVERSATION,
  UPDATE_CHAT_IN_DB,
  REQUEST_EARLIER_MESSAGES,
  REQUEST_CHECK_LEAD,
  TAKE_UP_CONVERSATION,
  END_CONVERSATION_BOT,
  REQUEST_LOAD_ALL_MESSAGES,
  REQUEST_PARSE_ATTENDANT_OPTIONS,
  FETCH_MY_CHATS_IN_DB,
  FETCH_WAITING_ROOM_CHATS_IN_DB,
  MARK_UNSEEN_MESSAGE,
  REQUEST_PROFILE_PICTUTE_TO_CHAT,
  REQUEST_OPEN_NEW_CHAT_ACTION,
  REQUEST_CHECK_EMAIL_LEAD,
  REQUEST_CHAT_BATCH_ACTION,
  UPDATE_CHAT_LEAD_IN_DB,
  FETCH_WAITING_ROOM_CHATS_COUNT_IN_DB,
  ON_NEW_CHAT_EVENT,
  ON_CONVERSATION_UPDATE_IN_DATABASE,
  REQUEST_CHECK_CHATS_OPEN_TO_SEND_NOTE,
} from "./constants";

import {
  addAudioSource,
  setFiltering,
  setFilteredChat,
  setPagination,
  activeChatIndex,
  requestProfilePictureToChat,
  setActiveChatMongoId,
  activeUserId,
  removeChat,
  setUserImgWhatsapp,
  setPaginationsTabs,
  closeUserSidebar,
  activeRecipientId,
  setFullUser,
} from "../actions";

import store from "../store";

import {
  api,
  apiCRM,
  apiNova,
  setCRMAuthorization,
} from "../../services/api";

import { ADD_FILTER } from "../layout/constants";
import {
  closeModalMessenger,
  closeModalWhatsapp,
  setLoadingRequest,
  setFilterTabChat,
  addQueryFilter,
  setHas24hMessage,
  setHeightChatConversationPx,
  setLoadingChatInDb,
  setServerConnected,
  setLoadingConversation,
  closeModalEndConversation,
  closeModalEndConversationBot,
  openModalInfo,
  setCentralLoaded,
  setQueryParamsURL,
} from "../layout/actions";
import {
  fitchChatsInDb,
  removeMyChat,
  removeWaitingRoomChat,
  setChatInit,
  setCurrentAttendantSelected,
  setLeadData,
  setMyChats,
  setSelectAttendantOptions,
  setWaitingRoomChats,
  updateChatInDb,
  setCountMyChats,
  setCountWaitingRoom,
  setAllRoomTags,
  moveChat,
  openNewChatActionRequest,
  sortConversationsByTabName,
  clearChatMongoIdForBatchAction,
  setNewChat,
  setChatUpdated,
  requestCheckLead,
  setConversationUpdateMyChatInDatabase,
  setConversationUpdateWaitingRoomInDatabase,
  setConversationUpdateInDatabase,
  requestCheckChatsOpenToSendNote,
} from "./actions";
import {
  checkFilterActives,
  checkFilterTab,
  checkUserRestrictions,
} from "../../utils/checkUserRestrictions";
import parseFilterQuery from "../../utils/parseFilterQuery";
import openChat from "../../utils/openChat";
import getInfoResponsibleAndTeam from "../../utils/getInfoResponsibleAndTeam";
import getChannelIndex from "../../utils/websocket/getChannelIndex";
import Bugsnag from "@bugsnag/js";
import { getAttendantName, getMessageNote } from "../../utils/getMessageNote";

function downloadFile({ payload: message }) {
  try {
    const link = document.createElement("a");

    link.href = message.s3FilePath;

    link.setAttribute("download", message.mediaKey);
    link.setAttribute("target", "_blank");

    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error downloadFile: ", error);
  }
}

function fetchProfilePictureToChat({
  payload: { chatId, idMongo, identifier, idIntegracao },
}) {
  const state = store.getState();
  try {
    const channelIndex = getChannelIndex(identifier, idIntegracao);
    state.WebService.wsChannel[channelIndex].emit("getProfilePictureToChat", {
      chatId,
      idMongo,
    });
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error downloadFile: ", error);
  }
}

function fetchChatMessages({
  payload: { chatId, idMongo, identifier, idIntegracao, idEmpresa },
}) {
  const state = store.getState();

  try {
    const channelIndex = getChannelIndex(identifier, idIntegracao);
    state.WebService.wsChannel[channelIndex].emit("getChatMessages", {
      chatId,
      idMongo,
      identifier,
      idEmpresa,
    });

    console.log("fetchChatMessages chatId -> ", { chatId, idMongo });
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error downloadFile: ", error);
  }
}

function* loadEarlierMessages({
  payload: { chatId, idMongo, pagination, identifier, idIntegracao },
}) {
  const state = store.getState();

  yield put(setLoadingRequest(true));
  try {
    let page = 1;
    if (pagination) {
      page = pagination.page;
      const lastPage = pagination.lastPage;

      if (page < lastPage) {
        page = page + 1;
      }
    }
    const channelIndex = getChannelIndex(identifier, idIntegracao);
    state.WebService.wsChannel[channelIndex].emit("loadEarlierMessages", {
      chatId,
      idMongo,
      page,
    });

    console.log("loadEarlierMessages chatId -> ", { chatId, idMongo });
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error downloadFile: ", error);
    yield put(setLoadingRequest(false));
  }
}

function* loadAllMessages({
  payload: { chatId, idMongo, identifier, idIntegracao },
}) {
  const state = store.getState();

  yield put(setLoadingRequest(true));
  try {
    const channelIndex = getChannelIndex(identifier, idIntegracao);
    state.WebService.wsChannel[channelIndex].emit("loadAllMessagesInWpp", {
      chatId,
      idMongo,
    });
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error downloadFile: ", error);
  }
}

function* checkLead({ payload: { chat, updatedFields } }) {
  const state = store.getState();
  const user = state.Auth.user;
  const chatUpdated = { ...chat };
  try {
    if (!chat.id.includes("@g.us")) {
      let tipo = "";
      let phonenumber = "";

      switch (chat.tipoMensageiro) {
        case "whatsapp":
          phonenumber = chat.id.split("@c.us")[0];
          tipo = "whatsappweb";
          break;
        case "wzap_api_oficial":
          phonenumber = chat.id;
          tipo = "whatsappweb";
          break;
        case "messenger":
          tipo = "messenger";
          break;
        case "widget":
          tipo = "widget";
          break;
        case "instagram":
          tipo = "instagram";
          break;
        default:
          break;
      }

      console.log("chat", chat);

      const _name = chat.name;

      const body = {
        id: chat.idCliente ? chat.idCliente : "",
        id_empresa: chat.idEmpresa,
        id_usuario: user.id_usuario ?? 0,
        idchat: phonenumber,
        nome: _name ? _name : phonenumber,
        tipo,
      };

      console.log("body", { body });

      const { data } = yield call(apiCRM.post, "/chats/get-data-lead-op", body);

      const dataToSaveInChat = {};

      const id_cliente = parseInt(data.id_cliente);
      if (id_cliente !== chat.idCliente) {
        dataToSaveInChat.idCliente = parseInt(data.id_cliente);
      }

      if (data.razao !== chat.name) {
        chatUpdated.name = dataToSaveInChat.name = data.razao;
      }

      const oportunidades = data.oportunidades;

      if (oportunidades.length > 0) {
        console.log("oportunidades", oportunidades);
        const activeOpportunity = oportunidades[oportunidades.length - 1];
        const currentOpportunityId = parseInt(
          activeOpportunity.id_oportunidade
        );

        if (chat.idOportunidade !== currentOpportunityId) {
          dataToSaveInChat.idOportunidade = currentOpportunityId;
        }

        const allTags = activeOpportunity.statusTag.map((tag) =>
          parseInt(tag.id_status)
        );

        const chatTags = chat.tags ?? [];

        let differenceArray = chatTags
          .filter((x) => !allTags.includes(x))
          .concat(allTags.filter((x) => !chatTags.includes(x)));

        if (differenceArray.length > 0) {
          dataToSaveInChat.tags = allTags;
        }
      }

      if (!chat.profilePicture && chat.tipoMensageiro === "whatsapp") {
        yield put(
          requestProfilePictureToChat(
            chat.id,
            chat._id,
            chat.identifier,
            chat.idIntegracao
          )
        );
      }

      console.log("dataToSaveInChat", dataToSaveInChat);
      if (Object.entries(dataToSaveInChat).length > 0) {
        yield put(updateChatInDb(chat._id, dataToSaveInChat, updatedFields));
      }

      yield put(setLeadData(data));
    } else {
      yield put(closeUserSidebar());
      yield put(setLoadingRequest(false));
    }
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error checkLead: ", error);
  }
}

function* checkEmailLead({ payload: { conversation, updatedFields } }) {
  console.log("conversation", conversation);
  const state = store.getState();
  const user = state.Auth.user;
  try {
    yield call(setCRMAuthorization, user.access_key);
    const body = {
      id: conversation.client_id ? conversation.client_id : "",
      id_empresa: user.id_empresa,
      id_usuario: user.id_usuario ?? 0,
      idchat: "",
      nome: conversation.name,
      email: conversation.from,
      tipo: "email",
    };

    console.log("body", { body });

    const { data } = yield call(apiCRM.post, "/chats/get-data-lead-op", body);

    if (conversation.client_id === null) {
      const emailDataToUpdate = {
        client_id: parseInt(data.id_cliente),
        company_id: user.id_empresa,
      };

      yield call(
        apiNova.put,
        `/emails/${conversation.id}/opportunity`,
        emailDataToUpdate
      );
    }

    console.log("data", data);
    yield put(setLeadData(data));
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error checkLead: ", error);
  }
}

function* filterChat({ payload: { filter_type, value, name } }) {
  console.log("filterChat: ", { filter_type, value, name });

  if (filter_type !== "origin") {
    const state = store.getState();
    yield put(setFiltering(true));
    yield put(setFilteredChat(true));
    try {
      const activeFilters = state.Layout.activeFilters;

      let query = "";
      for (const [key, item] of Object.entries(activeFilters)) {
        if (typeof item === "object" && item !== null) {
          if (item.active) {
            query = query + `${key}=${item.value}&`;
          }

          if (item.length > 0) {
            query = query + `${key}=${JSON.stringify(item)}&`;
          }
        } else {
          if (key === "name") {
            query = query + `${key}=${item !== "" ? item : null}&`;
          } else {
            query = query + `${key}=${item}&`;
          }
        }
      }

      yield put(addQueryFilter(query.slice(0, -1)));
      yield put(fitchChatsInDb());
    } catch (error) {
      Bugsnag.notify(error);
      console.log("Error downloadFile: ", error);
      yield put(setFiltering(false));
    }
  }
}

function* requestOpenNewChatAction({ payload: id_cliente }) {
  const state = store.getState();
  const user = state.Auth.user;
  try {
    const resChat = yield call(
      api.get,
      `/db/mongo/conversas/${id_cliente}?id_empresa=${user.id_empresa}`
    );

    const conversa = resChat.data[0];

    if (conversa) {
      const { filterTabName, isSuccess } = yield call(checkFilterTab, conversa);
      console.log("checkFilterTab =>", filterTabName, isSuccess);

      if (isSuccess) {
        yield call(openChat, conversa, filterTabName);
      } else {
        const info = yield call(getInfoResponsibleAndTeam, conversa);
        let detail = "";

        if (!info.responsibleName) {
          detail =
            "Seu usuário não tem permissão para iniciar uma conversa.  Favor verifique com o administrador.";
        } else {
          detail = "Esse cliente já está sendo atendido por ";
          if (user.id_permissao === 2) {
            detail += info.responsibleName;
          } else if (user.id_permissao === 3) {
            detail += `${info.responsibleName ? info.responsibleName : ""}${
              info.teamName ? ` - equipe ${info.teamName}` : ""
            }`;
          }
        }

        yield put(openModalInfo(detail, ""));
      }
    } else {
      yield put(
        openModalInfo(
          "Conversa não encontrada!",
          `Nenhuma conversa encontrada para esse lead com id ${id_cliente}. Por favor, use a funcionalidade Novo Lead abra a conversa `
        )
      );
    }
    yield put(setLoadingConversation(false));
    yield put(setQueryParamsURL({}));
  } catch (error) {
    Bugsnag.notify(error);
    yield put(setLoadingConversation(false));
    console.log("requestOpenNewChatAction error", error);
  }
}

function* requestChatsInDb({ payload: { page, importacao } }) {
  const state = store.getState();

  const { filtering, query_filter, queryParamsUrl } = state.Layout;
  const chatActiveIdMongo = state.Chat.active_chat_mongo_id;
  const unanswered =
    state.Config.config.exibir_conversas_nao_atribuidas_todos === "S"
      ? "true"
      : "false";

  const _page = page ? `&page=${page}` : "&page=1";
  const _importacao = importacao ? `&importacao=true` : `&importacao=false`;
  const isOpen = query_filter.includes("isOpen") ? "" : "&isOpen=true";
  const hasUnanswared = query_filter.includes("unanswered")
    ? ""
    : state.Auth.user.id_permissao != 1
    ? `&unanswered=${unanswered}`
    : "";

  try {
    const dataForm = parseFilterQuery();

    const { data } = yield call(
      api.post,
      `/db/mongo/conversas/filter?${
        query_filter ? "typeChat=ALL&" + query_filter : "typeChat=ALL"
      }${isOpen}${hasUnanswared}${_page}${_importacao}`,
      dataForm
    );

    const chats = data.chats;
    const pagination = data.pagination;

    const indexChat = chats.findIndex((chat) => chat._id === chatActiveIdMongo);

    if (indexChat !== -1) {
      yield put(activeChatIndex(indexChat));
      yield put(activeUserId(chats[indexChat].id));
    }

    yield put(setPaginationsTabs("allChats", pagination));

    yield put(setHeightChatConversationPx(0));
    yield put(setHas24hMessage(false));

    const userConversations = chats
      .filter((chat) => checkUserRestrictions("ALL", chat))
      .filter((chat) => checkFilterActives(chat));

    yield put(setFullUser(userConversations));

    if (filtering) {
      yield put(setFilterTabChat("allChatsTab", true));
      yield put(sortConversationsByTabName("allChatsTab"));
      yield put(setFiltering(false));
    }

    yield put(closeModalWhatsapp());
    yield put(closeModalMessenger());
    yield put(setServerConnected());
    yield put(setLoadingChatInDb(false));

    if (
      queryParamsUrl &&
      queryParamsUrl.action &&
      queryParamsUrl.action === "open_chat"
    ) {
      yield put(setLoadingConversation(true));
      yield put(openNewChatActionRequest(queryParamsUrl.id_cliente));
    }
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error loadChatInDb: ", error);
  }
}

function* requestMyChatsInDb({ payload: { idResponsavel, page, importacao } }) {
  const state = store.getState();

  const unanswered =
    state.Config.config.exibir_conversas_nao_atribuidas_todos === "S"
      ? "true"
      : "false";
  const query_filter = `typeChat=MY_CHATS&attendants=[${idResponsavel}]&unanswered=${unanswered}&isOpen=true`;
  const chatActiveIdMongo = state.Chat.active_chat_mongo_id;
  const activeChatsTab = state.Layout.activeChatsTab;

  const _page = page ? `&page=${page}` : "&page=1";
  const _importacao = importacao ? `&importacao=true` : `&importacao=false`;

  try {
    const dataForm = parseFilterQuery();

    const { data } = yield call(
      api.post,
      `/db/mongo/conversas/filter?${query_filter + _page + _importacao}`,
      dataForm
    );

    const chats = data.chats;
    const pagination = data.pagination;

    const indexChat = chats.findIndex((chat) => chat._id === chatActiveIdMongo);

    if (indexChat !== -1) {
      yield put(activeChatIndex(indexChat));
      yield put(activeUserId(chats[indexChat].id));
    }

    yield put(setPaginationsTabs("myConversations", pagination));
    yield put(setCountMyChats(pagination.total));

    yield put(setHeightChatConversationPx(0));
    yield put(setHas24hMessage(false));

    const userConversations = chats
      .filter((chat) => checkUserRestrictions("MY_CHATS", chat))
      .filter((chat) => checkFilterActives(chat));

    yield put(setMyChats(userConversations));
    yield put(setFiltering(false));
    yield put(closeModalWhatsapp());
    yield put(closeModalMessenger());
    yield put(setServerConnected());
    yield put(setLoadingChatInDb(false));
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error loadChatInDb: ", error);
  }
}

function* requestWaitingRoomChatsInDb({
  payload: { page, importacao, count },
}) {
  const state = store.getState();
  const unanswered =
    state.Config.config.exibir_conversas_nao_atribuidas_todos === "S"
      ? "true"
      : "false";
  const query_filter = `typeChat=WAITING_ROOM&unanswered=${unanswered}&isOpen=true`;
  const chatActiveIdMongo = state.Chat.active_chat_mongo_id;
  const activeChatsTab = state.Layout.activeChatsTab;

  const hasCount = count ? `&count=true` : "";
  const _page = page ? `&page=${page}` : "&page=1";
  const _importacao = importacao ? `&importacao=true` : `&importacao=false`;

  try {
    const dataForm = parseFilterQuery();

    const { data } = yield call(
      api.post,
      `/db/mongo/conversas/filter?${
        query_filter + _page + _importacao + hasCount
      }&limit=100`,
      dataForm
    );

    const chats = data.chats;
    const pagination = data.pagination;

    const indexChat = chats.findIndex((chat) => chat._id === chatActiveIdMongo);

    if (indexChat !== -1) {
      yield put(activeChatIndex(indexChat));
      yield put(activeUserId(chats[indexChat].id));
    }

    yield put(setPaginationsTabs("waitingRoom", pagination));
    yield put(setCountWaitingRoom(pagination.total));

    yield put(setHeightChatConversationPx(0));
    yield put(setHas24hMessage(false));

    const userConversations = chats
      .filter((chat) => checkUserRestrictions("WAITING_ROOM", chat))
      .filter((chat) => checkFilterActives(chat));

    yield put(setWaitingRoomChats(userConversations));
    yield put(setFiltering(false));
    yield put(closeModalWhatsapp());
    yield put(closeModalMessenger());
    yield put(setServerConnected());
    yield put(setLoadingChatInDb(false));
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error loadChatInDb: ", error);
  }
}

function* requestWaitingRoomChatsCountInDb() {
  const state = store.getState();
  const unanswered =
    state.Config.config.exibir_conversas_nao_atribuidas_todos === "S"
      ? "true"
      : "false";
  const query_filter = `typeChat=WAITING_ROOM&unanswered=${unanswered}&isOpen=true`;

  try {
    const dataForm = parseFilterQuery();

    const hasCount = `&count=true`;
    const _page = "&page=1";
    const _importacao = `&importacao=false`;

    const { data } = yield call(
      api.post,
      `/db/mongo/conversas/filter?${
        query_filter + _page + _importacao + hasCount
      }&limit=100`,
      dataForm
    );

    const pagination = data.pagination;

    store.dispatch(setCountWaitingRoom(pagination.total));
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error loadChatInDb: ", error);
  }
}

function* requestEndConversation({ payload: finishBot }) {
  const state = store.getState();
  try {
    yield put(setLoadingRequest(true));
    const activeChatsTab = state.Layout.activeChatsTab;
    const chatFind = state.Chat[activeChatsTab].find(
      (chat) => chat._id === state.Chat.active_chat_mongo_id
    );

    const user = state.Auth.user;
    const wsCentral = state.WebService.wsCentral;
    const limpar_resp = state.Config.config.limpar_responsavel_ao_finalizar;
    const limpar_equipe = state.Config.config.limpar_equipe_ao_finalizar;
    const limpar_resp_bot =
      state.Config.config.limpar_responsavel_ao_entrar_bot;
    let dataForm = {
      situacao: false,
    };

    if (limpar_resp === "S" && !finishBot) {
      dataForm.id_responsavel = null;
    }

    if (limpar_equipe === "S") {
      dataForm.id_equipe = null;
    }

    if (finishBot) {
      dataForm.bot_finalizado = false;

      if (limpar_resp_bot === "S") {
        dataForm.id_responsavel = null;
      }
    }

    const { data } = yield call(
      api.put,
      `/db/mongo/conversas/${chatFind._id}/situacao`,
      dataForm
    );

    yield put(removeChat(chatFind._id, "allChatsTab", true));
    yield put(removeMyChat(chatFind._id, "myConversationsTab", true));
    yield put(removeWaitingRoomChat(chatFind._id, "waitingRoomTab", true));

    wsCentral.emit("removeChat", {
      idEmpresa: user.id_empresa,
      idMongo: chatFind._id,
      tabNames: ["allChatsTab", "myConversationsTab", "waitingRoomTab"],
    });
    // wsChannel.emit('newChat', data.chat)
    yield put(closeModalEndConversation());
    yield put(openModalInfo("Conversa encerrada com sucesso", ""));
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error requestEndConversation: ", error);
  }
}

function* requestEndConversationBatchAction({
  payload: { finishChat, finishBot },
}) {
  const state = store.getState();
  try {
    yield put(setLoadingRequest(true));
    const active_chats_for_batch_action =
      state.Chat.active_chats_for_batch_action;

    let dataForm = {
      finishChat,
      finishBot,
      chats: active_chats_for_batch_action,
    };

    const limpar_resp = state.Config.config.limpar_responsavel_ao_finalizar;
    const limpar_equipe = state.Config.config.limpar_equipe_ao_finalizar;
    const limpar_resp_bot =
      state.Config.config.limpar_responsavel_ao_entrar_bot;

    if (limpar_resp === "S" && !finishBot) {
      dataForm.removeResponsible = true;
    }

    if (limpar_equipe === "S") {
      dataForm.removeTeam = true;
    }

    if (finishBot) {
      dataForm.finishBot = true;

      if (limpar_resp_bot === "S") {
        dataForm.removeResponsible = true;
      }
    }

    const text = getAttendantName(state.Auth.user.id_usuario);

    const message = `Conversa finalizada por "${text}"`;

    dataForm.message = message;

    yield put(setChatInit(true));
    const { data } = yield call(
      api.put,
      `/db/mongo/conversas/end_batch_conversations_action`,
      dataForm
    );

    console.log("data", data);

    yield put(clearChatMongoIdForBatchAction());
    yield put(closeModalEndConversation());
    yield put(
      openModalInfo("Aguarde enquanto estamos encerrando as conversas.", "")
    );
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error requestEndConversation: ", error);
  }
}

function* requestEndConversationBot() {
  const state = store.getState();

  try {
    yield put(setLoadingRequest(true));
    const wsCentral = state.WebService.wsCentral;
    const _id = state.Chat.active_chat_mongo_id;
    const user = state.Auth.user;
    const activeChatsTab = state.Layout.activeChatsTab;
    const chatFind = state.Chat[activeChatsTab].find(
      (chat) => chat._id === state.Chat.active_chat_mongo_id
    );

    const limpar_resp_bot =
      state.Config.config.limpar_responsavel_ao_entrar_bot;

    let body = {
      situacao: false,
      bot_finalizado: false,
    };

    if (limpar_resp_bot === "S") {
      body.id_responsavel = null;
    }

    const { data } = yield call(
      api.put,
      `/db/mongo/conversas/${_id}/situacao`,
      body
    );


    yield put(removeChat(_id, "allChatsTab", true));
    yield put(removeMyChat(_id, "myConversationsTab", true));
    yield put(removeWaitingRoomChat(_id, "waitingRoomTab", true));
    wsCentral.emit("removeChat", {
      idEmpresa: user.id_empresa,
      idMongo: chatFind._id,
      tabNames: ["allChatsTab", "myConversationsTab", "waitingRoomTab"],
    });
    wsCentral.emit("newChat", {
      idEmpresa: user.id_empresa,
      chat: data.chat,
      bot: false,
    });
    yield put(closeModalEndConversationBot());
    yield put(
      openModalInfo(
        "Conversa encerrada com sucesso e redirecionada para o bot",
        ""
      )
    );
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error requestEndConversation: ", error);
  }
}

function* requestTakeUpConversation() {
  const state = store.getState();

  try {
    yield put(setLoadingRequest(true));
    const id_responsavel = state.Auth.user.id_usuario;
    const wsCentral = state.WebService.wsCentral;
    const idMongo = state.Chat.active_chat_mongo_id;
    const user = state.Auth.user;
    const situacao = true;

    const { data } = yield call(
      api.put,
      `/db/mongo/conversas/${idMongo}/situacao`,
      {
        situacao,
        id_responsavel,
      }
    );

    const filterArrayChat = Object.entries(state.Layout.filterTabChat);
    const tabNameActive = filterArrayChat.filter((tab) => tab[1] === true);

    const message = getMessageNote({
      chatActive: data.chat,
      attendantUpdated: id_responsavel,
      idAttendant: data.chat.idResponsavel,
      idTeam: data.chat.idTeam,
      teamUpdated: null,
    });

    const emitMessageData = {
      chatId: data.chat.id,
      message,
      type: "chat",
      identifier: data.chat.identifier,
    };
    const channelIndex = getChannelIndex(
      data.chat.identifier,
      data.chat.idIntegracao
    );
    if (state.WebService.wsChannel[channelIndex]) {
      state.WebService.wsChannel[channelIndex]?.emit(
        "sendNote",
        emitMessageData
      );
    }

    yield put(requestCheckChatsOpenToSendNote(data.chat));

    yield put(moveChat(idMongo, "my_chats"));
    yield put(removeChat(idMongo, tabNameActive[0][0], true));

    wsCentral.emit("conversationUpdateInDatabase", {
      idEmpresa: user.id_empresa,
      idMongo,
      tabName: tabNameActive[0][0],
      updatedChat: data.chat,
      updatedFields: {
        attendantUpdated: true,
      },
    });
    yield delay(1500);
    wsCentral.emit("newChat", {
      idEmpresa: user.id_empresa,
      chat: data.chat,
      bot: true,
    });

    yield put(
      openModalInfo(
        "Conversa assumida com sucesso.",
        "Filtro por conversas atribuidas ou todas."
      )
    );

    console.log(data);
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error requestEndConversation: ", error);
  }
}

function* requestUpdateChatInDb({
  payload: { idMongo, dataForm, updatedFields },
}) {
  const state = store.getState();
  console.log("dataForm", { idMongo, dataForm, updatedFields });
  try {
    yield put(setLoadingRequest(true));
    let wsCentral = state.WebService.wsCentral;
    const user = state.Auth.user;
    const { activeChatsTab } = state.Layout;

    const { data } = yield call(
      api.put,
      `/db/mongo/conversas/${idMongo}/update`,
      dataForm
    );

    const filterArrayChat = Object.entries(state.Layout.filterTabChat);

    const tabNameActive = filterArrayChat.filter((tab) => tab[1] === true);

    yield put(setAllRoomTags(idMongo, data.tags));

    if (data.aberta) {
      if (data.tipoMensageiro == "widget") {
        for (let ws of state.WebService.wsChannel) {
          if (ws.topic.includes(`widget:${data.idIntegracao}`)) {
            wsCentral = ws;
          }
        }
      }

      wsCentral.emit("conversationUpdateInDatabase", {
        idEmpresa: user.id_empresa,
        idMongo,
        tabName: tabNameActive[0][0],
        updatedChat: data,
        updatedFields,
      });
    }

    if (!data.idEquipe && !data.idResponsavel) {
      yield put(
        removeMyChat(idMongo, "myConversationsTab", false, activeChatsTab)
      );
    } else if (data.idResponsavel === user.id_usuario) {
      yield put(
        removeWaitingRoomChat(idMongo, "waitingRoomTab", false, activeChatsTab)
      );
      yield put(setNewChat("my_chats", data, !data.bot_finalizado));
    }
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error requestEndConversation: ", error);
  }
}

function* requestUpdateChatLeadInDb({
  payload: { idLead, dataForm, updatedFields },
}) {
  const state = store.getState();
  console.log("dataForm", { idLead, dataForm, updatedFields });
  try {
    yield put(setLoadingRequest(true));
    let wsCentral = state.WebService.wsCentral;
    const user = state.Auth.user;
    const { activeChatsTab } = state.Layout;

    const { data } = yield call(
      api.put,
      `/db/mongo/conversas/lead/${idLead}/update`,
      dataForm
    );

    const filterArrayChat = Object.entries(state.Layout.filterTabChat);

    const tabNameActive = filterArrayChat.filter((tab) => tab[1] === true);

    for (const chat of data) {
      yield put(setAllRoomTags(chat._id, chat.tags));

      if (chat.aberta) {
        if (chat.tipoMensageiro == "widget") {
          for (let ws of state.WebService.wsChannel) {
            if (ws.topic.includes(`widget:${chat.idIntegracao}`)) {
              wsCentral = ws;
            }
          }
        }

        wsCentral.emit("conversationUpdateInDatabase", {
          idEmpresa: user.id_empresa,
          idMongo: chat._id,
          tabName: tabNameActive[0][0],
          updatedChat: chat,
          updatedFields,
        });
      }

      if (!chat.idEquipe && !chat.idResponsavel) {
        yield put(
          removeMyChat(chat._id, "myConversationsTab", false, activeChatsTab)
        );
      } else if (chat.idResponsavel === user.id_usuario) {
        yield put(
          removeWaitingRoomChat(
            chat._id,
            "waitingRoomTab",
            false,
            activeChatsTab
          )
        );
        yield put(setNewChat("my_chats", chat, !chat.bot_finalizado));
      }
    }
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error requestEndConversation: ", error);
  }
}
function* requestMarkUnseenMessage({ payload: { idMongo, chatId } }) {
  const state = store.getState();
  console.log("dataForm", { idMongo, chatId });
  try {
    yield put(setLoadingRequest(true));
    const wsCentral = state.WebService.wsCentral;
    const user = state.Auth.user;

    wsCentral.emit("markUnseenMessage", {
      idEmpresa: user.id_empresa,
      idMongo,
      chatId,
    });

    const { data } = yield call(
      api.put,
      `/db/mongo/conversas/${idMongo}/update`,
      {
        unRead: 1,
      }
    );

    const filterArrayChat = Object.entries(state.Layout.filterTabChat);

    const tabNameActive = filterArrayChat.filter((tab) => tab[1] === true);

    if (data.aberta) {
      wsCentral.emit("conversationUpdateInDatabase", {
        idEmpresa: user.id_empresa,
        idMongo,
        tabName: tabNameActive[0][0],
        updatedChat: data,
      });
    }
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error requestEndConversation: ", error);
  }
}

function* parseAttendantOptions({ payload: idAttendant }) {
  const state = store.getState();

  try {
    const { current_team_selected } = state.Chat;
    const { attendants } = state.Config;

    let _attendants = [{ value: null, label: "Nenhum responsável" }];

    let hasAttendantSelected = null;

    if (current_team_selected.value) {
      current_team_selected.users.forEach((attendant) => {
        _attendants.push({
          value: parseInt(attendant.id_usuario),
          label: attendant.nome,
        });
        if (parseInt(attendant.id_usuario) === idAttendant) {
          hasAttendantSelected = attendant;
        }
      });
    } else {
      attendants.forEach((attendant) => {
        _attendants.push({
          value: parseInt(attendant.id_usuario),
          label: attendant.nome,
        });
        if (parseInt(attendant.id_usuario) === idAttendant) {
          hasAttendantSelected = attendant;
        }
      });
    }

    console.log("hasAttendantSelected", { idAttendant, hasAttendantSelected });
    yield put(setSelectAttendantOptions(_attendants));

    yield put(
      setCurrentAttendantSelected({
        value: hasAttendantSelected
          ? parseInt(hasAttendantSelected.id_usuario)
          : null,
        label: hasAttendantSelected
          ? hasAttendantSelected.nome
          : "Nenhum responsável",
      })
    );
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error parseAttendantOptions: ", error);
  }
}

function* processNewChatEvent({ payload: data }) {
  try {
    const { chat, bot } = data;

    if (!chat || !Object.values(chat)?.length > 0) {
      return;
    }

    const state = store.getState();
    const user = state.Auth.user;

    const userConversations = [chat].filter((c) => checkFilterActives(c));

    if (chat) {
      console.log("userConversations", userConversations);

      if (
        chat.idResponsavel === user.id_usuario &&
        checkUserRestrictions("MY_CHATS", chat)
      ) {
        store.dispatch(setNewChat("my_chats", chat, bot));
      }

      if (
        chat.idResponsavel === null &&
        checkUserRestrictions("WAITING_ROOM", chat)
      ) {
        store.dispatch(setNewChat("waiting_room", chat, bot));
      }

      if (checkUserRestrictions("USERS", chat) && checkFilterActives(chat)) {
        store.dispatch(setNewChat("users", chat, bot));
      }

      if (chat.tipoMensageiro === "instagram") {
        store.dispatch(setFilterTabChat("allChatsTab", true));
        store.dispatch(sortConversationsByTabName("allChatsTab"));
      }
    }
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error processNewChatEvent: ", error);
  }
}

function* processConversationUpdateInDatabaseEvent({ payload: data }) {
  try {
    console.log("onConversationUpdateInDatabase =>", data);

    let dataUpdate = data;

    const state = store.getState();
    const user = state.Auth.user;
    const { activeChatsTab, filterTabChat } = state.Layout;
    const { active_chat_mongo_id, users, my_chats, waiting_room } = state.Chat;

    if (data.message) {
      dataUpdate = {
        idMongo: data.chat._id,
        tabName: "waitingRoomTab",
        updatedChat: data.chat,
      };
    }

    if (data.updatedChat.idEmpresa !== user.id_empresa) {
      return false;
    }

    const hasChatInRedux = users.findIndex(
      (user) => user._id === dataUpdate.idMongo
    );
    console.log("hasChatInRedux", hasChatInRedux);

    const userConversations = [dataUpdate.updatedChat].filter((chat) =>
      checkFilterActives(chat)
    );

    console.log("userConversations", userConversations);

    const tabNameActive = Object.entries(filterTabChat).filter(
      (tab) => tab[1] === true
    )[0][0];

    console.log("tabNameActive", tabNameActive);

    if (userConversations.length > 0) {
      // store.dispatch(setNewChat(userConversations[0]))

      const chatActive = users.find(
        (chat) => chat._id === active_chat_mongo_id
      );
      store.dispatch(setChatUpdated(dataUpdate?.updatedChat));

      if (
        dataUpdate.idMongo === active_chat_mongo_id &&
        dataUpdate.updatedFields?.leadOpUpdated
      ) {
        console.log("user conversation has tags diff => ", {
          chatActive,
          userConversations: userConversations[0],
        });
        store.dispatch(requestCheckLead(userConversations[0]));
      }
      if (
        userConversations[0].idResponsavel === user.id_usuario &&
        checkUserRestrictions("MY_CHATS", userConversations[0])
      ) {
        console.log("TO UPDATE IN MY CHAT");
        if (
          dataUpdate.updatedFields?.attendantUpdated ||
          dataUpdate.updatedFields?.tagsUpdated ||
          dataUpdate?.updateChatInDb?.name != chatActive?.name
        ) {
          store.dispatch(setConversationUpdateMyChatInDatabase(dataUpdate));
        }
      } else {
        const myChatActive = my_chats.find(
          (chat) => chat._id === active_chat_mongo_id
        );
        console.log("removeMyChat", { myChatActive, tabNameActive });

        if (myChatActive && tabNameActive === "myConversationsTab") {
          dataUpdate.tabName = tabNameActive;
        }

        store.dispatch(
          removeMyChat(
            dataUpdate.idMongo,
            dataUpdate.tabName,
            false,
            activeChatsTab
          )
        );
        // wsChannel.emit('removeChat', {idMongo: dataUpdate.idMongo, tabNames: ['allChatsTab', 'myConversationsTab', 'waitingRoomTab']})
      }

      if (
        userConversations[0].idResponsavel === null &&
        checkUserRestrictions("WAITING_ROOM", userConversations[0])
      ) {
        console.log("TO UPDATE IN MY WAITING ROOM");
        store.dispatch(setConversationUpdateWaitingRoomInDatabase(dataUpdate));
      } else {
        const waitingChatActive = waiting_room.find(
          (chat) => chat._id === active_chat_mongo_id
        );

        console.log("removeWaitingRoomChat", {
          waitingChatActive,
          tabNameActive,
        });
        if (waitingChatActive && tabNameActive === "waitingRoomTab") {
          dataUpdate.tabName = tabNameActive;
        }

        store.dispatch(
          removeWaitingRoomChat(
            dataUpdate.idMongo,
            dataUpdate.tabName,
            false,
            activeChatsTab
          )
        );
        // wsChannel.emit('removeChat', {idMongo: dataUpdate.idMongo, tabNames: ['allChatsTab', 'myConversationsTab', 'waitingRoomTab']})
      }

      if (hasChatInRedux !== -1) {
        if (checkUserRestrictions("USERS", userConversations[0])) {
          console.log("TO UPDATE IN USERS");
          store.dispatch(setConversationUpdateInDatabase(dataUpdate));
          store.dispatch(setLoadingRequest(false));
        } else {
          store.dispatch(
            removeChat(dataUpdate.idMongo, "allChatsTab", false, activeChatsTab)
          );
        }
      } else {
        if (
          dataUpdate.updatedFields?.attendantUpdated ||
          dataUpdate.updatedFields?.teamUpdated
        ) {
          if (checkUserRestrictions("USERS", userConversations[0])) {
            console.log("check USERS");
            store.dispatch(setConversationUpdateInDatabase(dataUpdate));
            store.dispatch(setLoadingRequest(false));
          } else {
            console.log("remove USERS");
            store.dispatch(
              removeChat(
                dataUpdate.idMongo,
                "allChatsTab",
                false,
                activeChatsTab
              )
            );
          }
        }
      }
    } else {
      if (hasChatInRedux !== -1) {
        console.log("removeChat");
        if (user.id_permissao === 3) {
          store.dispatch(
            removeChat(dataUpdate.idMongo, "allChatsTab", false, activeChatsTab)
          );
          store.dispatch(
            removeMyChat(
              dataUpdate.idMongo,
              "myConversationsTab",
              false,
              activeChatsTab
            )
          );
          store.dispatch(
            removeWaitingRoomChat(
              dataUpdate.idMongo,
              "waitingRoomTab",
              false,
              activeChatsTab
            )
          );
        } else {
          store.dispatch(
            removeChat(
              dataUpdate.idMongo,
              dataUpdate.tabName,
              false,
              activeChatsTab
            )
          );
        }

        store.dispatch(activeChatIndex(-1));
        store.dispatch(activeUserId(""));
        store.dispatch(setChatInit(true));
        store.dispatch(activeRecipientId(""));
      }
    }
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error processConversationUpdateInDatabaseEvent: ", error);
  }
}

function* checkChatsOpenToSendNote({ payload: chatActive }) {
  const state = store.getState();

  const { processos } = state.WebService;

  try {
    const ids_identifier = processos[chatActive.tipoMensageiro].map((p) => {
      if (chatActive.tipoMensageiro === "whatsapp") {
        return p.phonenumber;
      } else if (chatActive.tipoMensageiro === "messenger") {
        return p.id_pagina;
      } else {
        return p.identifier;
      }
    });

    const { data } = yield call(
      api.get,
      `/db/mongo/conversas/open/${chatActive.idEmpresa}/${
        chatActive.id
      }?ids_identifier=${JSON.stringify(ids_identifier)}`
    );

    if (data.length > 0) {
      const chatsToSendNote = data.filter(
        (c) => c.identifier !== chatActive.identifier
      );

      if (chatsToSendNote.length > 0) {
        for (const chat of chatsToSendNote) {
          const processo = processos[chatActive.tipoMensageiro].find(
            (p) => p.id === chatActive.idIntegracao
          );

          let linha = chatActive.identifier;
          if (processo) {
            const descricao =
              processo.descricao ??
              processo.nome_pagina ??
              processo.description;

            linha = `${descricao} (${linha})`;
          }
          const _message = `Conversa esta sendo atendido pela linha ${linha}`;
          const emitMessageData = {
            chatId: chat.id,
            message: _message,
            type: "chat",
            identifier: chat.identifier,
          };
          const channelIndex = getChannelIndex(
            chat.identifier,
            chat.idIntegracao
          );
          if (state.WebService.wsChannel[channelIndex]) {
            state.WebService.wsChannel[channelIndex]?.emit(
              "sendNote",
              emitMessageData
            );
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchDownloadFile() {
  yield takeEvery(REQUEST_FILE, downloadFile);
}

export function* watchFetchChatMessages() {
  yield takeEvery(REQUEST_CHAT_MESSAGES, fetchChatMessages);
}

export function* watchFetchProfilePictureToChat() {
  yield takeEvery(REQUEST_PROFILE_PICTUTE_TO_CHAT, fetchProfilePictureToChat);
}

export function* watchLoadEarlierMessages() {
  yield takeEvery(REQUEST_EARLIER_MESSAGES, loadEarlierMessages);
}

export function* watchLoadAllMessages() {
  yield takeEvery(REQUEST_LOAD_ALL_MESSAGES, loadAllMessages);
}

export function* watchRequestCheckLead() {
  yield takeEvery(REQUEST_CHECK_LEAD, checkLead);
}

export function* watchRequestEmailCheckLead() {
  yield takeEvery(REQUEST_CHECK_EMAIL_LEAD, checkEmailLead);
}

export function* watchFilterChat() {
  yield takeEvery(ADD_FILTER, filterChat);
}

export function* watchLoadChatInDb() {
  yield takeEvery(FETCH_CHATS_IN_DB, requestChatsInDb);
}

export function* watchLoadMyChatInDb() {
  yield takeEvery(FETCH_MY_CHATS_IN_DB, requestMyChatsInDb);
}

export function* watchLoadWaitingRoomChatInDb() {
  yield takeEvery(FETCH_WAITING_ROOM_CHATS_IN_DB, requestWaitingRoomChatsInDb);
}

export function* watchLoadWaitingRoomChatCountInDb() {
  yield takeEvery(
    FETCH_WAITING_ROOM_CHATS_COUNT_IN_DB,
    requestWaitingRoomChatsCountInDb
  );
}

export function* watchEndConversation() {
  yield takeEvery(END_CONVERSATION, requestEndConversation);
}

export function* watchEndConversationBot() {
  yield takeEvery(END_CONVERSATION_BOT, requestEndConversationBot);
}

export function* watchTakeUpConversation() {
  yield takeEvery(TAKE_UP_CONVERSATION, requestTakeUpConversation);
}

export function* watchUpdateChatInDb() {
  yield takeEvery(UPDATE_CHAT_IN_DB, requestUpdateChatInDb);
}

export function* watchUpdateChatLeadInDb() {
  yield takeEvery(UPDATE_CHAT_LEAD_IN_DB, requestUpdateChatLeadInDb);
}

export function* watchMarkUnseenMessage() {
  yield takeEvery(MARK_UNSEEN_MESSAGE, requestMarkUnseenMessage);
}

export function* watchParseAttendantOptions() {
  yield takeEvery(REQUEST_PARSE_ATTENDANT_OPTIONS, parseAttendantOptions);
}

export function* watchRequestOpenNewChatAction() {
  yield takeEvery(REQUEST_OPEN_NEW_CHAT_ACTION, requestOpenNewChatAction);
}

export function* watchRequestChatBatchAction() {
  yield takeEvery(REQUEST_CHAT_BATCH_ACTION, requestEndConversationBatchAction);
}

export function* watchProcessNewChatEvent() {
  yield takeEvery(ON_NEW_CHAT_EVENT, processNewChatEvent);
}

export function* watchProcessConversationUpdateInDatabaseEvent() {
  yield takeEvery(
    ON_CONVERSATION_UPDATE_IN_DATABASE,
    processConversationUpdateInDatabaseEvent
  );
}

export function* watchRequestCheckChatsOpenToSendNote() {
  yield takeEvery(
    REQUEST_CHECK_CHATS_OPEN_TO_SEND_NOTE,
    checkChatsOpenToSendNote
  );
}

function* chatSaga() {
  yield all([
    fork(watchDownloadFile),
    fork(watchFetchChatMessages),
    fork(watchFetchProfilePictureToChat),
    fork(watchLoadEarlierMessages),
    fork(watchLoadAllMessages),
    fork(watchRequestEmailCheckLead),
    fork(watchRequestCheckLead),
    fork(watchFilterChat),
    fork(watchLoadChatInDb),
    fork(watchUpdateChatLeadInDb),
    fork(watchLoadMyChatInDb),
    fork(watchLoadWaitingRoomChatInDb),
    fork(watchLoadWaitingRoomChatCountInDb),
    fork(watchEndConversation),
    fork(watchEndConversationBot),
    fork(watchTakeUpConversation),
    fork(watchUpdateChatInDb),
    fork(watchMarkUnseenMessage),
    fork(watchParseAttendantOptions),
    fork(watchRequestOpenNewChatAction),
    fork(watchRequestChatBatchAction),
    fork(watchProcessNewChatEvent),
    fork(watchProcessConversationUpdateInDatabaseEvent),
    fork(watchRequestCheckChatsOpenToSendNote),
  ]);
}

export default chatSaga;
