const { getUnixTime, millisecondsToSeconds } = require("date-fns");

const getTimestamp = (date) => {
  if (date) {
    let timeUnix = null;
    let timestamp = null;

    if (typeof date === "string") {
      const timeISO = new Date(date);

      timeUnix = getUnixTime(timeISO);
    } else {
      timeUnix = date;
    }

    if (timeUnix.toString().length > 10) {
      timestamp = millisecondsToSeconds(timeUnix);
    } else {
      timestamp = timeUnix;
    }

    if (timestamp) {
      return timestamp;
    } else {
      return getUnixTime(new Date());
    }
  } else {
    return getUnixTime(new Date());
  }
};

export default  getTimestamp;
