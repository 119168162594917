import Bugsnag from '@bugsnag/js'
import { format } from 'date-fns'
import { all, put, takeEvery, call, fork } from 'redux-saga/effects'
import { api, apiNova, setCRMAuthorization } from '../../services/api'
import { checkUserEmailRestrictions, getEmailsProvider } from '../../utils/email/checkUserEmailProvider'
import { setAlertMessage, closeModalEmailEditor } from '../actions'

import store from '../store'
import {
  setAllEmails,
  setEmailConfig,
  setNewEmail,
  setNewReplyEmail,
  setOptionsEmailProvider,
  setSendingEmail,
} from './actions'
import {
  REQUEST_EMAILS_CONFIG,
  REQUEST_EMAILS_LIST,
  REQUEST_SEND_EMAIL,
} from './constants'

const optionsConexao = [
  { value: 0, label: 'Nenhum' },
  { value: 1, label: 'SSL' },
  { value: 2, label: 'TSL' },
]

function* loadEmailConfig() {
  const state = store.getState()
  const user = state.Auth.user
  try {

    yield call(setCRMAuthorization, user.access_key)
    const resEmails = yield call(api, '/central/email/config')
    const emailConfig = resEmails.data


    const resEmailProvider = yield call(apiNova, '/email-provider')
    const emailprovider = resEmailProvider.data.map(provider => {

      const sender_secure_conection = optionsConexao.find(option => option.value === provider.sender_secure_conection)
      return {
        value: provider.type,
        label: provider.name,
        defaultSetting: {
          provider_id: provider.id,
          receiver_imap: provider.receiver_imap,
          sender_smtp_server: provider.sender_smtp_server,
          client_id: '',
          client_secret: '',
          sender_email: '',
          sender_password: '',
          sender_secure_conection,
          sender_port: provider.sender_port,
        }
      }
    })

    yield put(setOptionsEmailProvider([
      {
        value: 'default',
        label: 'Selecione o provedor de e-mail',
        defaultSetting: null,
      },
      ...emailprovider
    ]))
    yield put(setEmailConfig(emailConfig))

    yield call(getEmailsProvider())

    console.log('emailprovider', emailprovider)
    console.log('emailConfig', emailConfig)
  } catch (error) {
    Bugsnag.notify(error)
    if (error.response) {
      console.log(error.response)
    } else if (error.request) {
      console.log(error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message)
    }
  }
}

function* loadEmailsList() {
  const state = store.getState()

  const { user } = state.Auth
  const { emailAccess, activeEmailInbox } = state.Email

  try {
    if (emailAccess) {
      yield call(setCRMAuthorization, user.access_key)

      const resEmails = yield call(
        apiNova.get,
        `/emails?company_id=${user.id_empresa}&identifier_email=${activeEmailInbox}`,
      )

      const emails = resEmails.data

      console.log('emails', emails)

      const _emails = emails.filter(email => checkUserEmailRestrictions(email))

      console.log('_emails', _emails)


      yield put(setAllEmails(_emails))
    }

  } catch (error) {
    Bugsnag.notify(error)
    console.log('Error loadEmailsList: ', error)
  }
}

function* requestSendEmail() {
  const state = store.getState()

  const user = state.Auth.user
  const { type_reply_email, email_form, body } = state.Email
  console.log({ type_reply_email, email_form, body })

  try {
    yield put(setSendingEmail(true))
    yield call(setCRMAuthorization, user.access_key)

    const shipping_time = yield format(new Date(), 'yyyy-MM-dd hh:ss')

    const emailToSendData = {
      body,
      subject: email_form.subject,
      shipping_time,
      email_to: email_form.to,
      cc: email_form.cc,
      cco: email_form.cco,
      user_id: user.id_usuario,
      company_id: user.id_empresa,
      reply_to: email_form.reply_to,
      email_conversation_id: email_form.email_conversation_id,
      oportunity_id: email_form.oportunity_id
    }

    const resSendEmail = yield call(
      apiNova.post,
      `/emails`,
      emailToSendData,
    )

    const { emailConversation, createdMail } = resSendEmail.data

    yield put(setNewEmail({ emailConversation, createdMail }))

    yield put(setSendingEmail(false))
    yield put(closeModalEmailEditor())
    yield put(setAlertMessage('Enviando email...', 'info'))
  } catch (error) {
    Bugsnag.notify(error)
    console.log('Error requestSendEmail: ', error)
    yield put(setSendingEmail(false))
    yield put(closeModalEmailEditor())
    yield put(setAlertMessage('Falha ao enviar email.', 'info'))
  }
}

export function* watchLoadEmailsConfig() {
  yield takeEvery(REQUEST_EMAILS_CONFIG, loadEmailConfig)
}

export function* watchLoadEmailsList() {
  yield takeEvery(REQUEST_EMAILS_LIST, loadEmailsList)
}

export function* watchRequestSendEmail() {
  yield takeEvery(REQUEST_SEND_EMAIL, requestSendEmail)
}

function* emailSaga() {
  yield all([
    fork(watchLoadEmailsList),
    fork(watchRequestSendEmail),
    fork(watchLoadEmailsConfig),
  ])
}

export default emailSaga
