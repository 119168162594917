import Bugsnag from "@bugsnag/js";
import { all, put, takeEvery, call, fork } from "redux-saga/effects";
import {
  closeModalInstagram,
  openModalInstagram,
  openModalMessenger,
  closeModalMessenger,
} from "../../actions";

import store from "../../store";
import { REQUEST_VALID_CODE_FACEBOOK } from "./constants";
import { api } from "../../../services/api";

function* requestValidCode({ payload: { code, integration } }) {
  console.log("requestValidCode");
  const state = store.getState();
  const { user } = state.Auth;

  const id_empresa = user.id_empresa;

  if (integration === "instagram") {
    yield put(openModalInstagram());
  }

  if (integration === "messenger") {
    yield put(openModalMessenger());
  }
  try {
    const origin_host = window.location.origin;
    console.log("requestValidCode origin_host =>", origin_host);
    const { data } = yield call(
      api.post,
      `/${integration}/auth/code?origin_host=${origin_host}`,
      {
        code,
        id_empresa,
      }
    );

    console.log("requestCheckUserActive data", data);

    if (integration === "instagram") {
      yield put(closeModalInstagram());
    }

    if (integration === "messenger") {
      yield put(closeModalMessenger());
    }

    return (window.location.href = "/central-atendimento");
  } catch (error) {
    Bugsnag.notify(error)
    console.log("Error loadConfigCentral: ", error);
  }
}

export function* watchRequistValidCode() {
  yield takeEvery(REQUEST_VALID_CODE_FACEBOOK, requestValidCode);
}

function* facebookSaga() {
  yield all([fork(watchRequistValidCode)]);
}

export default facebookSaga;
