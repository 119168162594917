import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Favicon from 'react-favicon'

//Import Components
import LeftSidebarMenu from './LeftSidebarMenu'
import CentralAlert from '../../components/Banner/CentralAlert'

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.capitalizeFirstLetter.bind(this)
  }

  //function for capital first letter of current page pathname
  capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }

  componentDidMount() {
    let currentage = this.capitalizeFirstLetter(this.props.location.pathname)
    //set document title according to page path name
    document.title =
      currentage == 'Central-atendimento' 
        ? 'Central Atendimento ᴮᴱᵀᴬ'
        : ( currentage.startsWith('Configs/call-center/')
        ? 'Configurações - Central Atendimento ᴮᴱᵀᴬ'
        : currentage + ' - Central Atendimento ᴮᴱᵀᴬ')
  }

  render() {
    return (
      <React.Fragment>
        <Favicon url='https://meetspro-site.s3.amazonaws.com/favicon.ico' alertCount={this.props.count_waiting_room}/>
        <CentralAlert />
        <div className="layout-wrapper d-lg-flex">
          {/* left sidebar menu */}
          <LeftSidebarMenu pathname={this.props.location.pathname}  />
          {/* render page content */}
          {this.props.children}
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const {
    count_waiting_room
  } = state.Chat

  return {
    count_waiting_room
  }
}

export default connect(mapStateToProps, {})(withRouter(Index))
