import { all } from "redux-saga/effects";
import authSaga from "./auth/saga";
import chatSaga from "./chat/saga";
import configSaga from "./config/saga";
import emailSaga from "./email/saga";
import smsSaga from "./sms/saga";
import layoutSaga from "./layout/saga";
import wsSaga from "./ws/saga";
import wzapApiSaga from "./integrations/wzap_api_oficial/saga";
import facebookSaga from './integrations/facebook/saga'

export default function* rootSaga(getState) {
  yield all([
    authSaga(),
    chatSaga(),
    configSaga(),
    emailSaga(),
    smsSaga(),
    layoutSaga(),
    wsSaga(),
    wzapApiSaga(),
    facebookSaga()
  ])
}
