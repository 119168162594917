import { REQUEST_SMS_CONFIG, SET_SMS_CONFIG } from "./constants";

export const requestSmsConfig = () => ({
  type: REQUEST_SMS_CONFIG,
});

export const setSmsConfig = (smsConfig) => ({
  type: SET_SMS_CONFIG,
  payload: smsConfig,
});
