import {produce} from 'immer'


const INIT_STATE = {
}

const Instagram = (state = INIT_STATE, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default Instagram
