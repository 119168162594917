import { current, produce } from "immer";

import {
  CHAT_USER,
  CHAT_INIT,
  ACTIVE_CHAT_INDEX,
  ACTIVE_USER_ID,
  FULL_USER,
  ADD_LOGGED_USER,
  CREATE_GROUP,
  SET_PROFILE_USER_WHATSAPP,
  STATE_CHANGE,
  ADD_AUDIO_SOURCE,
  ACK_CHANGE,
  REPLY_CHAT_ID,
  ADD_IMAGE_SOURCE,
  NEW_MESSAGE,
  NEW_CHAT,
  ADD_MESSAGE,
  REMOVE_CHATS,
  ACTIVE_RECIPIENT_ID,
  FILTERED_CHAT,
  SET_EARLIER_MESSAGES,
  SET_NEW_CHAT_MESSAGES,
  PLAY_NOTIFICATION_SOUND,
  ACTIVE_CHAT_MONGO_ID,
  REMOVE_CHAT,
  CONVERSATION_UPDATE_IN_DATABASE,
  REMOVE_DELETED_MESSAGE,
  SET_LEAD_DATA,
  ERROR_SENDING_MESSAGE,
  SET_UNSENT_MESSAGE,
  DELETE_UNSENT_MESSAGE,
  SET_NEW_LEAD_CONVERSATION,
  SET_CURRENT_TEAM_SELECTED,
  SET_CURRENT_ATTENDANT_SELECTED,
  SET_SELECT_TEAMS_OPTIONS,
  SET_SELECT_ATTENDANT_OPTIONS,
  SET_MY_CHATS,
  SET_WAITING_ROOM_CHATS,
  CONVERSATION_UPDATE_WAITING_ROOM_IN_DATABASE,
  CONVERSATION_UPDATE_MY_CHAT_IN_DATABASE,
  REMOVE_WAITING_ROOM_CHAT,
  REMOVE_MY_CHAT,
  SET_COUNT_MY_CHATS,
  SET_COUNT_WAITING_ROOM,
  CLEAR_FETCH_NEW_CHAT,
  FETCH_NEW_CHAT,
  SET_FAILURE_EVENT,
  SET_UNREAD,
  SET_ALL_ROOM_TAGS,
  SET_TEMPLATE_TYPE_MESSAGE,
  MOVE_CHAT,
  CHAT_UPDATE_IN_DATABASE,
  ORDER_CHATS_BY_TAB,
  CLEAR_CHAT_ACTIVED,
  SET_CHAT_MONGO_ID_FOR_BATCH_ACTION,
  CLEAR_CHAT_MONGO_ID_FOR_BATCH_ACTION,
  UPDATE_CHAT,
  SET_ALL_CHAT_MONGO_ID_FOR_BATCH_ACTION,
} from "./constants";

//Import Images
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar7 from "../../assets/images/users/avatar-7.jpg";
import avatar8 from "../../assets/images/users/avatar-8.jpg";
import img6 from "../../assets/images/small/img-6.jpg";
import img4 from "../../assets/images/small/img-4.jpg";
import img7 from "../../assets/images/small/img-7.jpg";
import { lastDayOfQuarterWithOptions } from "date-fns/fp";
import { softConversations } from "../../utils/sortConversations";

const INIT_STATE = {
  active_chat_mongo_id: "",
  active_chat_index: 0,
  active_user_id: "",
  active_recipient_id: "",
  fetch_new_chat: false,
  fetch_new_chat_id: "",
  fetch_new_chat_id_empresa: null,
  fetch_new_chat_id_integracao: null,
  fetch_new_chat_identifier: null,
  fetch_chat_with_bot: null,
  user_picture_whatsapp: "",
  chat_init: true,
  stateChange: "",
  replyChatId: null,
  unsentMessages: {},
  newLeadConversation: null,
  hasNewMessage: false,
  templateTypeMessage: false,
  filtered_chat: false,
  play_notification_sound: false,
  select_teams_options: [],
  select_attendant_options: [],
  current_lead_data: null,
  current_team_selected: { value: null, label: "Todas as equipes", users: [] },
  current_attendant_selected: { value: null, label: "Nenhum responsável" },
  count_my_chats: 0,
  count_waiting_room: 0,
  active_chats_for_batch_action: [],
  users: [
    //admin is sender and user in receiver
    // { id : 0, name : "Patrick Hendricks", profilePicture : avatar2, status : "online", unRead : 0, roomType : "contact", isGroup: false,
    //     messages: [
    //         { id: 1, message: "Seja bem vindo ao Meets", time: new Date().getMilliseconds(), userType: true, isImageMessage : false, isFileMessage : false },
    // ] },
  ],
  my_chats: [],
  waiting_room: [],
  groups: [
    {
      gourpId: 1,
      name: "#General",
      profilePicture: "Null",
      isGroup: true,
      unRead: 0,
      desc: "General Group",
      members: [
        { userId: 1, name: "Sara Muller", profilePicture: "Null", role: null },
        {
          userId: 2,
          name: "Ossie Wilson",
          profilePicture: avatar8,
          role: "admin",
        },
        {
          userId: 3,
          name: "Jonathan Miller",
          profilePicture: "Null",
          role: null,
        },
        { userId: 4, name: "Paul Haynes", profilePicture: avatar7, role: null },
        { userId: 5, name: "Yana sha", profilePicture: avatar3, role: null },
        {
          userId: 6,
          name: "Steve Walker",
          profilePicture: avatar6,
          role: null,
        },
      ],
    },
    {
      gourpId: 2,
      name: "#Reporting",
      profilePicture: "Null",
      isGroup: true,
      unRead: 0,
      desc: "reporing Group here...",
      members: [
        { userId: 1, name: "Sara Muller", profilePicture: "Null", role: null },
        {
          userId: 2,
          name: "Ossie Wilson",
          profilePicture: avatar8,
          role: "admin",
        },
        {
          userId: 3,
          name: "Jonathan Miller",
          profilePicture: "Null",
          role: null,
        },
        { userId: 4, name: "Paul Haynes", profilePicture: avatar7, role: null },
        { userId: 5, name: "Yana sha", profilePicture: avatar3, role: null },
        {
          userId: 6,
          name: "Steve Walker",
          profilePicture: avatar6,
          role: null,
        },
      ],
    },
    {
      gourpId: 3,
      name: "#Designer",
      profilePicture: "Null",
      isGroup: true,
      unRead: 0,
      isNew: true,
      desc: "designers Group",
      members: [
        { userId: 1, name: "Sara Muller", profilePicture: "Null", role: null },
        {
          userId: 2,
          name: "Ossie Wilson",
          profilePicture: avatar8,
          role: "admin",
        },
        {
          userId: 3,
          name: "Jonathan Miller",
          profilePicture: "Null",
          role: null,
        },
        { userId: 4, name: "Paul Haynes", profilePicture: avatar7, role: null },
        { userId: 5, name: "Yana sha", profilePicture: avatar3, role: null },
        {
          userId: 6,
          name: "Steve Walker",
          profilePicture: avatar6,
          role: null,
        },
      ],
    },
    {
      gourpId: 4,
      name: "#Developers",
      profilePicture: "Null",
      isGroup: true,
      unRead: 0,
      desc: "developers Group",
      members: [
        { userId: 1, name: "Sara Muller", profilePicture: "Null", role: null },
        {
          userId: 2,
          name: "Ossie Wilson",
          profilePicture: avatar8,
          role: "admin",
        },
        {
          userId: 3,
          name: "Jonathan Miller",
          profilePicture: "Null",
          role: null,
        },
        { userId: 4, name: "Paul Haynes", profilePicture: avatar7, role: null },
        { userId: 5, name: "Yana sha", profilePicture: avatar3, role: null },
        {
          userId: 6,
          name: "Steve Walker",
          profilePicture: avatar6,
          role: null,
        },
      ],
    },
    {
      gourpId: 5,
      name: "#Project-aplha",
      profilePicture: "Null",
      isGroup: true,
      unRead: 0,
      isNew: true,
      desc: "project related Group",
      members: [
        { userId: 1, name: "Sara Muller", profilePicture: "Null", role: null },
        {
          userId: 2,
          name: "Ossie Wilson",
          profilePicture: avatar8,
          role: "admin",
        },
        {
          userId: 3,
          name: "Jonathan Miller",
          profilePicture: "Null",
          role: null,
        },
        { userId: 4, name: "Paul Haynes", profilePicture: avatar7, role: null },
        { userId: 5, name: "Yana sha", profilePicture: avatar3, role: null },
        {
          userId: 6,
          name: "Steve Walker",
          profilePicture: avatar6,
          role: null,
        },
      ],
    },
    {
      gourpId: 6,
      name: "#Snacks",
      profilePicture: "Null",
      isGroup: true,
      unRead: 0,
      desc: "snacks Group",
      members: [
        { userId: 1, name: "Sara Muller", profilePicture: "Null", role: null },
        {
          userId: 2,
          name: "Ossie Wilson",
          profilePicture: avatar8,
          role: "admin",
        },
        {
          userId: 3,
          name: "Jonathan Miller",
          profilePicture: "Null",
          role: null,
        },
        { userId: 4, name: "Paul Haynes", profilePicture: avatar7, role: null },
        { userId: 5, name: "Yana sha", profilePicture: avatar3, role: null },
        {
          userId: 6,
          name: "Steve Walker",
          profilePicture: avatar6,
          role: null,
        },
      ],
    },
  ],
  contacts: [
    { id: 1, name: "Albert Rodarte" },
    { id: 2, name: "Allison Etter" },
    { id: 3, name: "Craig Smiley" },
    { id: 4, name: "Daniel Clay" },
    { id: 5, name: "Doris Brown" },
    { id: 6, name: "Iris Wells" },
    { id: 7, name: "Juan Flakes" },
    { id: 8, name: "John Hall" },
    { id: 9, name: "Joy Southern" },
    { id: 10, name: "Mary Farmer" },
    { id: 11, name: "Mark Messer" },
    { id: 12, name: "Michael Hinton" },
    { id: 13, name: "Ossie Wilson" },
    { id: 14, name: "Phillis Griffin" },
    { id: 15, name: "Paul Haynes" },
    { id: 16, name: "Rocky Jackson" },
    { id: 17, name: "Sara Muller" },
    { id: 18, name: "Simon Velez" },
    { id: 19, name: "Steve Walker" },
    { id: 20, name: "Hanah Mile" },
  ],
};

const Chat = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHAT_USER:
      return { ...state };

    case CHAT_INIT:
      return {
        ...state,
        chat_init: action.payload,
      };

    case SET_PROFILE_USER_WHATSAPP:
      return {
        ...state,
        user_picture_whatsapp: action.payload,
      };
    case STATE_CHANGE:
      return {
        ...state,
        stateChange: action.payload,
      };

    case REPLY_CHAT_ID:
      return {
        ...state,
        replyChatId: action.payload,
      };

    case SET_UNSENT_MESSAGE:
      return produce(state, (draft) => {
        const idMongo = action.payload.idMongo;
        const message = action.payload.message;
        if (draft.unsentMessages && draft.unsentMessages[idMongo]) {
          draft.unsentMessages[idMongo].message = message;
        } else {
          draft.unsentMessages[idMongo] = { message };
        }
      });

    case SET_UNREAD:
      return produce(state, (draft) => {
        const idMongo = action.payload.idMongo;
        const unRead = action.payload.unRead;

        ["my_chats", "waiting_room", "users"].map((room) => {
          const indexChat = draft[room].findIndex(
            (chat) => chat._id === idMongo
          );
          if (indexChat !== -1) {
            draft[room][indexChat].unRead = unRead;
          }
        });
      });

    case MOVE_CHAT:
      return produce(state, (draft) => {
        const idMongo = action.payload.idMongo;
        const destinatedRoom = action.payload.destinatedRoom;

        let chat;
        ["my_chats", "waiting_room", "users"].map((room) => {
          chat = draft[room].find((chat) => chat._id === idMongo);
        });

        draft[destinatedRoom].push(chat);
      });

    case SET_ALL_ROOM_TAGS:
      return produce(state, (draft) => {
        const idMongo = action.payload.idMongo;
        const tags = action.payload.tags;

        ["my_chats", "waiting_room", "users"].map((room) => {
          const indexChat = draft[room].findIndex(
            (chat) => chat._id === idMongo
          );
          if (indexChat !== -1) {
            draft[room][indexChat].tags = tags;
          }
        });
      });

    case DELETE_UNSENT_MESSAGE:
      return produce(state, (draft) => {
        const idMongo = action.payload;
        if (draft.unsentMessages && draft.unsentMessages[idMongo]) {
          delete draft.unsentMessages[idMongo];

          if (!draft.unsentMessages) {
            draft.unsentMessages = {};
          }
        }
      });

    case SET_FAILURE_EVENT:
      return produce(state, (draft) => {
        const data = action.payload.data;
        const tabName = action.payload.tabName;
        const indexChat = action.payload.indexChat;

        if (indexChat !== -1) {
          const indexMessage = draft[tabName][indexChat].messages.findIndex(
            (message) => message.id === data.id._serialized
          );

          if (indexMessage) {
            draft[tabName][indexChat].messages[indexMessage].type = data.type;
            draft[tabName][indexChat].messages[indexMessage].failure =
              data.failure;

            draft.hasNewMessage = false;
          }
        }
      });
    case UPDATE_CHAT:
      return produce(state, (draft) => {
        const chatUpdated = action.payload.chatUpdated;
        if (!chatUpdated) {
          return false;
        }
        if (chatUpdated?.messages?.length === 0) {
          delete chatUpdated.messages;
        }
        ["my_chats", "waiting_room", "users"].forEach((room) => {
          const indexChat = draft[room].findIndex(
            (chat) => chat._id === chatUpdated._id
          );
          if (indexChat !== -1) {
            draft[room][indexChat] = {
              ...draft[room][indexChat],
              ...chatUpdated,
            };
          }
        });
      });
    case NEW_CHAT:
      return produce(state, (draft) => {
        const newChat = action.payload.chat;
        const tabName = action.payload.tabName;
        const isBot = action.payload.bot;

        const hasChat = draft[tabName].findIndex(
          (user) => user._id === newChat._id
        );

        if (hasChat === -1) {
          draft[tabName].unshift(newChat);

          const chatCurrentIndex = draft[tabName].findIndex(
            (user) => user._id === newChat._id
          );

          if (chatCurrentIndex !== -1) {
            draft[tabName][chatCurrentIndex] = {
              ...draft[tabName][chatCurrentIndex],
              unRead: 1,
            };
          }

          const newIndexChat = draft[tabName].findIndex(
            (user) => user._id === draft.active_chat_mongo_id
          );

          if (newIndexChat !== -1) {
            draft.active_chat_index = newIndexChat;
          }

          if (tabName === "my_chats") {
            draft.count_my_chats += 1;
          }

          if (tabName === "waiting_room") {
            draft.count_waiting_room += 1;
          }
        }

        draft.fetch_new_chat = false;
        draft.fetch_new_chat_id = "";
        draft.fetch_new_chat_id_empresa = null;
        draft.fetch_new_chat_id_integracao = null;
        draft.fetch_new_chat_identifier = null;
        draft.fetch_chat_with_bot = null;
      });
    case CLEAR_FETCH_NEW_CHAT:
      return produce(state, (draft) => {
        draft.fetch_new_chat = false;
        draft.fetch_new_chat_id = "";
        draft.fetch_new_chat_id_empresa = null;
        draft.fetch_new_chat_id_integracao = null;
        draft.fetch_new_chat_identifier = null;
        draft.fetch_chat_with_bot = null;
      });
    case SET_NEW_CHAT_MESSAGES:
      return produce(state, (draft) => {
        const messages = action.payload.data.messages;
        const pagination = action.payload.data.pagination;
        const profilePicture = action.profilePicture;
        const chatId = action.payload.chatId;
        const idMongo = action.payload.idMongo;
        const tipo_mensageiro = action.payload.tipo_mensageiro;

        const indexChatMyChat = draft.my_chats.findIndex(
          (myChat) => myChat._id === idMongo
        );
        const indexChatWaitingRoom = draft.waiting_room.findIndex(
          (myChat) => myChat._id === idMongo
        );
        const indexChatUsers = draft.users.findIndex(
          (user) => user._id === idMongo
        );

        if (indexChatMyChat !== -1) {
          draft.my_chats[indexChatMyChat].pagination = pagination;
          draft.my_chats[indexChatMyChat].messages = messages ? messages : [];
        }
        if (indexChatWaitingRoom !== -1) {
          draft.waiting_room[indexChatWaitingRoom].pagination = pagination;
          draft.waiting_room[indexChatWaitingRoom].messages = messages
            ? messages
            : [];
        }
        if (indexChatUsers !== -1) {
          draft.users[indexChatUsers].pagination = pagination;
          draft.users[indexChatUsers].messages = messages ? messages : [];
        }
      });

    case SET_NEW_LEAD_CONVERSATION:
      return produce(state, (draft) => {
        draft.newLeadConversation = action.payload;
      });

    case SET_EARLIER_MESSAGES:
      return produce(state, (draft) => {
        const messages = action.payload.data.messages;
        const pagination = action.payload.data.pagination;
        const chatId = action.payload.chatId;
        const idMongo = action.payload.idMongo;
        const tipo_mensageiro = action.payload.tipo_mensageiro;

        const tabNames = ["my_chats", "waiting_room", "users"];
        for (const tabName of tabNames) {
          const indexChat = draft[tabName].findIndex(
            (user) => user._id === idMongo
          );

          if (indexChat !== -1) {
            if (JSON.stringify(messages) !== JSON.stringify({})) {
              const activeMessages = draft[tabName][indexChat].messages;

              const _messages = [];

              messages.forEach((message) => {
                if (
                  typeof message === "object" &&
                  Object.keys(message).length > 0
                ) {
                  const indexMessage = activeMessages.findIndex(
                    (activeMessage) => activeMessage.id === message.id
                  );

                  if (indexMessage === -1) {
                    _messages.push(message);
                  }
                }
              });

              const newMessages = _messages.concat(activeMessages);

              console.log("newMessages", newMessages);
              draft[tabName][indexChat].pagination = pagination;
              draft[tabName][indexChat].messages = newMessages;
            }
          }
        }
      });

    case NEW_MESSAGE:
      const tabName = action.payload.tabName;
      const message = action.payload.message;
      const indexChat = action.payload.indexChat;
      return produce(state, (draft) => {
        if (indexChat !== -1) {
          let indexMessage;
          const temporaryMessageId = message.temporaryMessageId;

          if (
            temporaryMessageId &&
            [
              "file",
              "document",
              "audio",
              "sticker",
              "chat",
              "image",
              "video",
              "ptt",
            ].includes(message.type)
          ) {
            indexMessage = draft[tabName][indexChat].messages.findIndex(
              (m) => m.temporaryMessageId === temporaryMessageId
            );
          } else {
            indexMessage = draft[tabName][indexChat].messages.findIndex(
              (m) => m.id === message.id
            );
          }

          console.log({ indexChat, temporaryMessageId, indexMessage });

          if (indexMessage === -1) {
            draft[tabName][indexChat].messages.push(message);

            console.log("message?.time", message?.time);
            draft[tabName][indexChat].time_ultima_msg = message?.time;
            console.log("chat =>", draft[tabName][indexChat].time_ultima_msg);
            if (!message.userType) {
              draft.play_notification_sound = true;
              //if (message.ack == 0 && !message.chatId._serialized)
              if (message.ack == 0) {
                //draft[tabName][indexChat].unRead = 1;
                let newUnReadValue = 0;
                if (draft[tabName][indexChat].unRead === -1) {
                  newUnReadValue = 1;
                } else {
                  newUnReadValue = draft[tabName][indexChat].unRead
                    ? draft[tabName][indexChat].unRead + 1
                    : 1;
                }
                draft[tabName][indexChat].unRead = newUnReadValue;
              }
            }

            draft.hasNewMessage = true;

            let userContent = current(draft[tabName][indexChat]);

            draft[tabName].splice(indexChat, 1);

            draft[tabName].unshift(userContent);

            draft[tabName] = softConversations(draft[tabName]);

            const newIndexChat = draft[tabName].findIndex(
              (user) => user._id === draft.active_chat_mongo_id
            );

            if (newIndexChat !== -1) {
              draft.active_chat_index = newIndexChat;
            }
          } else {
            const diffMessage =
              draft[tabName][indexChat].messages[indexMessage];
            draft[tabName][indexChat].messages[indexMessage] = {
              ...diffMessage,
              ...message,
            };
          }
        }
      });
    case FETCH_NEW_CHAT:
      return produce(state, (draft) => {
        const message = action.payload;

        if (message?.chatId?._serialized) {
          draft.fetch_new_chat_id = message.chatId._serialized;
        } else {
          draft.fetch_new_chat_id = message.chatId;
        }

        draft.fetch_new_chat_id_empresa = message.idEmpresa;
        draft.fetch_new_chat_id_integracao = message.idIntegracao;
        draft.fetch_new_chat_identifier = message.identifier;
        draft.fetch_chat_with_bot = message.isBot;

        draft.fetch_new_chat = true;
      });
    case ADD_MESSAGE:
      return produce(state, (draft) => {
        const messageToAdd = action.payload;
        const indexChat = draft.users.findIndex(
          (user) => user._id === draft.active_chat_mongo_id
        );

        draft.users[indexChat].messages.push(messageToAdd);

        if (messageToAdd.type === "ptt") {
          draft.users[indexChat].loadingFile = true;
        }

        let userContent = current(draft.users[indexChat]);

        draft.users.splice(indexChat, 1);

        draft.users.unshift(userContent);

        const newIndexChat = draft.users.findIndex(
          (user) => user._id === draft.active_chat_mongo_id
        );

        draft.active_chat_index = newIndexChat;

        draft.hasNewMessage = true;
      });
    case ACK_CHANGE:
      return produce(state, (draft) => {
        const ackData = action.payload.ackData;
        const tabName = action.payload.tabName;
        const indexChat = action.payload.indexChat;
        // let chatId = ''

        // if (ackData.id.fromMe) {
        //   chatId = ackData.to
        // } else {
        //   chatId = ackData.from
        // }
        let idMessage = null;
        if (ackData.id._serialized) {
          idMessage = ackData.id._serialized;
        } else {
          idMessage = ackData.id;
        }

        // const indexUser = draft[tabName].findIndex(
        //   (user) =>
        //     user.id === chatId &&
        //     user.idEmpresa === ackData.idEmpresa &&
        //     user.idIntegracao === ackData.idIntegracao,
        // )
        // console.log('indexUser', indexUser)
        // console.log('payload', action.payload)
        if (indexChat !== -1) {
          const indexMessage = draft[tabName][indexChat].messages.findIndex(
            (message) => message.id === idMessage
          );

          const hasMessage = draft[tabName][indexChat].messages[indexMessage];

          if (hasMessage) {
            if (ackData.nome_usuario) {
              draft[tabName][indexChat].messages[indexMessage].id_usuario =
                ackData.id_usuario;
              draft[tabName][indexChat].messages[indexMessage].nome_usuario =
                ackData.nome_usuario;
            }

            const lastS3FilePath =
              draft[tabName][indexChat].messages[indexMessage].s3FilePath;
            if (ackData.s3FilePath && !lastS3FilePath) {
              draft[tabName][indexChat].messages[indexMessage].s3FilePath =
                ackData.s3FilePath;
            }

            draft[tabName][indexChat].messages[indexMessage].ack = ackData.ack;
          }

          const fromMe = ackData.id.fromMe ?? ackData.userType;

          if (!fromMe) {
            draft[tabName][indexChat].unRead =
              +draft[tabName][indexChat].unRead + 1;
          }

          // if (ackData.ack >= 3) {
          //  draft[tabName][indexChat].unRead = 0;
          // }

          draft.hasNewMessage = false;
        }
      });
    case SET_COUNT_MY_CHATS:
      return produce(state, (draft) => {
        draft.count_my_chats = action.payload;
      });

    case SET_COUNT_WAITING_ROOM:
      return produce(state, (draft) => {
        draft.count_waiting_room = action.payload;
      });

    case ERROR_SENDING_MESSAGE:
      return produce(state, (draft) => {
        const message = action.payload;
        const chatId = message.chatId._serialized;

        const indexChat = draft.users.findIndex(
          (user) =>
            user.id === chatId &&
            user.idEmpresa === message.idEmpresa &&
            user.idIntegracao === message.idIntegracao
        );

        if (indexChat !== -1) {
          let indexMessage = null;

          if (message.type === "chat") {
            indexMessage = draft.users[indexChat].messages.findIndex(
              (messageInChat) =>
                messageInChat.ack === null &&
                messageInChat.message === message.message &&
                messageInChat.chatId._serialized === chatId
            );
          } else {
            indexMessage = draft.users[indexChat].messages.findIndex(
              (messageInChat) =>
                messageInChat.ack === null &&
                messageInChat.chatId._serialized === chatId
            );
          }

          if (indexMessage !== -1) {
            draft.users[indexChat].messages[indexMessage].errorSending = true;
          }
        }
      });
    case ACTIVE_CHAT_INDEX:
      return {
        ...state,
        active_chat_index: action.payload,
      };
    case ACTIVE_USER_ID:
      return {
        ...state,
        active_user_id: action.payload,
      };

    case ACTIVE_RECIPIENT_ID:
      return {
        ...state,
        active_recipient_id: action.payload,
      };

    case ACTIVE_CHAT_MONGO_ID:
      return {
        ...state,
        active_chat_mongo_id: action.payload,
      };

    case SET_SELECT_TEAMS_OPTIONS:
      return produce(state, (draft) => {
        const teamsOptions = action.payload;
        draft.select_teams_options = teamsOptions;
      });

    case SET_SELECT_ATTENDANT_OPTIONS:
      return produce(state, (draft) => {
        const attendantOptions = action.payload;
        draft.select_attendant_options = attendantOptions;
      });

    case SET_LEAD_DATA:
      return produce(state, (draft) => {
        const leadData = action.payload;
        draft.current_lead_data = leadData;
      });

    case SET_CURRENT_TEAM_SELECTED:
      return produce(state, (draft) => {
        const currentTeam = action.payload;
        draft.current_team_selected = currentTeam;
      });

    case SET_CURRENT_ATTENDANT_SELECTED:
      return produce(state, (draft) => {
        const currentAttendant = action.payload;
        draft.current_attendant_selected = currentAttendant;
      });

    case FULL_USER:
      return produce(state, (draft) => {
        if (draft.filtered_chat) {
          draft.users = action.payload;
          draft.filtered_chat = false;
        } else {
          (action.payload || []).map((chat) => {
            const indexFind = draft.users.findIndex(
              (user) => user._id === chat._id
            );

            if (indexFind === -1) {
              draft.users.push(chat);
            } else {
              if (chat.messages.length === 0) {
                delete chat.messages;
              }
              draft.users[indexFind] = {
                ...draft.users[indexFind],
                ...chat,
              };
            }
          });
        }

        draft.users = softConversations(draft.users);
      });

    case SET_MY_CHATS:
      return produce(state, (draft) => {
        if (draft.filtered_chat) {
          draft.my_chats = action.payload;
          draft.filtered_chat = false;
        } else {
          (action.payload || []).map((chat) => {
            const indexFind = draft.my_chats.findIndex(
              (user) => user._id === chat._id
            );

            if (indexFind === -1) {
              draft.my_chats.push(chat);
            } else {
              if (chat.messages.length === 0) {
                delete chat.messages;
              }
              draft.my_chats[indexFind] = {
                ...draft.my_chats[indexFind],
                ...chat,
              };
            }
          });
        }
        draft.my_chats = softConversations(draft.my_chats);
      });

    case SET_WAITING_ROOM_CHATS:
      return produce(state, (draft) => {
        if (draft.filtered_chat) {
          draft.waiting_room = action.payload;
          draft.filtered_chat = false;
        } else {
          (action.payload || []).map((chat, index) => {
            const indexFind = draft.waiting_room.findIndex(
              (user) => user._id === chat._id
            );
            if (indexFind === -1) {
              draft.waiting_room.push(chat);
            } else {
              if (chat.messages.length === 0) {
                delete chat.messages;
              }
              draft.waiting_room[indexFind] = {
                ...draft.waiting_room[indexFind],
                ...chat,
              };
            }
          });
        }
        draft.waiting_room = softConversations(draft.waiting_room);
      });

    case REMOVE_CHATS:
      return produce(state, (draft) => {
        const idIntegracao = action.payload;
        const chatsUsersRomm = state.users.filter(
          (chat) => chat.idIntegracao !== idIntegracao
        );
        const chatsMyChatsRoom = state.my_chats.filter(
          (chat) => chat.idIntegracao !== idIntegracao
        );
        const chatsWaitingRoom = state.waiting_room.filter(
          (chat) => chat.idIntegracao !== idIntegracao
        );

        draft.users = chatsUsersRomm ?? [];
        draft.my_chats = chatsMyChatsRoom ?? [];
        draft.waiting_room = chatsWaitingRoom ?? [];

        draft.count_my_chats = chatsMyChatsRoom.length;
        draft.count_waiting_room = chatsWaitingRoom.length;
      });

    case REMOVE_CHAT:
      return produce(state, (draft) => {
        const { idMongo, tabName, cleanScreen, activeChatsTab } =
          action.payload;
        const chats = state.users.filter((chat) => chat._id !== idMongo);

        draft.users = chats;
        if (chats.length > 0) {
          if (tabName === "allChatsTab") {
            if (cleanScreen) {
              draft.active_chat_index = 0;
              draft.active_chat_mongo_id = "";
              draft.active_recipient_id = "";
              draft.active_user_id = "";
              draft.chat_init = true;
            } else {
              const isChatActive = draft.active_chat_mongo_id === idMongo;

              if (isChatActive && activeChatsTab === "users") {
                draft.active_chat_index = 0;
                draft.active_chat_mongo_id = "";
                draft.active_recipient_id = "";
                draft.active_user_id = "";
                draft.chat_init = true;
              }
            }
          }
        } else {
          if (tabName === "allChatsTab") {
            draft.active_chat_index = 0;
            draft.active_chat_mongo_id = "";
            draft.active_recipient_id = "";
            draft.active_user_id = "";
            draft.chat_init = true;
          }
        }
      });

    case REMOVE_MY_CHAT:
      return produce(state, (draft) => {
        const { idMongo, tabName, cleanScreen, activeChatsTab } =
          action.payload;

        if (state.my_chats.find((chat) => chat._id === idMongo)) {
          draft.count_my_chats -= 1;

          const chats = state.my_chats.filter((chat) => chat._id !== idMongo);
          draft.my_chats = chats;

          if (chats.length > 0) {
            if (tabName === "myConversationsTab") {
              if (cleanScreen) {
                draft.active_chat_index = 0;
                draft.active_chat_mongo_id = "";
                draft.active_recipient_id = "";
                draft.active_user_id = "";
                draft.chat_init = true;
              } else {
                const isChatActive = draft.active_chat_mongo_id === idMongo;

                if (isChatActive && activeChatsTab === "my_chats") {
                  draft.active_chat_index = 0;
                  draft.active_chat_mongo_id = "";
                  draft.active_recipient_id = "";
                  draft.active_user_id = "";
                  draft.chat_init = true;
                }
              }
            }
          } else {
            if (tabName === "myConversationsTab") {
              draft.active_chat_index = 0;
              draft.active_chat_mongo_id = "";
              draft.active_recipient_id = "";
              draft.active_user_id = "";
              draft.chat_init = true;
            }
          }
        }
      });

    case REMOVE_WAITING_ROOM_CHAT:
      return produce(state, (draft) => {
        const { idMongo, tabName, cleanScreen, activeChatsTab } =
          action.payload;

        if (state.waiting_room.find((chat) => chat._id === idMongo)) {
          draft.count_waiting_room -= 1;

          const chats = state.waiting_room.filter(
            (chat) => chat._id !== idMongo
          );

          draft.waiting_room = chats;

          if (chats.length > 0) {
            if (tabName === "waitingRoomTab") {
              if (cleanScreen) {
                draft.active_chat_index = 0;
                draft.active_chat_mongo_id = "";
                draft.active_recipient_id = "";
                draft.active_user_id = "";
                draft.chat_init = true;
              } else {
                const isChatActive = draft.active_chat_mongo_id === idMongo;

                if (isChatActive && activeChatsTab === "waiting_room") {
                  draft.active_chat_index = 0;
                  draft.active_chat_mongo_id = "";
                  draft.active_recipient_id = "";
                  draft.active_user_id = "";
                  draft.chat_init = true;
                }
              }
            }
          } else {
            if (tabName === "waitingRoomTab") {
              draft.active_chat_index = 0;
              draft.active_chat_mongo_id = "";
              draft.active_recipient_id = "";
              draft.active_user_id = "";
              draft.chat_init = true;
            }
          }
        }
      });

    case CONVERSATION_UPDATE_IN_DATABASE:
      return produce(state, (draft) => {
        const idMongo = action.payload.idMongo;
        const updatedChat = action.payload.updatedChat;

        const newIndexChat = draft.users.findIndex(
          (user) => user._id === idMongo
        );

        console.log({ idMongo, newIndexChat });

        if (newIndexChat !== -1) {
          draft.users[newIndexChat] = {
            ...draft.users[newIndexChat],
            idResponsavel: updatedChat.idResponsavel,
            idEquipe: updatedChat.idEquipe,
            idOportunidade: updatedChat.idOportunidade,
            idCliente: updatedChat.idCliente,
            tags: updatedChat.tags,
            messages: draft.users[newIndexChat].messages,
            pagination: draft.users[newIndexChat].pagination,
          };
        } else {
          draft.users.unshift({
            ...updatedChat,
            messages: [],
            pagination: null,
          });

          const newIndexChat = draft.users.findIndex(
            (user) => user._id === draft.active_chat_mongo_id
          );

          draft.active_chat_index = newIndexChat;
        }

        draft.users = softConversations(draft.users);
      });

    case CONVERSATION_UPDATE_WAITING_ROOM_IN_DATABASE:
      return produce(state, (draft) => {
        const idMongo = action.payload.idMongo;
        const updatedChat = action.payload.updatedChat;

        const newIndexChat = draft.waiting_room.findIndex(
          (user) => user._id === idMongo
        );

        console.log({ idMongo, newIndexChat });

        if (newIndexChat !== -1) {
          draft.waiting_room[newIndexChat] = {
            ...draft.waiting_room[newIndexChat],
            idResponsavel: updatedChat.idResponsavel,
            idEquipe: updatedChat.idEquipe,
            idOportunidade: updatedChat.idOportunidade,
            idCliente: updatedChat.idCliente,
            tags: updatedChat.tags,
            messages: draft.waiting_room[newIndexChat].messages,
            pagination: draft.waiting_room[newIndexChat].pagination,
          };
        } else {
          draft.waiting_room.unshift({
            ...updatedChat,
            messages: [],
            pagination: null,
          });

          const newIndexChat = draft.waiting_room.findIndex(
            (user) => user._id === draft.active_chat_mongo_id
          );

          draft.active_chat_index = newIndexChat;

          draft.count_waiting_room += 1;
        }

        draft.waiting_room = softConversations(draft.waiting_room);
      });

    case CONVERSATION_UPDATE_MY_CHAT_IN_DATABASE:
      return produce(state, (draft) => {
        const idMongo = action.payload.idMongo;
        const updatedChat = action.payload.updatedChat;

        const newIndexChat = draft.my_chats.findIndex(
          (user) => user._id === idMongo
        );

        console.log({ idMongo, newIndexChat });

        if (newIndexChat !== -1) {
          draft.my_chats[newIndexChat] = {
            ...draft.my_chats[newIndexChat],
            idResponsavel: updatedChat.idResponsavel,
            idEquipe: updatedChat.idEquipe,
            idOportunidade: updatedChat.idOportunidade,
            idCliente: updatedChat.idCliente,
            tags: updatedChat.tags,
            messages: draft.my_chats[newIndexChat].messages,
            pagination: draft.my_chats[newIndexChat].pagination,
          };
        } else {
          draft.my_chats.unshift({
            ...updatedChat,
            messages: [],
            pagination: null,
          });

          const newIndexChat = draft.my_chats.findIndex(
            (user) => user._id === draft.active_chat_mongo_id
          );

          draft.active_chat_index = newIndexChat;

          draft.count_my_chats += 1;
        }

        draft.my_chats = softConversations(draft.my_chats);
      });

    case CHAT_UPDATE_IN_DATABASE:
      return produce(state, (draft) => {
        const idMongo = action.payload.idMongo;
        const { profilePicUrl } = action.payload.updatedChat;

        const rooms = ["my_chats", "waiting_room", "users"];
        rooms.map((room) => {
          const indexChat = draft[room].findIndex(
            (chat) => chat._id === idMongo
          );

          if (indexChat !== -1) {
            if (profilePicUrl) {
              draft[room][indexChat].profilePicture = profilePicUrl;
            }
          }
        });
      });
    case REMOVE_DELETED_MESSAGE:
      return produce(state, (draft) => {
        const idMongoChat = action.payload.idMongoChat;
        const chatId = action.payload.chatId;
        const messagesIdArray = action.payload.messagesIdArray;
        ["my_chats", "waiting_room", "users"].map((room) => {
          const indexChat = draft[room].findIndex(
            (chat) => chat._id === idMongoChat
          );

          if (indexChat !== -1) {
            const updatedMessages = draft[room][indexChat].messages.filter(
              (message) =>
                !messagesIdArray.some((messageId) => messageId === message.id)
            );

            draft[room][indexChat].messages = updatedMessages;
          }
        });
      });

    case ADD_AUDIO_SOURCE:
      return produce(state, (draft) => {
        const indexChat = action.payload.chatId;
        const tabName = action.payload.tabName;

        if (indexChat !== -1) {
          const hasIndexMessage =
            draft[tabName][indexChat].messages[action.payload.messageId];

          if (hasIndexMessage !== undefined) {
            draft[tabName][indexChat].messages[
              action.payload.messageId
            ].audioSource = action.payload.audioSource;
          } else {
            const indexMessage = draft[tabName][indexChat].messages.findIndex(
              (messageInChat) => messageInChat.id === action.payload.messageId
            );

            draft[tabName][indexChat].messages[indexMessage].audioSource =
              action.payload.audioSource;
          }

          draft[tabName][indexChat].loadingFile = false;
        } else {
          draft.fetch_new_chat = true;

          if (message.chatId._serialized) {
            draft.fetch_new_chat_id = message.chatId._serialized;
          } else {
            draft.fetch_new_chat_id = message.chatId;
          }
          draft.fetch_new_chat_id_empresa = message.idEmpresa;
          draft.fetch_new_chat_id_integracao = message.idIntegracao;
          draft.fetch_new_chat_identifier = message.identifier;
        }
      });

    case ADD_IMAGE_SOURCE:
      return produce(state, (draft) => {
        const indexChat = action.payload.chatId;
        const tabName = action.payload.tabName;

        draft[tabName][indexChat].messages[
          action.payload.messageId
        ].imageMessage = action.payload.imageSource;
      });

    case ADD_LOGGED_USER:
      const newUser = action.payload;
      return {
        ...state,
        users: [...state.users, newUser],
      };

    case CREATE_GROUP:
      const newGroup = action.payload;
      return {
        ...state,
        groups: [...state.groups, newGroup],
      };

    case FILTERED_CHAT:
      return produce(state, (draft) => {
        draft.filtered_chat = action.payload;
      });

    case PLAY_NOTIFICATION_SOUND:
      return produce(state, (draft) => {
        draft.play_notification_sound = action.payload;
      });
    case SET_TEMPLATE_TYPE_MESSAGE:
      return produce(state, (draft) => {
        draft.templateTypeMessage = action.payload;
      });
    case ORDER_CHATS_BY_TAB:
      return produce(state, (draft) => {
        let tabName = action.payload.tabName;
        switch (tabName) {
          case "waitingRoomTab":
            tabName = "waiting_room";
            break;
          case "myConversationsTab":
            tabName = "my_chats";
            break;
          case "allChats":
          default:
            tabName = "users";
            break;
        }
        draft[tabName] = softConversations(draft[tabName]);
      });
    case CLEAR_CHAT_ACTIVED:
      return produce(state, (draft) => {
        const idIntegracao = action.payload.idIntegracao;

        if (
          draft.users.find(
            (chat) =>
              chat._id === draft.active_chat_mongo_id &&
              chat.idIntegracao === idIntegracao
          )
        ) {
          draft.active_chat_index = 0;
          draft.active_chat_mongo_id = "";
          draft.active_recipient_id = "";
          draft.active_user_id = "";
          draft.chat_init = true;
        }
      });
    case SET_CHAT_MONGO_ID_FOR_BATCH_ACTION:
      return produce(state, (draft) => {
        const idMongo = action.payload.idMongo;

        const hasId = draft.active_chats_for_batch_action.find(
          (item) => item.idMongo === idMongo
        );

        if (hasId) {
          draft.active_chats_for_batch_action =
            draft.active_chats_for_batch_action.filter(
              (item) => item.idMongo !== idMongo
            );
        } else {
          draft.active_chats_for_batch_action.push(action.payload);
        }
      });

    case SET_ALL_CHAT_MONGO_ID_FOR_BATCH_ACTION:
      return produce(state, (draft) => {
        draft.active_chats_for_batch_action = action.payload;
      });
    case CLEAR_CHAT_MONGO_ID_FOR_BATCH_ACTION:
      return produce(state, (draft) => {
        draft.active_chats_for_batch_action = [];
      });

    default:
      return { ...state };
  }
};

export default Chat;
