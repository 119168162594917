import React from 'react'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Form,
  Label,
  Input,
  InputGroup,
} from 'reactstrap'
import * as Unicons from '@iconscout/react-unicons'
import { connect } from 'react-redux'

import {
  openModalInfo,
  closeModalInfo,
  setLoadingRequest,
} from '../../redux/actions'

function Info(props) {
  function closeModal() {
    props.setLoadingRequest(false)
    props.closeModalInfo()
  }
  return (
    <Modal tabIndex="-1" isOpen={props.modalInfo} centered>
      <ModalBody>
        {props.modalInfoData && (
          <div className="modal_encerrar text-center p-4">
            {props.modalInfoData.type === 'error' ? (
              <div className="mx-auto mb-1">
                <Unicons.UilExclamationOctagon color="#ed2d2d" />
              </div>
            ) : (
              <div className="mx-auto mb-1">
                <Unicons.UilCommentVerify />
              </div>
            )}

            <div className="mt-4 msg_encerrar">
              <h4>{props.modalInfoData.title}</h4>
              <h6>{props.modalInfoData.msg}</h6>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter className="border-0">
        <Button color="primary" onClick={() => closeModal()}>
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  const { modalInfo, modalInfoData } = state.Layout
  return { modalInfo, modalInfoData }
}

export default connect(mapStateToProps, {
  openModalInfo,
  closeModalInfo,
  setLoadingRequest,
})(Info)
