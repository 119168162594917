import { WS_CONNECTED } from "./constants";

import {
  addQrCode,
  setNewChat,
  setFullUser,
  qrCodeError,
  activeUserId,
  setNewMessage,
  scrollToBottom,
  activeChatIndex,
  setAlertMessage,
  openModalUseHere,
  requestCheckLead,
  openModalConflit,
  activeRecipientId,
  setSendingMessage,
  openModalWhatsapp,
  setHas24hMessage,
  addConnectionStatus,
  setEarlierMessages,
  setHeightChatConversationPx,
  setCurrentMessageIdDownloading,
  setConversationUpdateInDatabase,
  setConversationUpdateWaitingRoomInDatabase,
  setConversationUpdateMyChatInDatabase,
  setChatUpdateInDatabase,
  setRemoveDeletedMessege,
  closeModalDeleteMessege,
  requestDeleteProcessos,
  setProcessToReconnect,
  removeWaitingRoomChat,
  closeModalReconnect,
  closeModalMessenger,
  closeModalWhatsapp,
  setUserImgWhatsapp,
  setNewChatMessages,
  setServerConnected,
  setFilterTabChat,
  setFailureEvent,
  removeWebsocket,
  setStateChange,
  updateChatInDb,
  setPagination,
  removeMyChat,
  fitchNewChat,
  setChatInit,
  setStatusWs,
  removeChat,
  addServer,
  ackChange,
  setDisconnect,
  removeChats,
  clearChatIsActived,
  setTemplateTypeMessage,
} from "../actions";
import {
  fitchChatsInDb,
  fitchMyChatsInDb,
  fitchWaitingRoomChatsInDb,
  setChatUpdated,
  setErrorSendingMessage,
  setOnConversationUpdateInDatabase,
  setOnNewChatEvent,
  setUnreadAllRooms,
  sortConversationsByTabName,
} from "../chat/actions";
import {
  openModalDeviceNotConnected,
  closeModalLoadAllMessages,
  setLoadingChatInDb,
  setLoadingRequest,
  openModalConflitNewDeviceConnected,
} from "../layout/actions";
import {
  checkFilterActives,
  checkUserRestrictions,
} from "../../utils/checkUserRestrictions";
import checkMessageTabRestriction from "../../utils/checkMessageTabRestriction";
import checkAckTabRestriction from "../../utils/checkAckTabRestriction";
import getChannelIndex from "../../utils/websocket/getChannelIndex";
import { api } from "../../services/api";
import Bugsnag from "@bugsnag/js";

let timeToRequestChat = 0;

const newChatEvent = (store, data) => {
  store.dispatch(setOnNewChatEvent(data));
};

const getChats = (store, data) => {
  console.log("getChats ====> ", data);
  if (!data.identifier || !data.idIntegracao || !data.chatId) {
    return false;
  }
  let state = store.getState();
  let chatId = null;
  if (data?.chatId?._serialized) {
    chatId = data.chatId._serialized;
  } else {
    chatId = data.chatId;
  }
  const channelIndex = getChannelIndex(data.identifier, data.idIntegracao);

  console.log("channelIndex", channelIndex);
  if (channelIndex === -1) {
    return false;
  }
  try {
    state.WebService.wsChannel[channelIndex].emit("getChat", {
      chatId: chatId,
      idEmpresa: data.idEmpresa,
      idIntegracao: data.idIntegracao,
      identifier: data.identifier,
      bot: !data.bot_finalizado,
    });
  } catch (error) {
    Bugsnag.notify(error);
  }

  setTimeout(() => {
    state = store.getState();
    if (state.Chat.users.find((user) => user.id === chatId)) {
      return true;
    }

    api
      .get(
        `/db/mongo/conversas/${data.idEmpresa}/${data.idIntegracao}/${data.identifier}/${chatId}`
      )
      .then((response) => {
        const chatEmit = response.data;
        newChatEvent(store, { chat: chatEmit, bot: !chatEmit.bot_finalizado });
      })
      .catch((error) => {
        Bugsnag.notify(error);
        console.log("Error getChats -> middleware -> ws ===========>", error);
      });
  }, 2500);
  return true;
};

export const newMessageEvent = (store, data) => {
  console.log("onNewMessage =>", data);

  const state = store.getState();

  if (
    ["messenger", "instagram", "wzap_api_oficial"].includes(
      data.tipoMensageiro
    ) &&
    !data.userType &&
    state.Chat.active_recipient_id === data.chatId
  ) {
    store.dispatch(setHas24hMessage(false));
    store.dispatch(setHeightChatConversationPx(0));
    store.dispatch(setTemplateTypeMessage(false));
  }

  const tabChatNames = Object.entries(state.Layout.filterTabChat).map(
    (tab) => tab[0]
  );

  let hasChat = -1;

  tabChatNames.forEach((tabName) => {
    const { indexChat, stateName, idMongo } = checkMessageTabRestriction(
      tabName,
      data
    );

    console.log("{indexChat, stateName}", { indexChat, stateName });

    if (indexChat !== undefined && indexChat !== -1) {
      hasChat = indexChat;

      store.dispatch(setNewMessage(stateName, indexChat, data));
      if (data.userType && data.outOfHours !== undefined && !data.outOfHours) {
        store.dispatch(setUnreadAllRooms(idMongo, 0));
      }
    }
  });

  if (hasChat < 0) {
    if (timeToRequestChat) clearTimeout(timeToRequestChat);
    setTimeout(() => getChats(store, data), 300);
  }

  store.dispatch(setSendingMessage(false));
  store.dispatch(setLoadingRequest(false));
};

const webSocketMiddleware = () => {
  let channel_name = null;
  let whatsapp = null;
  let messenger = null;
  let whatsapp_api = null;
  let widget = null;
  let instagram = null;
  let id_integracao = null;

  const onAlertMessage = (store) => (data) => {
    if (data.event === "sendMessage") {
      store.dispatch(setErrorSendingMessage(data.data));
    }
    store.dispatch(setAlertMessage(data.message, data.type));
  };

  const onMessage = (store) => (data) => {
    store.dispatch(setStateChange(data));
  };

  const onReady = (store, socket) => () => {
    console.log("onReady sucess");
  };

  const onConflitNewDeviceConnected = (store, socket) => (data) => {
    const { host, id, id_empresa, pm2_app } = data;
    console.log("onConflitNewDeviceConnected sucess");
    store.dispatch(closeModalWhatsapp());
    store.dispatch(openModalConflitNewDeviceConnected());
    store.dispatch(clearChatIsActived(id));
    store.dispatch(removeChats(id));
    store.dispatch(removeWebsocket(id, "whatsapp"));
    store.dispatch(
      requestDeleteProcessos(host, id, id_empresa, pm2_app, "whatsapp")
    );
  };

  const onStatusWs = (store, socket) => (data) => {
    console.log("onStatusWs =>", data);
    store.dispatch(setStatusWs(data));
  };

  const onReadyIntegrationWs = (store, socket) => (data) => {
    console.log("onReadyIntegrationWs =>", data);

    const id = data.id_integracao;
    const tipo_mensageiro = data.tipo_mensageiro;
    const identifier = data.identifier;
    store.dispatch(
      addServer({
        id,
        tipo_mensageiro,
        identifier,
      })
    );

    const state = store.getState();

    store.dispatch(fitchMyChatsInDb(state.Auth.user.id_usuario));
    store.dispatch(fitchWaitingRoomChatsInDb());
    store.dispatch(fitchChatsInDb());
    store.dispatch(closeModalReconnect());
    store.dispatch(setSendingMessage(false));
    store.dispatch(setLoadingChatInDb(false));
  };

  const onLoadingChatInDb = (store, socket) => (data) => {
    console.log("onLoadingChatInDb =>", data);
    store.dispatch(setLoadingChatInDb(true));
  };

  const onWppConnected = (store, socket) => (data) => {
    console.log("onWppConnected =>", data);
  };

  const onWsReconnected = (store, socket) => (data) => {
    console.log("onWppConnected sucess", data);
    store.dispatch(closeModalWhatsapp());
    store.dispatch(closeModalMessenger());
    store.dispatch(closeModalReconnect());
    store.dispatch(setServerConnected());
    store.dispatch(setLoadingChatInDb(false));
    store.dispatch(
      addConnectionStatus(data.id_integracao, data.tipo_mensageiro, true)
    );
  };

  const onAck = (store, socket) => (data) => {
    console.log("onAck =>", data);
    const state = store.getState();

    const tabChatNames = Object.entries(state.Layout.filterTabChat).map(
      (tab) => tab[0]
    );

    let hasChat = -1;
    tabChatNames.forEach((tabName) => {
      const { indexChat, stateName } = checkAckTabRestriction(tabName, data);

      console.log("ackChange {indexChat, stateName}", { indexChat, stateName });

      if (indexChat !== undefined && indexChat !== -1) {
        hasChat = indexChat;
        store.dispatch(ackChange(stateName, indexChat, data));

        if (data.downloadComplete) {
          store.dispatch(setCurrentMessageIdDownloading(null));
        }
      }
    });

    if (hasChat < 0) {
      getChats(store, data);
    }
  };

  const onDeviceNotConnected = (store, socket) => (data) => {
    console.log("Seu celular foi desconectado");
    const state = store.getState();

    const processos = state.WebService.processos;

    const processo = processos[data.tipo_mensageiro].find(
      (processo) =>
        processo.id === parseInt(data.id_integracao) &&
        processo.id_empresa === parseInt(data.id_empresa)
    );

    store.dispatch(
      requestDeleteProcessos(
        processo.host,
        processo.id,
        data.id_empresa,
        processo.pm2_app,
        data.tipo_mensageiro,
        data.tipo_mensageiro === "messenger" ? processo.id_pagina : null
      )
    );

    store.dispatch(openModalDeviceNotConnected());
  };

  const onStateChange = (store, socket) => (data) => {
    console.log("onStateChange =>", data);
    const state = store.getState();

    const { whatsapp } = state.WebService.processos;

    if (data === "CONNECTED") {
      store.dispatch(closeModalWhatsapp());
      store.dispatch(setServerConnected());
      store.dispatch(setLoadingChatInDb(false));
      store.dispatch(
        addConnectionStatus(data.id_integracao, data.tipo_mensageiro, true)
      );
    }
    if (data.state === "CONFLICT") {
      const processos = state.WebService.processos;

      const processo = processos[data.tipo_mensageiro].find(
        (processo) => processo.id === parseInt(data.id_integracao)
      );

      store.dispatch(setProcessToReconnect(processo));
      store.dispatch(openModalUseHere());
    }

    if (data.state === "PAIRING") {
      console.log("Usuário desconectou", data.stat);
      store.dispatch(openModalConflit(data.id_integracao));
    }

    if (data.state === "UNPAIRED") {
      store.dispatch(openModalConflit(data.id_integracao));
    }

    if (data.state === "WA_SESSION_DISCONNECTED") {
      const processo = whatsapp.find(
        (p) => p.id === data.id_integracao && p.phonenumber === data.identifier
      );

      store.dispatch(clearChatIsActived(processo.id));
      store.dispatch(removeChats(processo.id));
      store.dispatch(removeWebsocket(processo.id, processo.tipo_mensageiro));
      store.dispatch(
        requestDeleteProcessos(
          processo.host,
          processo.id,
          processo.id_empresa,
          processo.pm2_app,
          processo.tipo_mensageiro,
          null
        )
      );
    }
  };

  const onAllChats = (store, socket) => (data) => {
    console.log("onAllChats =>", data);
    console.log("store", store);

    const state = store.getState();
  };

  const onUpdateChats = (store, socket) => (data) => {
    console.log("onUpdateChats =>", data);

    const state = store.getState();
    const user = state.Auth.user;

    if (Array.isArray(data)) {
      const userConversations = data.filter((chat) => checkFilterActives(chat));

      const bot = false;
      (userConversations || []).map((chat) => {
        if (
          chat.idResponsavel === user.id_usuario &&
          checkUserRestrictions("MY_CHATS", chat)
        ) {
          console.log(
            state.Chat.my_chats.filter((v) => v._id == chat._id).length
          );
          if (state.Chat.my_chats.filter((v) => v._id == chat._id).length > 0) {
            store.dispatch(
              setConversationUpdateMyChatInDatabase({
                updatedChat: chat,
                idMongo: chat._id,
              })
            );
          } else {
            store.dispatch(setNewChat("my_chats", chat, bot));
          }
        }

        if (
          chat.idResponsavel === null &&
          checkUserRestrictions("WAITING_ROOM", chat)
        ) {
          if (
            state.Chat.waiting_room.filter((v) => v._id == chat._id).length > 0
          ) {
            store.dispatch(
              setConversationUpdateWaitingRoomInDatabase({
                updatedChat: chat,
                idMongo: chat._id,
              })
            );
          } else {
            store.dispatch(setNewChat("waiting_room", chat, bot));
          }
        }

        if (checkUserRestrictions("USERS", chat)) {
          console.log(state.Chat.users.filter((v) => v._id == chat._id).length);
          if (state.Chat.users.filter((v) => v._id == chat._id).length > 0) {
            store.dispatch(
              setConversationUpdateInDatabase({
                updatedChat: chat,
                idMongo: chat._id,
              })
            );
          } else {
            store.dispatch(setNewChat("users", chat, bot));
          }
        }
      });
    }
  };

  const onNewChat = (store, socket) => (data) => {
    console.log("onNewChat =>", data);
    newChatEvent(store, data);
  };

  const onNewMessage = (store, socket) => (data) => {
    console.log("onNewMessage =>", data);
    newMessageEvent(store, data);
  };

  const onRemoveChat = (store, socket) => (data) => {
    const idMongo = data.idMongo;
    const tabNames = data.tabNames;

    const state = store.getState();
    const activeChatsTab = state.Layout.activeChatsTab;
    console.log("onRemoveChat =>", { idMongo, activeChatsTab });

    tabNames.forEach((tabName) => {
      if (tabName === "allChatsTab") {
        store.dispatch(removeChat(idMongo, tabName, false, activeChatsTab));
      }
      if (tabName === "myConversationsTab") {
        store.dispatch(removeMyChat(idMongo, tabName, false, activeChatsTab));
      }
      if (tabName === "waitingRoomTab") {
        store.dispatch(
          removeWaitingRoomChat(idMongo, tabName, false, activeChatsTab)
        );
      }
    });

    store.dispatch(setLoadingRequest(false));
  };

  const onChatUpdateInDatabase = (store, socket) => (data) => {
    console.log("onChatUpdateInDatabase =>", data);

    store.dispatch(setChatUpdateInDatabase(data));
  };

  const onConversationUpdateInDatabase = (store, socket) => (data) => {
    store.dispatch(setOnConversationUpdateInDatabase(data));
  };

  const onRemoveDeletedMessege = (store, socket) => (data) => {
    console.log("onRemoveDeletedMessege =>", data);
    const idMongoChat = data.idMongoChat;
    const chatId = data.chatId;
    const messagesIdArray = data.messagesIdArray;
    store.dispatch(
      setRemoveDeletedMessege(idMongoChat, chatId, messagesIdArray)
    );
    store.dispatch(closeModalDeleteMessege());
    store.dispatch(setLoadingRequest(false));
  };

  const onFailureEvent = (store, socket) => (data) => {
    console.log("onFailureEvent =>", data);

    const state = store.getState();

    const tabChatNames = Object.entries(state.Layout.filterTabChat).map(
      (tab) => tab[0]
    );

    tabChatNames.forEach((tabName) => {
      const { indexChat, stateName } = checkAckTabRestriction(tabName, data);

      console.log("onFailureEvent {indexChat, stateName}", {
        indexChat,
        stateName,
      });

      if (indexChat !== undefined && indexChat !== -1) {
        store.dispatch(setFailureEvent(stateName, indexChat, data));
      }
    });
  };

  const onMarkUnseenMessage = (store, socket) => (data) => {
    console.log("onMarkUnseenMessage =>", data);
    const idMongoChat = data.idMongoChat;
    const chatId = data.chatId;
    // store.dispatch(
    //   setRemoveDeletedMessege(idMongoChat, chatId, messagesIdArray),
    // )
    // store.dispatch(closeModalDeleteMessege())
    // store.dispatch(setLoadingRequest(false))
  };

  const onNewChatMessages = (store, socket) => (data) => {
    console.log("onNewChatMessages ->", data);

    const state = store.getState();

    const { activeChatsTab } = state.Layout;

    const chatActive = state.Chat[activeChatsTab].find(
      (user) => user._id === data.idMongo
    );

    console.log("chatActive", chatActive);

    if (data.data?.messages) {
      const messages = data.data.messages;

      const time_ultima_msg = messages[messages.length - 1]?.time;
      const ultima_msg = messages[messages.length - 1]?.message;

      console.log({ time_ultima_msg, ultima_msg });

      if (chatActive) {
        if (chatActive.time_ultima_msg !== time_ultima_msg) {
          store.dispatch(
            updateChatInDb(data.idMongo, {
              ultima_msg,
              time_ultima_msg,
            })
          );
        }
      }

      store.dispatch(setNewChatMessages(data));
      store.dispatch(scrollToBottom(true));
      store.dispatch(setLoadingRequest(false));
    }
  };

  const onEarlierMessages = (store, socket) => (data) => {
    console.log("onEarlierMessages ->", data);

    store.dispatch(setEarlierMessages(data));
    store.dispatch(setLoadingRequest(false));
  };

  const onAllMessagesInWpp = (store, socket) => (data) => {
    console.log("onAllMessagesInWpp ->", data);

    store.dispatch(setNewChatMessages(data));
    store.dispatch(setLoadingRequest(false));
    store.dispatch(closeModalLoadAllMessages());
  };

  const onQrCode = (store, socket) => (data) => {
    console.log("onQrCode =>", data);
    const { base64Qr, idIntegracaoDisconnect, idEmpresaDisconnect } = data;
    const state = store.getState();

    if (state.Auth.user.id_usuario_tipo !== 1) return;
    if (state.Layout.modalReconnect) {
      store.dispatch(openModalWhatsapp());
      store.dispatch(closeModalReconnect());
    } else {
      store.dispatch(openModalWhatsapp());
    }

    store.dispatch(addQrCode(base64Qr));
    store.dispatch(setDisconnect(idIntegracaoDisconnect, idEmpresaDisconnect));
  };

  const onQrCodeErro = (store, socket) => (data) => {
    console.log("onQrCodeErro =>", data);

    const state = store.getState();

    if (state.Auth.user.id_usuario_tipo !== 1) return;

    if (state.Layout.modalReconnect) {
      store.dispatch(openModalWhatsapp());
      store.dispatch(closeModalReconnect());
    }

    const tipo_mensageiro = data.tipo_mensageiro;
    const processos = state.WebService.processos;

    const processo = processos[tipo_mensageiro].find(
      (processo) => processo.id === parseInt(data.id_integracao)
    );
    store.dispatch(setProcessToReconnect(processo));

    store.dispatch(qrCodeError());
  };

  const onError = (store, socket) => (data) => {
    console.log("onError =>", data);
  };

  const onClose = (store, socket) => () => {
    const state = store.getState();
    const wsIndex = state.websockets.findIndex(
      (socket) => socket.options.query.id_integracao === parseInt(id_integracao)
    );

    store.dispatch(
      setAlertMessage(
        "Conexão com o servidor perdida. Por favor, recarregue a página para atualizar.",
        "server"
      )
    );

    console.log("Websocket ID %s server is closed", wsIndex);
  };

  return (store) => (next) => (action) => {
    switch (action.type) {
      case WS_CONNECTED:
        channel_name = action.payload.channel_name;
        id_integracao = action.payload.id_integracao;

        console.log("channel_name", action.payload);

        if (channel_name === "whatsapp") {
          whatsapp = action.payload.websocket;

          whatsapp.on("alertMessage", onAlertMessage(store, whatsapp));
          whatsapp.on("message", onMessage(store, whatsapp));
          whatsapp.on("ready", onReady(store, whatsapp));
          whatsapp.on(
            "conflitNewDeviceConnected",
            onConflitNewDeviceConnected(store, whatsapp)
          );
          whatsapp.on(
            "readyIntegration",
            onReadyIntegrationWs(store, whatsapp)
          );
          whatsapp.on("loadingChatInDb", onLoadingChatInDb(store, whatsapp));
          whatsapp.on("statusWs", onStatusWs(store, whatsapp));
          whatsapp.on("wppConnected", onWppConnected(store, whatsapp));
          whatsapp.on("wsReconnected", onWsReconnected(store, whatsapp));
          whatsapp.on("ack", onAck(store, whatsapp));
          whatsapp.on("stateChange", onStateChange(store, whatsapp));
          whatsapp.on(
            "deviceNotConnected",
            onDeviceNotConnected(store, whatsapp)
          );
          whatsapp.on("allChats", onAllChats(store, whatsapp));
          whatsapp.on("updateChats", onUpdateChats(store, whatsapp));
          whatsapp.on("newChat", onNewChat(store, whatsapp));
          whatsapp.on("newMessage", onNewMessage(store, whatsapp));
          whatsapp.on("newChatMessages", onNewChatMessages(store, whatsapp));
          whatsapp.on("earlierMessages", onEarlierMessages(store, whatsapp));
          whatsapp.on("allMessagesInWpp", onAllMessagesInWpp(store, whatsapp));
          whatsapp.on("removeChat", onRemoveChat(store, whatsapp));
          whatsapp.on(
            "chatUpdateInDatabase",
            onChatUpdateInDatabase(store, whatsapp)
          );
          whatsapp.on(
            "conversationUpdateInDatabase",
            onConversationUpdateInDatabase(store, whatsapp)
          );
          whatsapp.on(
            "messegeDeleted",
            onRemoveDeletedMessege(store, whatsapp)
          );
          whatsapp.on(
            "markUnseenMessage",
            onMarkUnseenMessage(store, whatsapp)
          );
          whatsapp.on("qrCode", onQrCode(store, whatsapp));
          whatsapp.on("qrCodeErro", onQrCodeErro(store, whatsapp));
          whatsapp.on("error", onError(store, whatsapp));
          whatsapp.on("close", onClose(store, whatsapp));
        } else if (channel_name === "messenger") {
          messenger = action.payload.websocket;

          messenger.on("alertMessage", onAlertMessage(store, messenger));
          messenger.on("message", onMessage(store, messenger));
          messenger.on("ready", onReady(store, messenger));
          messenger.on(
            "readyIntegration",
            onReadyIntegrationWs(store, messenger)
          );
          messenger.on("loadingChatInDb", onLoadingChatInDb(store, messenger));
          messenger.on("statusWs", onStatusWs(store, messenger));
          messenger.on("wppConnected", onWppConnected(store, messenger));
          messenger.on("wsReconnected", onWsReconnected(store, messenger));
          messenger.on("ack", onAck(store, messenger));
          messenger.on("allChats", onAllChats(store, messenger));
          messenger.on("updateChats", onUpdateChats(store, messenger));
          messenger.on("newChat", onNewChat(store, messenger));
          messenger.on("newMessage", onNewMessage(store, messenger));
          messenger.on("newChatMessages", onNewChatMessages(store, messenger));
          messenger.on("earlierMessages", onEarlierMessages(store, messenger));
          messenger.on("removeChat", onRemoveChat(store, messenger));
          messenger.on(
            "chatUpdateInDatabase",
            onChatUpdateInDatabase(store, messenger)
          );
          messenger.on(
            "conversationUpdateInDatabase",
            onConversationUpdateInDatabase(store, messenger)
          );
          messenger.on(
            "messegeDeleted",
            onRemoveDeletedMessege(store, messenger)
          );
          messenger.on("error", onError(store, messenger));
          messenger.on("close", onClose(store, messenger));
        } else if (channel_name === "wzap_api_oficial") {
          whatsapp_api = action.payload.websocket;

          whatsapp_api.on("alertMessage", onAlertMessage(store, whatsapp_api));
          whatsapp_api.on("message", onMessage(store, whatsapp_api));
          whatsapp_api.on("ready", onReady(store, whatsapp_api));
          whatsapp_api.on(
            "readyIntegration",
            onReadyIntegrationWs(store, whatsapp_api)
          );
          whatsapp_api.on(
            "loadingChatInDb",
            onLoadingChatInDb(store, whatsapp_api)
          );
          whatsapp_api.on("statusWs", onStatusWs(store, whatsapp_api));
          whatsapp_api.on("wppConnected", onWppConnected(store, whatsapp_api));
          whatsapp_api.on(
            "wsReconnected",
            onWsReconnected(store, whatsapp_api)
          );
          whatsapp_api.on("ack", onAck(store, whatsapp_api));
          whatsapp_api.on(
            "chatUpdateInDatabase",
            onChatUpdateInDatabase(store, whatsapp_api)
          );
          whatsapp_api.on(
            "deviceNotConnected",
            onDeviceNotConnected(store, whatsapp_api)
          );
          whatsapp_api.on("allChats", onAllChats(store, whatsapp_api));
          whatsapp_api.on("updateChats", onUpdateChats(store, whatsapp_api));
          whatsapp_api.on("newChat", onNewChat(store, whatsapp_api));
          whatsapp_api.on("newMessage", onNewMessage(store, whatsapp_api));
          whatsapp_api.on(
            "newChatMessages",
            onNewChatMessages(store, whatsapp_api)
          );
          whatsapp_api.on(
            "earlierMessages",
            onEarlierMessages(store, whatsapp_api)
          );
          whatsapp_api.on(
            "allMessagesInWpp",
            onAllMessagesInWpp(store, whatsapp_api)
          );
          whatsapp_api.on("removeChat", onRemoveChat(store, whatsapp_api));
          whatsapp_api.on(
            "conversationUpdateInDatabase",
            onConversationUpdateInDatabase(store, whatsapp_api)
          );
          whatsapp_api.on(
            "messegeDeleted",
            onRemoveDeletedMessege(store, whatsapp_api)
          );
          whatsapp_api.on("failureEvent", onFailureEvent(store, whatsapp_api));
          whatsapp_api.on("qrCode", onQrCode(store, whatsapp_api));
          whatsapp_api.on("qrCodeErro", onQrCodeErro(store, whatsapp_api));
          whatsapp_api.on("error", onError(store, whatsapp_api));
          whatsapp_api.on("close", onClose(store, whatsapp_api));
        } else if (channel_name === "instagram") {
          instagram = action.payload.websocket;

          instagram.on("alertMessage", onAlertMessage(store, instagram));
          instagram.on("message", onMessage(store, instagram));
          instagram.on("ready", onReady(store, instagram));
          instagram.on(
            "readyIntegration",
            onReadyIntegrationWs(store, instagram)
          );
          instagram.on("loadingChatInDb", onLoadingChatInDb(store, instagram));
          instagram.on("statusWs", onStatusWs(store, instagram));
          instagram.on("wppConnected", onWppConnected(store, instagram));
          instagram.on("wsReconnected", onWsReconnected(store, instagram));
          instagram.on("ack", onAck(store, instagram));
          instagram.on("allChats", onAllChats(store, instagram));
          instagram.on("updateChats", onUpdateChats(store, instagram));
          instagram.on("newChat", onNewChat(store, instagram));
          instagram.on("newMessage", onNewMessage(store, instagram));
          instagram.on("newChatMessages", onNewChatMessages(store, instagram));
          instagram.on("earlierMessages", onEarlierMessages(store, instagram));
          instagram.on("removeChat", onRemoveChat(store, instagram));
          instagram.on(
            "chatUpdateInDatabase",
            onChatUpdateInDatabase(store, instagram)
          );
          instagram.on(
            "conversationUpdateInDatabase",
            onConversationUpdateInDatabase(store, instagram)
          );
          instagram.on(
            "messegeDeleted",
            onRemoveDeletedMessege(store, instagram)
          );
          instagram.on("error", onError(store, instagram));
          instagram.on("close", onClose(store, instagram));
        } else if (channel_name === "widget") {
          widget = action.payload.websocket;

          widget.on("alertMessage", onAlertMessage(store, widget));
          widget.on("message", onMessage(store, widget));
          widget.on("ready", onReady(store, widget));
          widget.on("readyIntegration", onReadyIntegrationWs(store, widget));
          widget.on("loadingChatInDb", onLoadingChatInDb(store, widget));
          widget.on("statusWs", onStatusWs(store, widget));
          widget.on("wppConnected", onWppConnected(store, widget));
          widget.on("wsReconnected", onWsReconnected(store, widget));
          widget.on("ack", onAck(store, widget));
          widget.on("allChats", onAllChats(store, widget));
          widget.on("updateChats", onUpdateChats(store, widget));
          widget.on("newChat", onNewChat(store, widget));
          widget.on("newMessage", onNewMessage(store, widget));
          widget.on("newChatMessages", onNewChatMessages(store, widget));
          widget.on("earlierMessages", onEarlierMessages(store, widget));
          widget.on("removeChat", onRemoveChat(store, widget));
          widget.on(
            "chatUpdateInDatabase",
            onChatUpdateInDatabase(store, widget)
          );
          widget.on(
            "conversationUpdateInDatabase",
            onConversationUpdateInDatabase(store, widget)
          );
          widget.on("messegeDeleted", onRemoveDeletedMessege(store, widget));
          widget.on("error", onError(store, widget));
          widget.on("close", onClose(store, widget));
        }

        break;
      default:
        console.log("the next action:", action);
        return next(action);
    }
  };
};

export default webSocketMiddleware();
