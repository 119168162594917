export const WS_CONNECTED = 'WS_CONNECTED'

export const ADD_SERVER = 'ADD_SERVER'
export const ADD_WEBSOCKET = 'ADD_WEBSOCKET'
export const ADD_CHANNEL = 'ADD_CHANNEL'
export const ADD_CONNECTION_STATUS = 'ADD_CONNECTION_STATUS'

export const SET_CHANNEL_INDEX = 'SET_CHANNEL_INDEX'

export const PROCESS_TO_RECONNECT = 'PROCESS_TO_RECONNECT'
export const REQUEST_PROCESSOS = 'REQUEST_PROCESSOS'
export const REQUEST_PROCESSOS_SUCESS = 'REQUEST_PROCESSOS_SUCESS'

export const REQUEST_START_PROCESSOS = 'REQUEST_START_PROCESSOS'
export const REQUEST_START_PROCESSOS_SUCESS = 'REQUEST_START_PROCESSOS_SUCESS'
export const REQUEST_START_PROCESSOS_ERROR = 'REQUEST_START_PROCESSOS_ERROR'

export const REQUEST_DELETE_PROCESSOS = 'REQUEST_DELETE_PROCESSOS'
export const REQUEST_DELETE_PROCESSOS_SUCESS = 'REQUEST_DELETE_PROCESSOS_SUCESS'
export const REQUEST_DELETE_PROCESSOS_ERROR = 'REQUEST_DELETE_PROCESSOS_ERROR'

export const REQUEST_SYNC_CONVERSATIONS_WHATS = 'REQUEST_SYNC_CONVERSATIONS_WHATS'

export const SET_PROCESSOS = 'SET_PROCESSOS'

export const ENABLE_MESSENGER_REQUEST = 'ENABLE_MESSENGER_REQUEST'
export const DISABLE_MESSENGER_REQUEST = 'DISABLE_MESSENGER_REQUEST'

export const AUTH_INSTAGRAM = 'AUTH_INSTAGRAM'
export const AUTH_MESSENGER = 'AUTH_MESSENGER'
export const DISABLE_INSTAGRAM_REQUEST = 'DISABLE_MESSENGER_REQUEST'

export const ENABLE_WIDGET_REQUEST = 'ENABLE_WIDGET_REQUEST'
export const DISABLE_WIDGET_REQUEST = 'DISABLE_WIDGET_REQUEST'

export const REMOVE_WEBSOCKET = 'REMOVE_WEBSOCKET'

export const SET_EMAIL_SOCKET = 'SET_EMAIL_SOCKET'

export const SET_CENTRAL_SOCKET = 'SET_CENTRAL_SOCKET'

export const ADD_MESSAGE_WARNING = 'ADD_MESSAGE_WARNING'

export const REMOVE_MESSAGE_WARNING = 'REMOVE_MESSAGE_WARNING'