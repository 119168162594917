import store from '../redux/store'
const parseFilterQuery = () => {
  const state = store.getState()

  const id_empresa = state.Auth.user.id_empresa
  const id_usuario = state.Auth.user.id_usuario
  const id_permissao = state.Auth.user.id_permissao;
  const exibir_conversas_nao_atribuidas_todos = state?.Config?.config?.exibir_conversas_nao_atribuidas_todos
  const servers = state.WebService.servers
  const teams = state.Config.teams

  const filter_id_integracao = []
  const filter_identifier = []
  const filter_tipo_mensageiro = []
  for (const serve of servers) {
    filter_id_integracao.push(serve.id)
    const hasMensageiro = filter_tipo_mensageiro.find(
      (mensageiro) => mensageiro === serve.tipo_mensageiro,
    )
    if (!hasMensageiro) {
      filter_tipo_mensageiro.push(serve.tipo_mensageiro)
    }

    if (serve.tipo_mensageiro === 'whatsapp') {
      console.log('whatsapp', serve)
      filter_identifier.push(serve.identifier)
    } else if (serve.tipo_mensageiro === 'wzap_api_oficial') {
      console.log('whatsawzap_api_oficialp', serve)
      filter_identifier.push(serve.identifier)
    } else if (serve.tipo_mensageiro === 'messenger') {
      console.log('messenger', serve)
      filter_identifier.push(serve.identifier)
    } else if (serve.tipo_mensageiro === 'widget') {
      console.log('widget', serve)
      filter_identifier.push(serve.identifier)
    } else if (serve.tipo_mensageiro === 'instagram') {
      console.log('instagram', serve)
      filter_identifier.push(serve.identifier)
    }
  }

  const filter_id_teams = []

  for (const team of teams) {
    team.users.forEach((user) => {
      if (user.id_usuario === id_usuario) {
        filter_id_teams.push(team.id_equipe)
      }
    })
  }

  return {
    id_empresa,
    id_usuario,
    teams: filter_id_teams,
    id_permissao,
    exibir_conversas_nao_atribuidas_todos,
    id_integracao: filter_id_integracao,
    tipo_mensageiro: filter_tipo_mensageiro,
    identifier: filter_identifier,
  }
}

export default parseFilterQuery
