import produce, { current } from "immer";
import getTimestamp from "../../utils/getTimestamp";
import {
  SET_ACTIVE_EMAIL_ID,
  SET_ALL_EMAILS,
  SET_CURRENT_CONTENT_EMAIL_HTML,
  SET_TYPE_ANSWER_EMAIL,
  SET_EMAIL_FORM,
  SET_NEW_EMAIL,
  SET_SENDING_EMAIL,
  SET_NEW_REPLY_EMAIL,
  SET_EMAIL_WEBSOCKET_START,
  FILTER_TAB_EMAIL,
  SET_EMAIL_CONFIG,
  SET_EMAIL_UPDATE_STATUS,
  SET_NEW_EMAILS,
  SET_EMAIL_UPDATE_LEAD,
  ACTIVE_EMAIL_INBOX,
  ACTIVE_OPTIONS_EMAIL_PROVIDER,
  SET_EMAIL_ACCESS,
  REMOVE_EMAIL_CONVERSATION,
} from "./constants";

export const INIT_FORM_EMAIL = {
  to: [],
  cc: [],
  cco: [],
  subject: "",
  reply_to: null,
  email_conversation_id: null,
  oportunity_id: null,
};

const INIT_STATE = {
  emailAccess: false,
  active_email_id: null,
  type_reply_email: "",
  emails: [],
  email_form: INIT_FORM_EMAIL,
  body: "",
  sending_email: false,
  emailSocketStart: false,
  activeEmailInbox: "",
  filterTabEmail: {
    inbox: true,
    exitbox: false,
    trash: false,
  },
  emailConfig: null,
  optionsEmailProvider: [
    {
      value: "default",
      label: "Selecione o provedor de e-mail",
      defaultSetting: null,
    },
  ],
};

const Email = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ALL_EMAILS:
      return produce(state, (draft) => {
        draft.emails = action.payload;
      });

    case SET_NEW_EMAIL:
      return produce(state, (draft) => {
        const emailConversation = action.payload.emailConversation;
        const createdMail = action.payload.createdMail;

        const emailConversationId = emailConversation.id;
        console.log("emailConversationId", emailConversationId);
        const emailConversationIndex = draft.emails.findIndex(
          (email) => email.id === emailConversationId
        );

        console.log("emailConversationIndex", emailConversationIndex);

        if (emailConversationIndex !== -1) {
          draft.emails[emailConversationIndex].emails.push(createdMail);

          let emailContent = current(draft.emails[emailConversationIndex]);

          console.log("emailContent", emailContent);

          draft.emails.splice(emailConversationIndex, 1);

          draft.emails.unshift(emailContent);

          console.log(
            "draft.emails[emailConversationIndex]",
            draft.emails[emailConversationIndex]
          );
        } else {
          draft.emails.push({
            ...emailConversation,
            emails: [createdMail],
          });
        }

        draft.emails.sort((email1, email2) => {
          if (
            getTimestamp(email1.updated_at) < getTimestamp(email2.updated_at)
          ) {
            return 1;
          }
          if (
            getTimestamp(email1.updated_at) > getTimestamp(email2.updated_at)
          ) {
            return -1;
          }
          return 0;
        });
      });

    case SET_NEW_EMAILS:
      return produce(state, (draft) => {
        const emails = action.payload;

        for (const emailConversation of emails) {
          const emailConversationId = emailConversation.id;
          console.log("emailConversationId", emailConversationId);
          const emailConversationIndex = draft.emails.findIndex(
            (email) => email.id === emailConversationId
          );

          console.log("emailConversationIndex", emailConversationIndex);

          if (emailConversationIndex !== -1) {
            for (const emailData of emailConversation.emails) {
              const emailIndex = draft.emails[
                emailConversationIndex
              ].emails.findIndex((item) => item.id === emailData.id);

              if (emailIndex !== -1) {
                draft.emails[emailConversationIndex].emails[emailIndex] =
                  emailData;
              } else {
                draft.emails[emailConversationIndex].emails.push(emailData);
              }
            }
          } else {
            draft.emails.push({
              id: emailConversation.id,
              subject: emailConversation.subject,
              from: emailConversation.from,
              to: emailConversation.to,
              main_id: emailConversation.main_id,
              oportunity_id: emailConversation.oportunity_id,
              company_id: emailConversation.company_id,
              deleted_at: emailConversation.deleted_at,
              created_at: emailConversation.created_at,
              updated_at: emailConversation.updated_at,
              emails: emailConversation.emails,
            });
          }
        }

        draft.emails.sort((email1, email2) => {
          if (
            getTimestamp(email1.updated_at) < getTimestamp(email2.updated_at)
          ) {
            return 1;
          }
          if (
            getTimestamp(email1.updated_at) > getTimestamp(email2.updated_at)
          ) {
            return -1;
          }
          return 0;
        });
      });
    case SET_EMAIL_UPDATE_STATUS:
      return produce(state, (draft) => {
        const emailData = action.payload;
        const emailConversationId = emailData.email_conversation_id;
        console.log("emailConversationId", emailConversationId);
        const emailConversationIndex = draft.emails.findIndex(
          (email) => email.id === emailConversationId
        );

        if (emailConversationIndex !== -1) {
          // const main_id = draft.emails[emailConversationIndex]?.main_id

          // if (!main_id) {
          //   draft.emails[emailConversationIndex].main_id = emailData.main_id
          //   draft.emails[emailConversationIndex].from = emailData.from
          // }

          const emailIndex = draft.emails[
            emailConversationIndex
          ].emails.findIndex((item) => item.id === emailData.id);

          if (emailIndex !== -1) {
            draft.emails[emailConversationIndex].emails[emailIndex] = emailData;
          } else {
            draft.emails[emailConversationIndex].emails.push(emailData);
          }
        }
      });
    case SET_EMAIL_UPDATE_LEAD:
      return produce(state, (draft) => {
        const emailData = action.payload;
        const emailConversationId = emailData.id;

        const emailConversationIndex = draft.emails.findIndex(
          (email) => email.id === emailConversationId
        );

        if (emailConversationIndex !== -1) {
          draft.emails[emailConversationIndex].client_id = emailData.client_id;
          draft.emails[emailConversationIndex].opportunity_id =
            emailData.opportunity_id;
        } else {
          draft.emails.push(emailData);
        }
      });

    case SET_NEW_REPLY_EMAIL:
      return produce(state, (draft) => {
        const newEmail = action.payload;

        const emailIndex = draft.emails.findIndex(
          (email) => email.id === newEmail.reply_to
        );

        console.log("emailIndex", emailIndex);

        if (emailIndex !== -1) {
          console.log(
            "draft.emails[emailIndex].emails",
            draft.emails[emailIndex].emails
          );
          draft.emails[emailIndex].emails.push(newEmail);

          let emailContent = current(draft.emails[emailIndex]);

          draft.emails.splice(emailIndex, 1);

          draft.emails.unshift(emailContent);
        }
      });
    case SET_EMAIL_CONFIG:
      return produce(state, (draft) => {
        draft.emailConfig = action.payload;
      });
    case SET_SENDING_EMAIL:
      return produce(state, (draft) => {
        draft.sending_email = action.payload;
      });
    case SET_ACTIVE_EMAIL_ID:
      return produce(state, (draft) => {
        draft.active_email_id = action.payload;
      });
    case SET_TYPE_ANSWER_EMAIL:
      return produce(state, (draft) => {
        draft.type_reply_email = action.payload;
      });
    case SET_CURRENT_CONTENT_EMAIL_HTML:
      return produce(state, (draft) => {
        draft.body = action.payload;
      });
    case SET_EMAIL_FORM:
      return produce(state, (draft) => {
        draft.email_form.to = action.payload.to;
        draft.email_form.cc = action.payload.cc;
        draft.email_form.cco = action.payload.cco;
        draft.email_form.subject = action.payload.subject;
        draft.email_form.reply_to = action.payload.reply_to;
        draft.email_form.email_conversation_id =
          action.payload.email_conversation_id;
        draft.email_form.oportunity_id = action.payload.oportunity_id;
      });
    case SET_EMAIL_WEBSOCKET_START:
      return produce(state, (draft) => {
        draft.emailSocketStart = action.payload;
      });
    case FILTER_TAB_EMAIL:
      return produce(state, (draft) => {
        switch (action.payload.name) {
          case "inboxTab":
            draft.filterTabEmail.inbox = true;
            draft.filterTabEmail.exitbox = false;
            draft.filterTabEmail.trash = false;
            break;
          case "exitboxTab":
            draft.filterTabEmail.inbox = false;
            draft.filterTabEmail.exitbox = true;
            draft.filterTabEmail.trash = false;
            break;

          case "trashTab":
            draft.filterTabEmail.inbox = false;
            draft.filterTabEmail.exitbox = false;
            draft.filterTabEmail.trash = true;
            break;
          default:
            break;
        }
      });

    case ACTIVE_EMAIL_INBOX:
      return produce(state, (draft) => {
        draft.activeEmailInbox = action.payload;
      });
    case ACTIVE_OPTIONS_EMAIL_PROVIDER:
      return produce(state, (draft) => {
        draft.optionsEmailProvider = action.payload;
      });
    case SET_EMAIL_ACCESS:
      return produce(state, (draft) => {
        draft.emailAccess = action.payload;
      });
    case REMOVE_EMAIL_CONVERSATION:
      return produce(state, (draft) => {
        const emails = draft.emails.filter(
          (email) => email.id !== action.payload.id
        );
        draft.emails = emails;
      });

    default:
      return state;
  }
};

export default Email;
