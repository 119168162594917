import produce from "immer";
import {
  NEW_TEMPLATE_MESSAGE_DATA,
  REQUEST_CONFIG_SUCESS,
  TEMPLATE_MESSAGES_SUCESS,
} from "./constants";

const INIT_STATE = {
  config: null,
  configSchedule: [],
  attendants: [],
  enterprise: null,
  standardMessages: [],
  templateMessages: [],
  newTemplateMessage: {},
  attachments: [],
  taskType: [],
  teams: [],
  tags: [],
  funnels: [],
  status: [],
  products: [],
  employees: [],
  dialogFlow: null,
  voip: {},
};

const Config = (state = INIT_STATE, action) => {
  switch (action.type) {
    case REQUEST_CONFIG_SUCESS:
      return {
        ...state,
        config: action.payload.config ?? null,
        configSchedule: action.payload.configSchedule ?? [],
        attendants: action.payload.attendants ?? [],
        standardMessages: action.payload.standardMessages ?? [],
        attachments: action.payload.attachments ?? [],
        enterprise: action.payload.enterprise ?? [],
        taskType: action.payload.taskType ?? [],
        teams: action.payload.teams ?? [],
        tags: action.payload.tags ?? [],
        status: action.payload.status ?? [],
        funnels: action.payload.funnels ?? [],
        products: action.payload.products ?? [],
        employees: action.payload.employees ?? [],
        dialogFlow: action.payload.dialogFlow ?? null,
        voip: action.payload.voip ?? [],
      };
    case TEMPLATE_MESSAGES_SUCESS:
      return produce(state, (draft) => {
        draft.templateMessages = action.payload;
      });
    case NEW_TEMPLATE_MESSAGE_DATA:
      return produce(state, (draft) => {
        draft.newTemplateMessage = action.payload;
      });

    default:
      return state;
  }
};

export default Config;
