import { removeChat } from '../redux/actions'
import store from '../redux/store'
import { checkFilterActives } from './checkUserRestrictions'

const checkMessageTabRestriction = (tabName, message) => {
  const state = store.getState()
  const user = state.Auth.user

  let stateName = 'users'

  if(tabName === 'myConversationsTab'){
    stateName = 'my_chats'
  }

  if(tabName === 'waitingRoomTab'){
    stateName = 'waiting_room'
  }

  
  console.log('stateName', stateName)

  let indexChat = undefined
  let chatId = ''

  let dataRes = {
    indexChat,
    stateName
  }
  if ((message.ack === 1 || 2) && message?.chatId?._serialized) {
    chatId = message.chatId._serialized
    indexChat = state.Chat[stateName].findIndex(
      (user) =>
        user.id === chatId &&
        user.idEmpresa === message.idEmpresa &&
        user.idIntegracao === message.idIntegracao &&
        user.identifier === message.identifier,
    )
  } else {
    chatId = message.chatId
    indexChat = state.Chat[stateName].findIndex(
      (user) =>
        user.id === chatId &&
        user.idEmpresa === message.idEmpresa &&
        user.idIntegracao === message.idIntegracao,
    )
  }

  console.log('indexChat', indexChat)


  if (indexChat !== -1) {
    const userChat = {
      ... state.Chat[stateName][indexChat],
      time_ultima_msg: message.time
    }

    if (stateName === 'users' && !checkFilterActives(userChat)) {
      store.dispatch(removeChat(userChat._id, 'allChatsTab', true))
      return {  
        indexChat: -1,
        stateName: stateName
      }
    }

    switch (tabName) {
      case 'allChatsTab':
        dataRes = {indexChat, stateName, idMongo: userChat._id}
        console.log('dataRes allChatsTab=>',dataRes)
      case 'myConversationsTab':
        if (userChat && userChat.idResponsavel === user.id_usuario) {
          dataRes = {indexChat, stateName, idMongo: userChat._id}
          console.log('dataRes myConversationsTab=>',dataRes)
        }
        break
      case 'waitingRoomTab':
        if (userChat && userChat.idResponsavel === null) {
          dataRes = {indexChat, stateName, idMongo: userChat._id}
          console.log('dataRes waitingRoomTab=>',dataRes)
        }
        break
      default:
        break
    }
  }

  return dataRes
}

export default checkMessageTabRestriction
