import Bugsnag from "@bugsnag/js";
import { all, put, takeEvery, call, fork } from "redux-saga/effects";
import { api } from "../../../services/api";
import getChannelIndex from "../../../utils/websocket/getChannelIndex";
import {
  setTemplateMessages,
  setTemplateType,
  setTemplateTypeMessage,
} from "../../actions";

import store from "../../store";
import { REQUEST_TEMPLATES_WHATSAPP, CHECK_USER_ACTIVE } from "./constants";

function* loadTemplatesWhatsapp({
  payload: { identifier, description, partner_integration },
}) {
  try {
    const { data } = yield call(
      api.get,
      `/central/template-messages?integration=${identifier}&template_type=${partner_integration}`
    );

    const templates = data;
    console.log("loadTemplatesWhatsapp =>", data);

    const templateTypeWzap = {
      label: `Templates Whatsapp API - ${description}`,
      value: `template_wzap_api_oficial_${identifier}`,
    };

    let templateMessageData = [];
    switch (partner_integration) {
      case "gupshup":
        templateMessageData = templates.map((item) => {
          return {
            value: item.mensagem_texto,
            label: item.titulo,
            template_id: item.id_mensagens_padrao,
            template_type: item.template_type,
            template_data: JSON.parse(item.template_data),
            integration: item.integration,
          };
        });
        break;
      case "360dialog":
        templateMessageData = templates.map((item) => {
          return {
            value: item.mensagem_texto,
            label: item.titulo,
            template_id: item.id_mensagens_padrao,
            template_type: item.template_type,
            template_data: JSON.parse(item.template_data),
            integration: item.integration,
          };
        });
        break;
      case "facebook_api_oficial":
        templateMessageData = templates.map((item) => {
          return {
            value: item.mensagem_texto,
            label: item.titulo,
            template_id: item.id_mensagens_padrao,
            template_type: item.template_type,
            template_data: JSON.parse(item.template_data),
            integration: item.integration,
          };
        });
        break;
      default:
        break;
    }

    yield put(
      setTemplateMessages(
        templateMessageData,
        `template_wzap_api_oficial_${identifier}`
      )
    );
    yield put(setTemplateType(templateTypeWzap));
  } catch (error) {
    Bugsnag.notify(error)
    console.log("Error loadConfigCentral: ", error);
  }
}

function* requestCheckUserActive() {
  const state = store.getState();
  const { servers } = state.WebService;
  const { activeChatsTab } = state.Layout;
  const { active_chat_mongo_id } = state.Chat;
  try {
    const chatActive = state.Chat[activeChatsTab].find(
      (chat) => chat._id === active_chat_mongo_id
    );

    const channelIndex = getChannelIndex(
      chatActive.identifier,
      chatActive.idIntegracao
    );
    const processo = servers[channelIndex];

    const { data } = yield call(
      api.get,
      `/gupshup/users/check?appName=${processo.app_name}&chatId=${chatActive.id}`
    );

    if (data.typeMessage === "template") {
      yield put(setTemplateTypeMessage(true));
    } else {
      yield put(setTemplateTypeMessage(false));
    }
  } catch (error) {
    Bugsnag.notify(error)
    console.log("Error loadConfigCentral: ", error);
  }
}

export function* watchTemplatesWhatsapp() {
  yield takeEvery(REQUEST_TEMPLATES_WHATSAPP, loadTemplatesWhatsapp);
}

export function* watchCheckUserActive() {
  yield takeEvery(CHECK_USER_ACTIVE, requestCheckUserActive);
}

function* wzapApiSaga() {
  yield all([fork(watchTemplatesWhatsapp), fork(watchCheckUserActive)]);
}

export default wzapApiSaga;
