import { connect } from 'react-redux'
import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function AlertMessage(props) {
  return <ToastContainer onClick={() => console.log('requestProcessos')} />
}

const mapStateToProps = (state) => {
  const { alertMessage } = state.Layout
  return {
    alertMessage,
  }
}

export default connect(mapStateToProps, {})(AlertMessage)
