export const softConversations = (chats) => {
  return chats.sort((chat1, chat2) => {
    const lastTimeChat1 = chat1.time_ultima_msg
    const lastTimeChat2 = chat2.time_ultima_msg

    const lastTimeMessageChat1 = chat1.messages[chat1.messages.length - 1]?.time
    const lastTimeMessageChat2 = chat2.messages[chat2.messages.length - 1]?.time

    if (lastTimeMessageChat1 && lastTimeMessageChat2) {
      if (lastTimeMessageChat1 < lastTimeMessageChat2) return 1
      if (lastTimeMessageChat1 > lastTimeMessageChat2) return -1
      return 0
    } else {
      if (lastTimeChat1 < lastTimeChat2) return 1
      if (lastTimeChat1 > lastTimeChat2) return -1
      return 0
    }
  })
}
