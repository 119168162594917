import { all, put, takeEvery, call, delay, fork } from "redux-saga/effects";
import Ws from "@adonisjs/websocket-client";
import { api } from "../../services/api";

import {
  AUTH_INSTAGRAM,
  AUTH_MESSENGER,
  DISABLE_INSTAGRAM_REQUEST,
  DISABLE_MESSENGER_REQUEST,
  ENABLE_MESSENGER_REQUEST,
  ENABLE_WIDGET_REQUEST,
  REQUEST_DELETE_PROCESSOS,
  REQUEST_PROCESSOS,
  REQUEST_START_PROCESSOS,
  REQUEST_SYNC_CONVERSATIONS_WHATS,
} from "./constants";

import {
  addServer,
  setFullUser,
  addWebsocket,
  activeUserId,
  activeChatIndex,
  loadingMessenger,
  activeRecipientId,
  setUserImgWhatsapp,
  closeModalWhatsapp,
  setServerConnected,
  addConnectionStatus,
  closeModalReconnect,
  requestProcessosSucess,
  requestStartProcessosError,
  requestDeleteProcessosSucess,
  requestDeleteProcessos,
  fitchChatsInDb,
  qrCodeLoading,
  setPagination,
  qrCodeError,
  addChannel,
  setStatusWs,
  wsConnect,
  removeWebsocket,
  fitchMyChatsInDb,
  fitchWaitingRoomChatsInDb,
  setWsFinished,
  setStateChange,
  removeChats,
  addMessageWarning,
  removeMessageWarning,
  clearChatIsActived,
  setProcessToReconnect,
} from "../actions";

import store from "../store";
import axios from "axios";
import {
  openModalWhatsapp,
  setAlertMessage,
  setLoadingChatInDb,
  setModalSyncConversations,
} from "../layout/actions";
import { getLoggedInAuth } from "../../helpers/authUtils";
import { requestTemplatesWhatasapp } from "../integrations/wzap_api_oficial/actions";
import Bugsnag from "@bugsnag/js";

const auth = getLoggedInAuth();
let loadChats = 0;

function* loadProcessos({ payload: { idEmpresa, reconnect, forceStartId } }) {
  console.log("Buscando processos para id empresa:", idEmpresa);
  yield put(setStatusWs("Carregando mensagens... aguarde"));

  try {
    const state = store.getState();

    const { user } = state.Auth;
    const { enterprise } = state.Config;

    const res = yield call(api.get, `/central/processos/${idEmpresa}`);
    let processos = res.data;

    yield put(requestProcessosSucess(processos));

    processos.whatsapp = processos.whatsapp ? processos.whatsapp : [];
    processos.wzap_api_oficial = processos.wzap_api_oficial
      ? processos.wzap_api_oficial
      : [];
    processos.messenger = processos.messenger ? processos.messenger : [];
    processos.instagram = processos.instagram ? processos.instagram : [];
    processos.widget = processos.widget ? processos.widget : [];

    if (processos) {
      if (enterprise.em_teste === 1) {
        if (processos.whatsapp.length === 0) {
          store.dispatch(openModalWhatsapp());
        } else if (processos.whatsapp[0]?.ativo === "N") {
          store.dispatch(setProcessToReconnect(processos.whatsapp[0]));
          store.dispatch(openModalWhatsapp());
        }
      }

      processos.whatsapp.map((processo, index) => {
        if (processo.id === forceStartId) {
          processos.whatsapp[index].ativo = "S";
        }

        return startWebSocket({
          ...processo,
          identifier: processo.phonenumber,
        });
      });

      processos.wzap_api_oficial.map((processo, index) => {
        store.dispatch(
          requestTemplatesWhatasapp({
            identifier: processo.identifier,
            description: processo.description,
            partner_integration: processo.partner_integration,
          })
        );
        if (processo.id === forceStartId) {
          processos.wzap_api_oficial[index].ativo = "S";
        }
        return startWebSocket(
          {
            ...processo,
            tipo_mensageiro: "wzap_api_oficial",
            host: "",
            app_name: processo.app_name,
            channel: processo.channel,
            toapi_keyken: processo.apikey,
          },
          true
        );
      });

      processos.messenger.map((processo, index) => {
        if (processo.id === forceStartId) {
          processos.messenger[index].ativo = "S";
        }
        return startWebSocket(
          {
            ...processo,
            identifier: processo.id_pagina,
            tipo_mensageiro: "messenger",
            host: "",
            token: processo.token,
          },
          true
        );
      });
      processos.instagram.map((processo, index) => {
        if (processo.id === forceStartId) {
          processos.instagram[index].ativo = "S";
        }
        return startWebSocket(
          {
            ...processo,
            tipo_mensageiro: "instagram",
            host: "",
          },
          true
        );
      });
      processos.widget.map((processo, index) => {
        const id_empresa = +processo.id_empresa;
        if (processo.id === forceStartId) {
          processos.widget[index].ativado = "S";
          processos.widget[index].ativo = "S";
        }
        return startWebSocket(
          {
            ...processo,
            id_empresa,
            tipo_mensageiro: "widget",
            host: "",
            forceStartId,
          },
          true
        );
      });
    }

    yield put(closeModalReconnect());
    yield put(setLoadingChatInDb(false));
  } catch (error) {
    Bugsnag.notify(error);
    console.log("error", error);
    console.log("Error downloadFile: ", error);
  }
}

function startWebSocket(processMeets, isGenerator = false) {
  const state = store.getState();
  const { user } = state.Auth;
  const { teams } = state.Config;

  let hasAcess = false;

  if (
    processMeets.ativo !== "S" ||
    state.WebService.websockets.find(
      (websocket) =>
        websocket.options.query.id_integracao === processMeets.id &&
        websocket.options.query.id_empresa === processMeets.id_empresa &&
        websocket.options.query.tipo_mensageiro === processMeets.tipo_mensageiro
    )
  ) {
    return false;
  }

  if (processMeets.id_equipe === 0) {
    hasAcess = true;
  } else {
    const hasTeam = teams.find(
      (team) => team.id_equipe === processMeets.id_equipe
    );

    if (hasTeam) {
      const hasAttendantTeam = hasTeam.users.find(
        (attendant) => attendant.id_usuario === user.id_usuario
      );

      if (hasAttendantTeam) {
        hasAcess = true;
      }
    }
  }

  if (processMeets.attendants && user.id_permissao !== 1) {
    const whatsappAttendasnt =
      processMeets.attendants === ("" || "0")
        ? []
        : JSON.parse(processMeets.attendants);

    const hasAttendant = whatsappAttendasnt.find(
      (attendant) => attendant.value === user.id_usuario
    );

    if (hasAttendant) {
      hasAcess = true;
    }
  }

  if (user.id_permissao === 1) {
    hasAcess = true;
  }

  if (!hasAcess) {
    return false;
  }

  let wsHost =
    process.env.NODE_ENV === "development"
      ? `ws://${processMeets.host}`
      : `wss://${processMeets.host}`;
  if (isGenerator) {
    wsHost = process.env.REACT_APP_WS_GERADOR;
  }

  const configs = {
    reconnect: true,
    reconnectionAttempts: +process.env.REACT_APP_LIMIT_ATTEMPTS ?? 60,
    query: {
      identifier: processMeets.identifier,
      id_integracao: processMeets.id,
      id_empresa: processMeets.id_empresa,
      id_usuario: state.Auth.user.id_usuario,
      tipo_mensageiro: processMeets.tipo_mensageiro,
      descricao:
        processMeets.descricao ??
        processMeets.nome_pagina ??
        processMeets.description,
    },
  };

  const ws = Ws(wsHost, configs).withJwtToken(auth.token).connect();

  ws.on("open", () => {
    let channel;
    if (processMeets.tipo_mensageiro === "whatsapp") {
      channel = ws.subscribe(processMeets.tipo_mensageiro);
    } else if (processMeets.tipo_mensageiro === "widget") {
      channel = ws.subscribe(
        `${processMeets.tipo_mensageiro}:${processMeets.id}`
      );
    } else {
      channel = ws.subscribe(
        `${processMeets.tipo_mensageiro}:${processMeets.identifier}`
      );
    }
    store.dispatch(addWebsocket(ws));
    store.dispatch(
      addServer({
        id: processMeets.id,
        id_empresa: processMeets.id_empresa,
        identifier: processMeets.identifier,
        tipo_mensageiro: processMeets.tipo_mensageiro,
        host: processMeets.host,
        app_name: processMeets.app_name ?? null,
        token: processMeets.token ?? null,
        channel: processMeets.channel ?? null,
        toapi_keyken: processMeets.toapi_keyken ?? null,
      })
    );
    store.dispatch(
      addChannel(
        processMeets.tipo_mensageiro,
        channel,
        processMeets.id,
        processMeets.id_empresa
      )
    );
    store.dispatch(
      wsConnect(processMeets.tipo_mensageiro, channel, processMeets.id)
    );
    store.dispatch(
      addConnectionStatus(processMeets.id, processMeets.tipo_mensageiro, false)
    );
    store.dispatch(
      removeMessageWarning(processMeets.id, processMeets.tipo_mensageiro)
    );
    if (loadChats) clearTimeout(loadChats);
    loadChats = setTimeout(() => {
      if (state.Layout.loadingQrCode) {
        return false;
      }
      store.dispatch(setWsFinished(true));
      store.dispatch(fitchMyChatsInDb(state.Auth.user.id_usuario));
      store.dispatch(fitchWaitingRoomChatsInDb());
      store.dispatch(fitchChatsInDb());
    }, 2000);
  });

  ws.on("close", () => {
    if (
      ws._reconnectionAttempts >= +process.env.REACT_APP_LIMIT_ATTEMPTS ??
      60
    ) {
      store.dispatch(removeChats(processMeets.id));
      store.dispatch(
        removeWebsocket(processMeets.id, processMeets.tipo_mensageiro)
      );
      store.dispatch(
        removeMessageWarning(processMeets.id, processMeets.tipo_mensageiro)
      );
    } else {
      store.dispatch(
        addMessageWarning(processMeets.id, processMeets.tipo_mensageiro)
      );
      store.dispatch(setStateChange("DISCONNECTED"));
    }
    store.dispatch(clearChatIsActived(processMeets.id));
  });

  return true;
}

function* startProcessos({
  payload: {
    tipo_mensageiro,
    id_integracao,
    id_empresa,
    id_usuario,
    id_pagina,
    id_bot_dialogflow,
    limite_importacao,
    token,
    reconnect,
  },
}) {
  console.log("Start processo", {
    tipo_mensageiro,
    id_integracao,
    id_empresa,
    id_usuario,
    id_pagina,
    id_bot_dialogflow,
    limite_importacao,
    token,
    reconnect,
  });

  const state = store.getState();

  yield put(setStatusWs("Iniciando PM2 ->", tipo_mensageiro));

  switch (tipo_mensageiro) {
    case "whatsapp":
      yield put(qrCodeLoading());
      break;
    default:
      break;
  }

  console.log("state.Config.dialogFlow,", state.Config.dialogFlow);

  try {
    let data = {
      id_integracao,
      id_empresa,
      id_usuario,
      tipo_mensageiro,
      limite_importacao,
      id_pagina,
      id_bot_dialogflow,
      token,
      reconnect,
    };

    const resServeDisponible = yield call(
      api.get,
      `/db/crm/server/disponible/${data.id_integracao}`
    );

    const serveDisponible = resServeDisponible.data;

    data.id_server = serveDisponible.id;

    console.log("serveDisponible", serveDisponible);

    if (reconnect === "true") {
      yield call(axios.post, `${serveDisponible.host}/central/reload`, data);
      return true;
    }

    const fileNameDialogFlow = data.dialog_flow
      ? data.dialog_flow.path_json
      : null;
    const app_name_pm2 = `${tipo_mensageiro}_${id_empresa}_${id_integracao}`;
    const query = `?id_integracao=${id_integracao}&tipo_mensageiro=${tipo_mensageiro}&app_name_pm2=${app_name_pm2}${
      fileNameDialogFlow ? `&file_name_dialog_flow=${fileNameDialogFlow}` : ""
    }`;

    yield call(
      axios.delete,
      `${serveDisponible.host}/central/processos/${id_empresa}${query}`
    );

    const resData = yield call(
      axios.post,
      `${serveDisponible.host}/central/start`,
      data
    );

    yield loadProcessos({
      payload: {
        idEmpresa: id_empresa,
        reconnect: false,
        forceStartId: id_integracao,
      },
    });
  } catch (error) {
    Bugsnag.notify(error);
    console.log("Error startProcessos messenger: ", error);
    yield put(requestStartProcessosError());
    yield put(qrCodeError());
  }
}

function* enableMessenger({ payload: processo }) {
  const processToSave = {
    id: processo.id,
    id_empresa: processo.id_empresa,
    identifier: processo.id_pagina,
    tipo_mensageiro: "messenger",
    host: "",
    token: processo.token,
  };

  const response = yield call(api.post, `/messenger/subscribed_apps`, {
    id_empresa: processo.id_empresa,
    id_integracao: processo.id,
    id_pagina: processo.id_pagina,
    id_bot_dialogflow: processo.id_bot_dialogflow,
    token: processo.token,
  });

  console.log("response =>", response);

  const wsHost = process.env.REACT_APP_WS_GERADOR;

  try {
    yield loadProcessos({
      payload: {
        idEmpresa: processo.id_empresa,
        reconnect: false,
        forceStartId: processo.id,
      },
    });
  } catch (error) {
    console.log(error);
    Bugsnag.notify(error);
  }
}

function* authInstagram() {
  try {
    const origin_host = window.location.origin;
    console.log("authInstagram origin_host =>", origin_host);
    const res = yield call(
      api.get,
      `/instagram/auth?origin_host=${origin_host}`
    );

    const { url_redirect } = res.data;

    console.log("url_redirect", url_redirect);
    return (window.location.href = url_redirect);
  } catch (error) {
    console.log(error);
    Bugsnag.notify(error);
  }
}

function* authMessenger() {
  try {
    const origin_host = window.location.origin;
    console.log("authMessenger origin_host =>", origin_host);
    const res = yield call(
      api.get,
      `/messenger/auth?origin_host=${origin_host}`
    );

    const { url_redirect } = res.data;

    console.log("url_redirect", url_redirect);
    return (window.location.href = url_redirect);
  } catch (error) {
    console.log(error);
    Bugsnag.notify(error);
  }
}

function* enableWidget({ payload: processo }) {
  const processToSave = {
    id: processo.id,
    id_empresa: processo.id_empresa,
    identifier: processo.identifier,
    tipo_mensageiro: "widget",
    host: "",
  };

  const response = yield call(api.post, `/widget/subscribed_apps`, {
    id_empresa: processo.id_empresa,
    id_integracao: processo.id,
    id_bot_dialogflow: processo.id_bot_dialogflow,
  });

  console.log("response =>", response);

  try {
    yield* loadProcessos({
      payload: {
        idEmpresa: processo.id_empresa,
        reconnect: false,
        forceStartId: processo.id,
      },
    });
    return (window.location.href = "/central-atendimento");
  } catch (error) {
    console.log(error);
    Bugsnag.notify(error);
  }
}

function* deleteProcessos({
  payload: {
    host,
    id_integracao,
    id_empresa,
    app_name_pm2,
    tipo_mensageiro,
    reload,
  },
}) {
  console.log("Delete processo", {
    host,
    id_integracao,
    id_empresa,
    app_name_pm2,
    tipo_mensageiro,
  });

  const hostGerador =
    process.env.NODE_ENV === "development"
      ? `http://${host.split(":")[0]}:3444`
      : `https://${host.split(":")[0]}`;

  const pm2host =
    process.env.NODE_ENV === "development"
      ? `http://${host}/whatsapp/logout`
      : `https://${host}/whatsapp/logout`;

  console.log("hostGerador", hostGerador);

  try {
    const query = `?id_integracao=${id_integracao}&tipo_mensageiro=${tipo_mensageiro}&app_name_pm2=${app_name_pm2}`;
    const url = `${hostGerador}/central/processos/${id_empresa}${query}`;

    console.log("url", url);

    try {
      const resWpp = yield call(axios.delete, pm2host);
      console.log("resWpp =>", resWpp.data);
    } catch (error) {
      Bugsnag.notify(error);
      console.log(error);
    }

    const res = yield call(axios.delete, url);

    const processo = res.data;

    console.log("processo apagado", processo);

    const response = yield call(api.get, `/central/processos/${id_empresa}`);
    const processos = response.data;
    yield put(requestProcessosSucess(processos));
    yield put(requestDeleteProcessosSucess(id_integracao, tipo_mensageiro));
  } catch (error) {
    console.log("Error deleteProcessos: ", error);
    Bugsnag.notify(error);
    yield put(requestStartProcessosError());
    yield put(qrCodeError());
  }
}

function* requestSyncConversationsWhats({ payload: processo }) {
  console.log("Sicronisando conversas", processo);
  yield put(setModalSyncConversations(true));

  const { host, id, phonenumber, id_empresa } = processo;

  const query = `?id_integracao=${id}&id_empresa=${id_empresa}&identifier=${phonenumber}`;

  const pm2host =
    process.env.NODE_ENV === "development"
      ? `http://${host}/whatsapp/sync${query}`
      : `https://${host}/whatsapp/sync${query}`;

  try {
    try {
      const resWpp = yield call(axios.get, pm2host);
      const result = resWpp.data;
      console.log("result =>", result);
      yield put(setModalSyncConversations(false));
      store.dispatch(
        setAlertMessage(
          `Conversas sicronizadas com sucesso, para a linha ${processo.descricao}. Atualizando pagina.`,
          "info"
        )
      );
      yield delay(3000);

      return (window.location.href = "/central-atendimento");
    } catch (error) {
      console.log(error);
    }
  } catch (error) {
    Bugsnag.notify(error);
    yield put(setModalSyncConversations(false));
    store.dispatch(
      setAlertMessage(
        `Falha ao sicronizando conversas para a linha ${processo.descricao}.`,
        "error"
      )
    );
    console.log("Error requestSyncConversationsWhats: ", error);
  }
}

function* requestDisableInstagram({ payload: processo }) {
  console.log("Delete processo instagram", processo);
  const { id_empresa, id, id_pagina, token } = processo;
  try {
    const query_params = `id_empresa=${id_empresa}&id_integracao=${id}&id_pagina=${id_pagina}&token=${token}`;
    const response = yield call(
      api.delete,
      `/instagram/subscribed_apps?${query_params}`
    );

    yield delay(500);

    return (window.location.href = "/central-atendimento");
  } catch (error) {
    console.log("Error deleteProcessos: ", error);
    Bugsnag.notify(error);
    yield put(requestStartProcessosError());
    yield put(qrCodeError());
  }
}

function* requestDisableMessenger({ payload: processo }) {
  console.log("Delete processo messenger", processo);
  const { id_empresa, id, id_pagina, token } = processo;
  try {
    const query_params = `id_empresa=${id_empresa}&id_integracao=${id}&id_pagina=${id_pagina}&token=${token}`;
    const response = yield call(
      api.delete,
      `/messenger/subscribed_apps?${query_params}`
    );

    yield delay(500);

    return (window.location.href = "/central-atendimento");
  } catch (error) {
    console.log("Error deleteProcessos: ", error);
    Bugsnag.notify(error);
    yield put(requestStartProcessosError());
    yield put(qrCodeError());
  }
}

export function* watchLoadProcessos() {
  yield takeEvery(REQUEST_PROCESSOS, loadProcessos);
}

export function* watchStartProcesso() {
  yield takeEvery(REQUEST_START_PROCESSOS, startProcessos);
}

export function* watchDeleteProcesso() {
  yield takeEvery(REQUEST_DELETE_PROCESSOS, deleteProcessos);
}

export function* watchRequestSyncConversationsWhats() {
  yield takeEvery(
    REQUEST_SYNC_CONVERSATIONS_WHATS,
    requestSyncConversationsWhats
  );
}

export function* watchEnableMessenger() {
  yield takeEvery(ENABLE_MESSENGER_REQUEST, enableMessenger);
}

export function* watchAuthInstagram() {
  yield takeEvery(AUTH_INSTAGRAM, authInstagram);
}

export function* watchAuthMessenge() {
  yield takeEvery(AUTH_MESSENGER, authMessenger);
}

export function* watchDisableInstagram() {
  yield takeEvery(DISABLE_INSTAGRAM_REQUEST, requestDisableInstagram);
}

export function* watchDisableMessenger() {
  yield takeEvery(DISABLE_MESSENGER_REQUEST, requestDisableMessenger);
}

export function* watchEnableWidgt() {
  yield takeEvery(ENABLE_WIDGET_REQUEST, enableWidget);
}

function* wsSaga() {
  yield all([
    fork(watchLoadProcessos),
    fork(watchStartProcesso),
    fork(watchDeleteProcesso),
    fork(watchRequestSyncConversationsWhats),
    fork(watchDisableInstagram),
    fork(watchDisableMessenger),
    fork(watchEnableMessenger),
    fork(watchAuthInstagram),
    fork(watchAuthMessenge),
    fork(watchEnableWidgt),
  ]);
}

export default wsSaga;
