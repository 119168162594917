import Bugsnag from '@bugsnag/js'
import {
  activeChatIndex,
  activeRecipientId,
  activeUserId,
  requestChatMessages,
  requestCheckLead,
  setActiveChatMongoId,
  setActiveTab,
  setChannelIndex,
  setChatInit,
  setFilterTabChat,
  setHas24hMessage,
  setHeightChatConversationPx,
  setMessengerType,
  setReplyMessage,
  setTemplateTypeMessage,
  sortConversationsByTabName,
} from '../redux/actions'
import store from '../redux/store'
import { api } from '../services/api'

const openChat = (newChat, filterTabName) => {
  console.log('newChat', newChat)

  const state = store.getState()
  const { my_chats, waiting_room } = state.Chat

  let chatIndex = -1

  if (filterTabName === 'myConversationsTab') {
    chatIndex = my_chats.findIndex((chat) => chat._id === newChat._id)
  } else {
    chatIndex = waiting_room.findIndex((chat) => chat._id === newChat._id)
  }

  if (chatIndex !== -1) {
    store.dispatch(activeChatIndex(chatIndex))
  } else {
    store.dispatch(activeChatIndex(0))
  }
  store.dispatch(setActiveChatMongoId(newChat._id))
  store.dispatch(activeUserId(newChat.id))
  store.dispatch(activeRecipientId(newChat.id))
  store.dispatch(setChannelIndex(newChat.idIntegracao, newChat.tipoMensageiro))
  store.dispatch(setFilterTabChat(filterTabName, true))
  store.dispatch(sortConversationsByTabName(filterTabName))
  
 
  store.dispatch(setReplyMessage(null))
  store.dispatch(setMessengerType(newChat.tipoMensageiro))
  
  if (
    ["messenger", "instagram", "wzap_api_oficial"].includes(newChat.tipoMensageiro)
  ) {
    try {
      api.get(`/db/mongo/conversa/${newChat.id}/validate24hous`, {
        params: {
          integration: newChat.identifier
        }
      }).then((response) => {
        setLayout24h(store, response.data);
      })
    } catch (error) {
      Bugsnag.notify(error)
      setLayout24h(store, false);
    }
  } else {
    setLayout24h(store, false);
  }

  store.dispatch(setChatInit(false))
  

  store.dispatch(
    requestChatMessages(
      newChat.id,
      newChat._id,
      newChat.identifier,
      newChat.idIntegracao,
      newChat.idEmpresa
    ),
  )
  store.dispatch(setActiveTab('chat'))
  store.dispatch(requestCheckLead(newChat))
}

const setLayout24h = (store, is24h = false) => {
  store.dispatch(setHas24hMessage(is24h));
  store.dispatch(setHeightChatConversationPx(is24h ? 30 : 0));
  store.dispatch(setTemplateTypeMessage(is24h));
}

export default openChat
