import {
  ADD_WEBSOCKET,
  ADD_CHANNEL,
  SET_CHANNEL_INDEX,
  REQUEST_PROCESSOS_SUCESS,
  REQUEST_PROCESSOS,
  PROCESS_TO_RECONNECT,
  REQUEST_START_PROCESSOS,
  REQUEST_START_PROCESSOS_SUCESS,
  REQUEST_START_PROCESSOS_ERROR,
  REQUEST_DELETE_PROCESSOS,
  REQUEST_DELETE_PROCESSOS_SUCESS,
  REQUEST_DELETE_PROCESSOS_ERROR,
  ENABLE_MESSENGER_REQUEST,
  DISABLE_MESSENGER_REQUEST,
  REMOVE_WEBSOCKET,
  WS_CONNECTED,
  ADD_CONNECTION_STATUS,
  ADD_SERVER,
  ENABLE_WIDGET_REQUEST,
  DISABLE_WIDGET_REQUEST,
  AUTH_INSTAGRAM,
  DISABLE_INSTAGRAM_REQUEST,
  SET_PROCESSOS,
  SET_EMAIL_SOCKET,
  REQUEST_SYNC_CONVERSATIONS_WHATS,
  SET_CENTRAL_SOCKET,
  ADD_MESSAGE_WARNING,
  REMOVE_MESSAGE_WARNING,
  AUTH_MESSENGER
} from './constants'

export const wsConnect = (channel_name, websocket, id_integracao) => ({
  type: WS_CONNECTED,
  payload: {
    channel_name,
    websocket,
    id_integracao,
  },
})

export const addServer = (serverProcess) => ({
  type: ADD_SERVER,
  payload: serverProcess,
})

export const addWebsocket = (ws) => ({
  type: ADD_WEBSOCKET,
  payload: ws,
})

export const addChannel = (
  tipo_mensageiro,
  ws_channel,
  id_integracao,
  id_empresa,
) => ({
  type: ADD_CHANNEL,
  payload: {
    tipo_mensageiro,
    ws_channel,
    id_integracao,
    id_empresa,
  },
})

export const addConnectionStatus = (
  id_integracao,
  tipo_mensageiro,
  status,
) => ({
  type: ADD_CONNECTION_STATUS,
  payload: {
    id_integracao,
    tipo_mensageiro,
    status,
  },
})

export const setChannelIndex = (id_integracao, tipo_mensageiro) => ({
  type: SET_CHANNEL_INDEX,
  payload: {
    id_integracao,
    tipo_mensageiro,
  },
})

export const setProcessToReconnect = (processo) => ({
  type: PROCESS_TO_RECONNECT,
  payload: processo,
})

export const enableMessenger = (data) => ({
  type: ENABLE_MESSENGER_REQUEST,
  payload: data,
})

export const enableWidget = (data) => ({
  type: ENABLE_WIDGET_REQUEST,
  payload: data,
})

export const authInstagram = () => ({
  type: AUTH_INSTAGRAM,
})


export const authMessenger = () => ({
  type: AUTH_MESSENGER,
})

export const disableMessenger = (data) => ({
  type: DISABLE_MESSENGER_REQUEST,
  payload: data,
})

export const disableInstagram = (data) => ({
  type: DISABLE_INSTAGRAM_REQUEST,
  payload: data,
})

export const disableWidget = (data) => ({
  type: DISABLE_WIDGET_REQUEST,
  payload: data,
})

export const setProcessos = (processos) => ({
  type: SET_PROCESSOS,
  payload: { processos }
})

export const requestProcessos = (idEmpresa, reconnect) => ({
  type: REQUEST_PROCESSOS,
  payload: { idEmpresa, reconnect },
})

export const requestProcessosSucess = (processos) => ({
  type: REQUEST_PROCESSOS_SUCESS,
  payload: processos,
})

export const requestStartProcessos = (data) => ({
  type: REQUEST_START_PROCESSOS,
  payload: data,
})

export const requestStartProcessosSucess = () => ({
  type: REQUEST_START_PROCESSOS_SUCESS,
})

export const requestStartProcessosError = () => ({
  type: REQUEST_START_PROCESSOS_ERROR,
})

export const requestDeleteProcessos = (
  host,
  id_integracao,
  id_empresa,
  app_name_pm2,
  tipo_mensageiro,
  id_pagina,
  reload = null,
) => ({
  type: REQUEST_DELETE_PROCESSOS,
  payload: {
    host,
    id_integracao,
    id_empresa,
    app_name_pm2,
    tipo_mensageiro,
    id_pagina,
    reload,
  },
})

export const requestDeleteProcessosSucess = (
  id_integracao,
  tipo_mensageiro,
) => ({
  type: REQUEST_DELETE_PROCESSOS_SUCESS,
  payload: { id_integracao, tipo_mensageiro },
})

export const requestDeleteProcessosError = () => ({
  type: REQUEST_DELETE_PROCESSOS_ERROR,
})

export const requestSyncConversationsWhats = (processo) => ({
  type: REQUEST_SYNC_CONVERSATIONS_WHATS,
  payload: processo,
})

export const removeWebsocket = (idIntegracao, tipo_mensageiro) => ({
  type: REMOVE_WEBSOCKET,
  payload: { idIntegracao, tipo_mensageiro }
})

export const setEmailSocket = (socket) => ({
  type: SET_EMAIL_SOCKET,
  payload: socket,
})

export const setCentralSocket = (socket) => ({
  type: SET_CENTRAL_SOCKET,
  payload: socket,
})

export const addMessageWarning = (idIntegracao, tipoMensageiro) => ({
  type: ADD_MESSAGE_WARNING,
  payload: { idIntegracao, tipoMensageiro },
})

export const removeMessageWarning = (idIntegracao, tipoMensageiro) => ({
  type: REMOVE_MESSAGE_WARNING,
  payload: { idIntegracao, tipoMensageiro },
})
