import { all, put, takeEvery, call, fork } from "redux-saga/effects";
import { api } from "../../services/api";

import { REQUEST_SMS_CONFIG } from "./constants";

import { setSmsConfig } from "../actions";
import store from "../store";

function* loadConfigSmsCentral() {
  const state = store.getState();

  const { id_empresa } = state.Auth.user;

  try {
    const resSms = yield call(api.get, `/sms/config?id_empresa=${id_empresa}`);
    const sms = resSms.data;

    yield put(setSmsConfig(sms));
  } catch (error) {}
}

export function* watchLoadSmsConfig() {
  yield takeEvery(REQUEST_SMS_CONFIG, loadConfigSmsCentral);
}

function* smsSaga() {
  yield all([fork(watchLoadSmsConfig)]);
}

export default smsSaga;
