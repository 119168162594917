import { setActiveEmailInbox, setEmailAccess } from "../../redux/actions"
import store from "../../redux/store"


const checkUserEmailRestrictions = (emailConversation) => {

  const state = store.getState()

  const { id_usuario, id_permissao } = state.Auth.user
  const { emailGlobal } = state.Email.emailConfig

  let canSee = false

  let attendants = []
  let hasAttendant = null
  let view_all_emails = 0

  if (emailGlobal) {
    view_all_emails = emailGlobal.view_all_emails

    attendants = emailGlobal.attendants === "" ? [] : JSON.parse(emailGlobal.attendants)
    hasAttendant = attendants.find(attendant => parseInt(attendant.value) === id_usuario)
  }

  /*
  Permições
  1 - Global
  2 - Restrito
  3 - Equipe
  */
  switch (id_permissao) {
    case 1:
      canSee = true
      break
    case 2:
      if (hasAttendant && view_all_emails === 1) {
        canSee = true
      } else if (hasAttendant &&
        emailConversation.attendant_id === id_usuario) {
        canSee = true
      }
      break
    case 3:
      if (hasAttendant && view_all_emails === 1) {
        canSee = true
      } else if (hasAttendant && emailConversation.attendant_id === id_usuario) {
        canSee = true
      }
      break
  }

  return canSee
}

const getEmailInboxInfo = () => {
  const state = store.getState()

  const { id_usuario, id_permissao } = state.Auth.user
  const { emailConfig } = state.Email
  const { emailGlobal } = emailConfig

  let canSee = false

  let attendants = []
  let hasAttendant = null
  let view_all_emails = false

  if (emailGlobal) {
    view_all_emails = emailGlobal.view_all_emails === 1

    attendants = emailGlobal.attendants === "" ? [] : JSON.parse(emailGlobal.attendants)

    attendants = attendants.map(attendant => { return { ...attendant, value: parseInt(attendant.value) } })
    hasAttendant = attendants.find(attendant => parseInt(attendant.value) === id_usuario)
  }

  return { hasAttendant, view_all_emails }
}

const getEmailsProvider = () => {
  const state = store.getState()
  const { emailConfig } = state.Email

  const { emailGlobal, emailUser } = emailConfig
  console.log('emailConfig', { emailGlobal, emailUser })

  const { hasAttendant, view_all_emails } = getEmailInboxInfo()
  console.log('hasAttendant, view_all_emails', hasAttendant, view_all_emails)

  const provides = []
  let hasEmailConfig = false
  let activeEmail = ''
  let activeProvider = {}
  if (emailGlobal && emailGlobal.sender_email && hasAttendant) {
    provides.push({
      value: emailGlobal.sender_email,
      label: emailGlobal.sender_email
    })

    activeEmail = emailGlobal.sender_email

  }

  if (emailUser && emailUser.sender_email) {

    if (!activeEmail) {
      activeEmail = emailUser.sender_email
    }
    provides.push({
      value: emailUser.sender_email,
      label: emailUser.sender_email
    })
  }

  if (provides.length > 0) {
    hasEmailConfig = true
    store.dispatch(setActiveEmailInbox(activeEmail))
    store.dispatch(setEmailAccess(true))

    activeProvider = provides.find(provider => provider.value === activeEmail)
  }


  return { provides, activeProvider, hasEmailConfig }

}

export { checkUserEmailRestrictions, getEmailsProvider }
