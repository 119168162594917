import {
  LOAD_TEMPLATE_MESSAGE,
  NEW_TEMPLATE_MESSAGE_DATA,
  REQUEST_CONFIG,
  REQUEST_CONFIG_SUCESS,
  REQUEST_SMS_CONFIG,
  SET_SMS_CONFIG,
  TEMPLATE_MESSAGES_SUCESS,
} from "./constants";

export const requestConfigCentral = (idEmpresa, reconnect = null) => ({
  type: REQUEST_CONFIG,
  payload: { idEmpresa, reconnect },
});

export const setConfigCentral = (data) => ({
  type: REQUEST_CONFIG_SUCESS,
  payload: { ...data },
});

export const setTemplateMessagesSucess = (templates) => ({
  type: TEMPLATE_MESSAGES_SUCESS,
  payload: templates,
});

export const setNewTemplateMessage = ({
  title,
  type,
  integration,
  identifier,
  category,
  language,
}) => ({
  type: NEW_TEMPLATE_MESSAGE_DATA,
  payload: { title, type, integration, identifier, category, language },
});

export const loadTemplateMessage = ({
  search,
  active,
  type,
  template_type,
}) => ({
  type: LOAD_TEMPLATE_MESSAGE,
  payload: { search, active, type, template_type },
});