import {
  CHAT_USER,
  ACTIVE_CHAT_INDEX,
  ACTIVE_USER_ID,
  FULL_USER,
  CHAT_INIT,
  ADD_LOGGED_USER,
  CREATE_GROUP,
  SET_PROFILE_USER_WHATSAPP,
  STATE_CHANGE,
  ADD_AUDIO_SOURCE,
  ACK_CHANGE,
  REMOVE_CHATS,
  ACTIVE_RECIPIENT_ID,
  REPLY_CHAT_ID,
  EMIT_MESSAGE_WEBSOCKET,
  ADD_IMAGE_SOURCE,
  NEW_MESSAGE,
  NEW_CHAT,
  REQUEST_FILE,
  ADD_MESSAGE,
  FILTERED_CHAT,
  ADD_TAGS,
  FETCH_CHATS_IN_DB,
  REQUEST_CHAT_MESSAGES,
  SET_NEW_CHAT_MESSAGES,
  PLAY_NOTIFICATION_SOUND,
  END_CONVERSATION,
  TAKE_UP_CONVERSATION,
  ACTIVE_CHAT_MONGO_ID,
  REMOVE_CHAT,
  CONVERSATION_UPDATE_IN_DATABASE,
  CONVERSATION_UPDATE_WAITING_ROOM_IN_DATABASE,
  CONVERSATION_UPDATE_MY_CHAT_IN_DATABASE,
  REMOVE_DELETED_MESSAGE,
  SET_LEAD_DATA,
  UPDATE_CHAT_IN_DB,
  REQUEST_EARLIER_MESSAGES,
  SET_EARLIER_MESSAGES,
  REQUEST_CHECK_LEAD,
  END_CONVERSATION_BOT,
  ERROR_SENDING_MESSAGE,
  DELETE_UNSENT_MESSAGE,
  SET_UNSENT_MESSAGE,
  SET_NEW_LEAD_CONVERSATION,
  REQUEST_LOAD_ALL_MESSAGES,
  SET_CURRENT_ATTENDANT_SELECTED,
  SET_SELECT_TEAMS_OPTIONS,
  SET_SELECT_ATTENDANT_OPTIONS,
  SET_CURRENT_TEAM_SELECTED,
  REQUEST_PARSE_ATTENDANT_OPTIONS,
  SET_MY_CHATS,
  FETCH_MY_CHATS_IN_DB,
  FETCH_WAITING_ROOM_CHATS_IN_DB,
  SET_WAITING_ROOM_CHATS,
  REMOVE_MY_CHAT,
  REMOVE_WAITING_ROOM_CHAT,
  SET_COUNT_WAITING_ROOM,
  SET_COUNT_MY_CHATS,
  MARK_UNSEEN_MESSAGE,
  CLEAR_FETCH_NEW_CHAT,
  FETCH_NEW_CHAT,
  SET_FAILURE_EVENT,
  SET_UNREAD,
  SET_ALL_ROOM_TAGS,
  SET_TEMPLATE_TYPE_MESSAGE,
  MOVE_CHAT,
  CHAT_UPDATE_IN_DATABASE,
  REQUEST_PROFILE_PICTUTE_TO_CHAT,
  REQUEST_OPEN_NEW_CHAT_ACTION,
  REQUEST_CHECK_EMAIL_LEAD,
  ORDER_CHATS_BY_TAB,
  CLEAR_CHAT_ACTIVED,
  SET_CHAT_MONGO_ID_FOR_BATCH_ACTION,
  REQUEST_CHAT_BATCH_ACTION,
  CLEAR_CHAT_MONGO_ID_FOR_BATCH_ACTION,
  UPDATE_CHAT,
  UPDATE_CHAT_LEAD_IN_DB,
  FETCH_WAITING_ROOM_CHATS_COUNT_IN_DB,
  ON_NEW_CHAT_EVENT,
  ON_CONVERSATION_UPDATE_IN_DATABASE,
  SET_ALL_CHAT_MONGO_ID_FOR_BATCH_ACTION,
  REQUEST_CHECK_CHATS_OPEN_TO_SEND_NOTE,
} from "./constants";

export const chatUser = () => ({
  type: CHAT_USER,
});

export const requestCheckLead = (chat, updatedFields = {}) => ({
  type: REQUEST_CHECK_LEAD,
  payload: { chat, updatedFields },
});

export const requestCheckEmailLead = (conversation, updatedFields = {}) => ({
  type: REQUEST_CHECK_EMAIL_LEAD,
  payload: { conversation, updatedFields },
});

export const activeChatIndex = (chatIndex) => ({
  type: ACTIVE_CHAT_INDEX,
  payload: chatIndex,
});

export const activeUserId = (userId) => ({
  type: ACTIVE_USER_ID,
  payload: userId,
});

export const activeRecipientId = (recipientId) => ({
  type: ACTIVE_RECIPIENT_ID,
  payload: recipientId,
});

export const setActiveChatMongoId = (idMongo) => ({
  type: ACTIVE_CHAT_MONGO_ID,
  payload: idMongo,
});

export const emitMessageWebsocket = (event, data) => ({
  type: EMIT_MESSAGE_WEBSOCKET,
  payload: { event, data },
});

export const setReplyMessage = (data) => ({
  type: REPLY_CHAT_ID,
  payload: data,
});

export const setUnsentMessage = (idMongo, message) => ({
  type: SET_UNSENT_MESSAGE,
  payload: {
    idMongo,
    message,
  },
});

export const deleteUnsentMessage = (idMongo) => ({
  type: DELETE_UNSENT_MESSAGE,
  payload: idMongo,
});

export const ackChange = (tabName, indexChat, ackData) => ({
  type: ACK_CHANGE,
  payload: { tabName, indexChat, ackData },
});

export const setFailureEvent = (tabName, indexChat, data) => ({
  type: SET_FAILURE_EVENT,
  payload: { tabName, indexChat, data },
});

export const setCountMyChats = (value) => ({
  type: SET_COUNT_MY_CHATS,
  payload: value,
});

export const setCountWaitingRoom = (value) => ({
  type: SET_COUNT_WAITING_ROOM,
  payload: value,
});

export const addAudioSource = (chatId, messageId, audioSource, tabName) => ({
  type: ADD_AUDIO_SOURCE,
  payload: {
    chatId,
    messageId,
    audioSource,
    tabName,
  },
});

export const addImageSource = (chatId, messageId, imageSource, tabName) => ({
  type: ADD_IMAGE_SOURCE,
  payload: {
    chatId,
    messageId,
    imageSource,
    tabName,
  },
});

export const setUserImgWhatsapp = (imgUrl) => ({
  type: SET_PROFILE_USER_WHATSAPP,
  payload: imgUrl,
});

export const setStateChange = (state) => ({
  type: STATE_CHANGE,
  payload: state,
});

export const setChatInit = (value) => ({
  type: CHAT_INIT,
  payload: value,
});

export const setFullUser = (fullUser) => ({
  type: FULL_USER,
  payload: fullUser,
});

export const setMyChats = (chats) => ({
  type: SET_MY_CHATS,
  payload: chats,
});

export const setWaitingRoomChats = (chats) => ({
  type: SET_WAITING_ROOM_CHATS,
  payload: chats,
});

export const setNewMessage = (tabName, indexChat, message) => ({
  type: NEW_MESSAGE,
  payload: { tabName, indexChat, message },
});

export const setNewChatMessages = (data) => ({
  type: SET_NEW_CHAT_MESSAGES,
  payload: data,
});

export const setEarlierMessages = (data) => ({
  type: SET_EARLIER_MESSAGES,
  payload: data,
});

export const addMessage = (addMessage) => ({
  type: ADD_MESSAGE,
  payload: addMessage,
});

export const setNewChat = (tabName, chat, bot = null) => ({
  type: NEW_CHAT,
  payload: { tabName, chat, bot },
});

export const setNewLeadConversation = (data) => ({
  type: SET_NEW_LEAD_CONVERSATION,
  payload: data,
});

export const moveChat = (idMongo, destinatedRoom) => ({
  type: MOVE_CHAT,
  payload: { idMongo, destinatedRoom },
});

export const removeChats = (idIntegracao) => ({
  type: REMOVE_CHATS,
  payload: idIntegracao,
});

export const removeChat = (
  idMongo,
  tabName,
  cleanScreen = false,
  activeChatsTab = ""
) => ({
  type: REMOVE_CHAT,
  payload: { idMongo, tabName, cleanScreen, activeChatsTab },
});

export const removeMyChat = (
  idMongo,
  tabName,
  cleanScreen = false,
  activeChatsTab = ""
) => ({
  type: REMOVE_MY_CHAT,
  payload: { idMongo, tabName, cleanScreen, activeChatsTab },
});

export const removeWaitingRoomChat = (
  idMongo,
  tabName,
  cleanScreen = false,
  activeChatsTab = ""
) => ({
  type: REMOVE_WAITING_ROOM_CHAT,
  payload: { idMongo, tabName, cleanScreen, activeChatsTab },
});

export const setErrorSendingMessage = (data) => ({
  type: ERROR_SENDING_MESSAGE,
  payload: data,
});

export const setConversationUpdateInDatabase = (data) => ({
  type: CONVERSATION_UPDATE_IN_DATABASE,
  payload: data,
});

export const setConversationUpdateWaitingRoomInDatabase = (data) => ({
  type: CONVERSATION_UPDATE_WAITING_ROOM_IN_DATABASE,
  payload: data,
});

export const setConversationUpdateMyChatInDatabase = (data) => ({
  type: CONVERSATION_UPDATE_MY_CHAT_IN_DATABASE,
  payload: data,
});

export const setChatUpdateInDatabase = (data) => ({
  type: CHAT_UPDATE_IN_DATABASE,
  payload: data,
});

export const setRemoveDeletedMessege = (
  idMongoChat,
  chatId,
  messagesIdArray
) => ({
  type: REMOVE_DELETED_MESSAGE,
  payload: {
    idMongoChat,
    chatId,
    messagesIdArray,
  },
});

export const addLoggedinUser = (userData) => ({
  type: ADD_LOGGED_USER,
  payload: userData,
});

export const createGroup = (groupData) => ({
  type: CREATE_GROUP,
  payload: groupData,
});

export const requestDownloadFile = (message) => ({
  type: REQUEST_FILE,
  payload: message,
});

export const requestChatMessages = (
  chatId,
  idMongo,
  identifier,
  idIntegracao,
  idEmpresa
) => ({
  type: REQUEST_CHAT_MESSAGES,
  payload: {
    chatId,
    idMongo,
    identifier,
    idIntegracao,
    idEmpresa,
  },
});

export const requestProfilePictureToChat = (
  chatId,
  idMongo,
  identifier,
  idIntegracao
) => ({
  type: REQUEST_PROFILE_PICTUTE_TO_CHAT,
  payload: {
    chatId,
    idMongo,
    identifier,
    idIntegracao,
  },
});

export const requestEarlierMessages = (
  chatId,
  idMongo,
  pagination,
  identifier,
  idIntegracao
) => ({
  type: REQUEST_EARLIER_MESSAGES,
  payload: {
    chatId,
    idMongo,
    pagination,
    identifier,
    idIntegracao,
  },
});

export const requestLoadAllMessages = (
  chatId,
  idMongo,
  identifier,
  idIntegracao
) => ({
  type: REQUEST_LOAD_ALL_MESSAGES,
  payload: {
    chatId,
    idMongo,
    identifier,
    idIntegracao,
  },
});

export const setFilteredChat = (bool) => ({
  type: FILTERED_CHAT,
  payload: bool,
});

export const setTags = (data) => ({
  type: ADD_TAGS,
  payload: data,
});

export const fitchChatsInDb = (page = null, importacao = false) => ({
  type: FETCH_CHATS_IN_DB,
  payload: {
    page,
    importacao,
  },
});

export const fitchMyChatsInDb = (
  idResponsavel,
  page = null,
  importacao = false
) => ({
  type: FETCH_MY_CHATS_IN_DB,
  payload: {
    idResponsavel,
    page,
    importacao,
  },
});

export const fitchWaitingRoomChatsInDb = (page = null, importacao = false) => ({
  type: FETCH_WAITING_ROOM_CHATS_IN_DB,
  payload: {
    page,
    importacao,
  },
});

export const countWaitingRoomChatsInDb = () => ({
  type: FETCH_WAITING_ROOM_CHATS_COUNT_IN_DB,
});

export const fitchNewChat = (message) => ({
  type: FETCH_NEW_CHAT,
  payload: message,
});

export const clearFitchNewChat = () => ({
  type: CLEAR_FETCH_NEW_CHAT,
});

export const updateChatInDb = (idMongo, dataForm, updatedFields) => ({
  type: UPDATE_CHAT_IN_DB,
  payload: {
    idMongo,
    dataForm,
    updatedFields,
  },
});

export const updateChatLeadInDb = (idLead, dataForm, updatedFields) => ({
  type: UPDATE_CHAT_LEAD_IN_DB,
  payload: {
    idLead,
    dataForm,
    updatedFields,
  },
});

export const setUnreadAllRooms = (idMongo, unRead) => ({
  type: SET_UNREAD,
  payload: {
    idMongo,
    unRead,
  },
});

export const setAllRoomTags = (idMongo, tags) => ({
  type: SET_ALL_ROOM_TAGS,
  payload: {
    idMongo,
    tags,
  },
});

export const setPlayNotificationSound = (bool) => ({
  type: PLAY_NOTIFICATION_SOUND,
  payload: bool,
});

export const setEndConversation = (finishBot = false) => ({
  type: END_CONVERSATION,
  payload: finishBot,
});

export const setEndConversationBot = () => ({
  type: END_CONVERSATION_BOT,
});

export const setTakeUpConversation = () => ({
  type: TAKE_UP_CONVERSATION,
});

export const setMarkUnseenMessage = (idMongo, chatId) => ({
  type: MARK_UNSEEN_MESSAGE,
  payload: { idMongo, chatId },
});

export const setLeadData = (data) => ({
  type: SET_LEAD_DATA,
  payload: data,
});

export const requestParseAttendantOptions = (idAttendant) => ({
  type: REQUEST_PARSE_ATTENDANT_OPTIONS,
  payload: idAttendant,
});

export const setSelectTeamsOptions = (teamsOptions) => ({
  type: SET_SELECT_TEAMS_OPTIONS,
  payload: teamsOptions,
});

export const setSelectAttendantOptions = (attendantsOptions) => ({
  type: SET_SELECT_ATTENDANT_OPTIONS,
  payload: attendantsOptions,
});

export const setCurrentTeamSelected = (currentTeam) => ({
  type: SET_CURRENT_TEAM_SELECTED,
  payload: currentTeam,
});

export const setCurrentAttendantSelected = (currentAttendant) => ({
  type: SET_CURRENT_ATTENDANT_SELECTED,
  payload: currentAttendant,
});

export const setTemplateTypeMessage = (bool) => ({
  type: SET_TEMPLATE_TYPE_MESSAGE,
  payload: bool,
});

export const openNewChatActionRequest = (id_cliente) => ({
  type: REQUEST_OPEN_NEW_CHAT_ACTION,
  payload: id_cliente,
});

export const sortConversationsByTabName = (tabName) => ({
  type: ORDER_CHATS_BY_TAB,
  payload: tabName,
});

export const clearChatIsActived = (idIntegracao) => ({
  type: CLEAR_CHAT_ACTIVED,
  payload: { idIntegracao },
});

export const setChatMongoIdForBatchAction = (idMongo) => ({
  type: SET_CHAT_MONGO_ID_FOR_BATCH_ACTION,
  payload: idMongo,
});

export const setAllChatMongoIdForBatchAction = (ids) => ({
  type: SET_ALL_CHAT_MONGO_ID_FOR_BATCH_ACTION,
  payload: ids,
});

export const clearChatMongoIdForBatchAction = () => ({
  type: CLEAR_CHAT_MONGO_ID_FOR_BATCH_ACTION,
});

export const requestChatBatchAction = ({ finishChat, finishBot }) => ({
  type: REQUEST_CHAT_BATCH_ACTION,
  payload: { finishChat, finishBot },
});

export const setChatUpdated = (chatUpdated) => ({
  type: UPDATE_CHAT,
  payload: { chatUpdated },
});

export const setOnNewChatEvent = (data) => ({
  type: ON_NEW_CHAT_EVENT,
  payload: data,
});

export const setOnConversationUpdateInDatabase = (data) => ({
  type: ON_CONVERSATION_UPDATE_IN_DATABASE,
  payload: data,
});

export const requestCheckChatsOpenToSendNote = (activeChat) => ({
  type: REQUEST_CHECK_CHATS_OPEN_TO_SEND_NOTE,
  payload: activeChat,
});
