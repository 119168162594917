import store from '../redux/store'
import { setAlertMessage } from '../redux/actions'

const checkErrorBoundary = () => {
  const cetralError = localStorage.getItem('centralError')
  const dataError = cetralError
    ? typeof cetralError == 'object'
      ? cetralError
      : JSON.parse(cetralError)
    : null
  console.log('dataError =>', dataError)
  if (dataError) {
    store.dispatch(
      setAlertMessage(
        'Ocorreu uma instabilidade no serviço. Caso continue, entre em contato com o suporte',
        'error',
      ),
    )

       localStorage.removeItem('centralError')
  }
}

export default checkErrorBoundary
