import {produce} from 'immer'
import { REQUEST_TEMPLATES_WHATSAPP_SUCESS } from './constants'

const INIT_STATE = {
}

const WZapApiOficial = (state = INIT_STATE, action) => {
  switch (action.type) {
    case REQUEST_TEMPLATES_WHATSAPP_SUCESS:
      return produce(state, (draft) => {
        const appName = action.payload.appName
        draft[appName] = {
          ...action.payload.data,
        }
      })
    default:
      return state
  }
}

export default WZapApiOficial
