import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as Unicons from '@iconscout/react-unicons'
import { connect } from 'react-redux'

import {
  openModalTestMode,
  closeModalTestMode,
  startModalTestMode,
  finishModalTestMode,
  setAlertMessage,
} from '../../redux/actions'
import store from '../../redux/store'

export const handleOpenModalTestMode = () => {
  store.dispatch(openModalTestMode())
}

function ModalTestMode(props) {
  function enableTestMode() {
    props.startModalTestMode()
    props.closeModalTestMode()
    props.setAlertMessage(
      'Você está no modo teste. Após concluir todos os teste clice AQUI para finalizar.',
      'test-mode',
    )
  }

  function finishTestMode() {
    props.finishModalTestMode()
    props.closeModalTestMode()

    return (window.location.href = '/central-atendimento')
  }

  return (
    <Modal tabIndex="-1" isOpen={props.modelTestMode} centered>
      <ModalBody>
        {props.testMode ? (
          <div className="modal_test_mode text-center p-4">
            <div className="mt-4 msg_test_mode">
              <h4>Desativar Modo Teste?</h4>
              <h6>
                Você está no modo teste. Se concluiu todos os testes é só clicar
                em Finalizar
              </h6>
            </div>
          </div>
        ) : (
          <div className="modal_test_mode text-center p-4">
            <div className="mt-4 msg_test_mode">
              <h4>Ativar Modo Teste?</h4>
              <h6>
                Você está prestes a ativar o modo teste. Após ativar, você pode
                iniciar os seus teste e depois de concluir, é só clicar em
                Finalizar
              </h6>
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter className="border-0">
        {props.testMode ? (
          <Button color="secundary" onClick={() => finishTestMode()}>
            Finalizar
          </Button>
        ) : (
          <Button color="primary" onClick={() => enableTestMode()}>
            Ativar
          </Button>
        )}
        <Button color="primary" onClick={() => props.closeModalTestMode()}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  const { modelTestMode, testMode } = state.Layout
  return { modelTestMode, testMode }
}

export default connect(mapStateToProps, {
  openModalTestMode,
  closeModalTestMode,
  startModalTestMode,
  finishModalTestMode,
  setAlertMessage,
})(ModalTestMode)
