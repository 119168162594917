import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  FORGET_PASSWORD,
} from "./constants";

import {
  loginUserSuccess,
  registerUserSuccess,
  forgetPasswordSuccess,
  apiError,
} from "./actions";
import { apiNova, api } from "../../services/api";
import Bugsnag from "@bugsnag/js";

/**
 * Sets the session
 * @param {*} user
 */

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { email, password, history } }) {
  try {
    const response = yield call(apiNova.post, "/auth/login", {
      email,
      senha: password,
    });

    const { access_token, user } = response.data.data;
    localStorage.setItem("auth", JSON.stringify({ token: access_token }));
    console.log("response =>>", response);
    yield put(loginUserSuccess(user));
    window.location.href = "/central-atendimento";
    window.location.reload();
  } catch (error) {
    Bugsnag.notify(error)
    yield put(apiError(error));
  }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
  try {
    localStorage.removeItem("auth");

    yield call(() => {
      history.push("/central-atendimento/login");
    });
  } catch (error) {
    Bugsnag.notify(error)
  }
}

/**
 * Register the user
 */
function* register({ payload: { user } }) {
  try {
    const response = yield call(api, "/register", user);
    yield put(registerUserSuccess(response));
  } catch (error) {
    Bugsnag.notify(error)
    yield put(apiError(error));
  }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { email } }) {
  try {
    const response = yield call(api, "/forget-pwd", { email });
    yield put(forgetPasswordSuccess(response));
  } catch (error) {
    Bugsnag.notify(error)
    yield put(apiError(error));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgetPassword),
  ]);
}

export default authSaga;
