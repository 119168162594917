import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import * as Unicons from "@iconscout/react-unicons";
import AlertMessage from "../../components/AlertMessage";
import ModalInfo from "../../components/Modals/Info";
import ModalWhatsapp from "../../components/Modals/ModalWhatsapp";
import {
  setActiveTab,
  setChatInit,
  setLogo,
  setStartSocket,
  getReportHistory,
  setAlertMessage,
  openModalTestMode,
  requestEmailList,
  setNewEmail,
  setStartEmailSocket,
  requestEmailConfig,
  setEmailStatusUpdated,
  setEmailLeadUpdated,
  setNewEmails,
  setCentralLoaded,
  requestCheckEmailLead,
  setEmailSocket,
  setCentralSocket,
  removeEmailConversation,
  openModalInfo,
  removeWebsocket,
  requestProcessos,
  removeChats,
  addMessageWarning,
  setStateChange,
  fitchWaitingRoomChatsInDb,
  fitchMyChatsInDb,
  fitchChatsInDb,
  removeChat,
  removeMyChat,
  removeWaitingRoomChat,
  setOnNewChatEvent,
  setOnConversationUpdateInDatabase,
  setCentralAlert,
} from "../../redux/actions";
import Ws from "@adonisjs/websocket-client";
import {
  setQueryParamsURL,
  requestValidCode,
  setBootstrap,
  requestConfigCentral,
  loginUserSuccess,
  setSidebarFixed,
  setChartsCarfsTop,
  setChartFonte,
  setTableDesempenho,
  setChartGraph,
} from "../../redux/actions";

//Import Images
import logo from "../../assets/images/novo-logo-meets-m.png";
import logoClinic from "../../assets/images/logo-clinic.png";
import useQuery from "../../utils/useQuery";
import checkErrorBoundary from "../../utils/checkErrorBoundary";
import { isUserAuthenticated } from "../../helpers/authUtils";
import store from "../../redux/store";
import { checkEmailProviderForActiveInbox } from "../../utils/email/checkEmailProviderForActiveInbox";
import { checkUserEmailRestrictions } from "../../utils/email/checkUserEmailProvider";
import SyncConversations from "../../components/Modals/SyncConversations";
import { api } from "../../services/api";
import NotificationBadge from "react-notification-badge/lib/components/NotificationBadge";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BackLogoutModal from "../AuthLayout/BackLogoutModal";

const idEmpresaAcessEmailtab = [12276, 10929, 5, 20195, 14849, 21055, 21970];

let loadChats = 0;

Bugsnag.start({
  apiKey: "4a8a4301aa7eb4409521c2fcaa0af59f",
  enabledReleaseStages: ["production"],
  plugins: [new BugsnagPluginReact()],
  onError: (event) => {
    const erroMsg = event.errors[0].errorMessage;
    if (erroMsg.includes("ResizeObserver loop limit exceeded")) {
      return false;
    }
  },
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

function LeftSidebarMenu(props) {
  let history = useHistory();
  const [visibleLogo, setVisibleLogo] = useState(false);
  const [dropdownOpenMobile, setDropdownOpenMobile] = useState(false);
  const [imgAvatar, setImgAvatar] = useState(logo);
  const query = useQuery();
  let location = useLocation();
  const [showBackLogoutModal, setShowBackLogoutModal] = useState(false);

  const toggleMobile = () => setDropdownOpenMobile(!dropdownOpenMobile);

  const toggleTab = (tab, pathname) => {
    props.setActiveTab(tab);

    if (tab === "contacts") {
      props.setChatInit(true);
    }

    history.push(pathname);
  };

  useEffect(() => {
    validadeAutentication();
    var w = window.innerWidth;

    if (w > 1360) {
      console.log("setSidebarFixed");
      props.setSidebarFixed(true);
    }

    checkErrorBoundary();
    checkQueryParams();
    checkTestMode();
    const pathname = location.pathname;
    if (
      pathname !== "/central-atendimento" &&
      pathname !== "/configs/call-center/settings-email"
    ) {
      props.setCentralLoaded(true);
    }
  }, []);

  async function validadeAutentication() {
    const user = await isUserAuthenticated();
    if (user) {
      console.log("user =>", user);
      props.loginUserSuccess(user);

      const reconnect = query.get("reconnect");

      checkHasFacebokCode();
      console.log("reconnect", reconnect);
      if (props.bootstrap) {
        props.requestConfigCentral(user.id_empresa, reconnect);
        props.requestEmailConfig();
        props.setBootstrap(false);
      }
    } else {
      localStorage.removeItem("auth");
      return (window.location.href = "/central-atendimento/login");
    }
  }

  function checkHasFacebokCode() {
    const pathname = location.pathname;
    const code = query.get("code");

    if (pathname === "/central-atendimento/instagram-code" && code) {
      props.requestValidCode(code, "instagram");
    }

    if (pathname === "/central-atendimento/messenger-code" && code) {
      props.requestValidCode(code, "messenger");
    }
  }

  function checkTestMode() {
    try {
      const pathname = location.pathname;
      const modo = query.get("modo");

      if (pathname === "/central-atendimento" && modo === "teste") {
        console.log("openModalTestMode");
        props.openModalTestMode();
      }
    } catch (error) {
      console.log("checkTestMode error ", error);
    }
  }

  function checkQueryParams() {
    const action = query.get("action");

    if (action) {
      switch (action) {
        case "open_chat":
          const id_cliente = query.get("id_cliente");
          props.setQueryParamsURL({
            action,
            id_cliente,
          });
          query.delete("action");
          query.delete("id_cliente");
          break;

        default:
          break;
      }
    }
  }

  useEffect(() => {
    if (
      props.enterprise?.white_label === 1 ||
      props.enterprise?.white_label_parent > 0
    ) {
      if (props.enterprise.whitelabel) {
        if (
          props.enterprise.whitelabel.title_page &&
          props.enterprise.whitelabel.title_page.includes("Clinic Machine")
        ) {
          props.setLogo(logoClinic);
        } else {
          props.setLogo(
            `https://acesso.meets.com.br/img/${props.enterprise.whitelabel.favicon}`
          );
        }
        setVisibleLogo(true);
      }
    } else {
      if (props.enterprise?.white_label === 0) {
        props.setLogo(logo);
        setVisibleLogo(true);
      }
    }
  }, [props.enterprise]);

  useEffect(() => {
    if (props.user.id_usuario && !props.wsCentral) {
      startSocketCentral(props.user.id_empresa);
    }

    if (props.emailConfig && props.user.id_usuario && !props.emailSocketStart) {
      startSocketEmail(props.user.id_empresa);
    }

    if (
      props.user.id_usuario &&
      props.user.id_permissao === 1 &&
      !props.report.socketStart
    ) {
      startSocketReport(props.user.id_usuario);
    }
  }, [props.emailConfig, props.user.id_usuario]);

  const startSocketReport = (id_usuario) => {
    const ws = Ws(process.env.REACT_APP_WS_GERADOR, {
      reconnect: true,
    }).connect();

    ws.on("open", () => {
      const channel = ws.subscribe(`report:${id_usuario}`);

      console.log("channel =>", channel);

      const res = ws.getSubscription(`report:${id_usuario}`);

      console.log("res", res);

      channel.on("finish", async (data) => {
        let tableDesempenho = {
          atendentes: data?.attendances ?? [],
        };

        let chartFonte = data?.countAllTipoMensageiros ?? 0;

        let chartGraph = {
          labels: [],
          datasets: [
            {
              label: "Performance",
              data: [],
            },
          ],
        };
        Object.keys(data?.countPreviusAttendances || {}).forEach((v) => {
          chartGraph.labels.push(v);
          chartGraph.datasets[0].data.push(data.countPreviusAttendances[v]);
        });
        let chartsCardsTop = {
          newAttendances: data?.countServices ?? 0,
          averageResponse: data?.avgDifferences ?? 0,
          acquiredAttendances: data?.conquestedServices ?? 0,
          leadReceveid: data?.countAllReceived ?? 0,
          finalized: data?.countAllServicesFinalized ?? 0,
          countBot: data?.countBot ?? 0,
        };
        props.setChartsCarfsTop(chartsCardsTop);
        props.setChartFonte(chartFonte);
        props.setTableDesempenho(tableDesempenho);
        props.setChartGraph(chartGraph);
      });

      props.setStartSocket(true);
    });
  };

  const startSocketEmail = (id_empresa) => {
    console.log("startSocketEmail ==", props.emailSocketStart);
    const ws = Ws(process.env.REACT_APP_WS_GERADOR, {
      reconnect: true,
    }).connect();

    ws.on("open", () => {
      const channel = ws.subscribe(`email:${id_empresa}`);

      props.setEmailSocket(channel);

      channel.on("newEmail", (email) => {
        console.log("newEmail =>", email);

        const newEmail = checkEmailProviderForActiveInbox([email]);
        props.setNewEmail(newEmail);
      });

      channel.on("newEmails", (emails) => {
        console.log("emails =>", emails);

        const newEmailConversation = checkEmailProviderForActiveInbox(emails);

        const emailConversation = newEmailConversation.filter((email) =>
          checkUserEmailRestrictions(email)
        );

        props.setNewEmails(emailConversation);
      });

      channel.on("emailStatusUpdated", (emailData) => {
        console.log("emailStatusUpdated =:", emailData);

        const emailConversation = [emailData].filter((email) =>
          checkUserEmailRestrictions(email)
        );

        if (emailConversation) {
          props.setEmailStatusUpdated(emailConversation[0]);
        }
      });

      channel.on("emailLeadUpdated", (emailData) => {
        console.group("emailLeadUpdated", emailData);

        const state = store.getState();

        const { active_email_id, emails } = state.Email;

        const emailConversation = [emailData].filter((email) =>
          checkUserEmailRestrictions(email)
        );

        console.group("emailConversation", emailConversation);

        let emailsActive = emails.find(
          (email) => email.id === parseInt(active_email_id)
        );

        if (emailConversation.length > 0) {
          if (emailsActive && emailsActive.id === emailConversation[0].id) {
            props.requestCheckEmailLead(emailsActive);
          }
          props.setEmailLeadUpdated(emailConversation[0]);
        } else {
          if (emailsActive && emailsActive.id === emailData.id) {
            history.push("/email");
            props.openModalInfo(
              `E-mail ${emailData.subject} transferido para outro usuário.`,
              ""
            );
          }
          props.removeEmailConversation(emailData);
        }
      });

      props.setStartEmailSocket(true);
      props.requestEmailList();
      props.setCentralLoaded(true);
    });
  };

  const startSocketCentral = () => {
    const ws = Ws(process.env.REACT_APP_WS_GERADOR, {
      reconnect: true,
      reconnectionAttempts: 86400,
    }).connect();

    ws.on("open", () => {
      const update_center = ws.subscribe(`callcenter:update_center`);
      const callcenter = ws.subscribe(`callcenter:${props.user.id_empresa}`);
      const state = store.getState();
      const { enterprise } = state.Config;

      props.setCentralSocket(callcenter);
      console.log("enterprise", enterprise);
      if (loadChats) clearTimeout(loadChats);
      loadChats = setTimeout(() => {
        if (enterprise) {
          props.fitchMyChatsInDb(state.Auth.user.id_usuario);
          props.fitchWaitingRoomChatsInDb();
          props.fitchChatsInDb();
        }
      }, 2000);

      console.log("update_center =>", update_center);
      console.log("callcenter =>", callcenter);

      update_center.on("centralUpdated", (data) => {
        console.log("centralUpdated data =>", data);

        const path_with_namespace = data.project.path_with_namespace;
        switch (path_with_namespace) {
          case "meets-central/central-meets-react":
            props.setCentralAlert(true);
            break;
          case "meets-central/venom-bot-adonis":
            props.setAlertMessage(
              `Nova atualização para a Central. Por favor, releia todas as linhas do Whatsapp para receber a atualização`,
              "dark",
              {
                autoClose: 30000,
                closeOnClick: false,
                closeButton: false,
              }
            );
            break;
          default:
            break;
        }
      });

      callcenter.on(`centralUpdatedData`, (data) => {
        console.log("centralUpdatedData data =>", data);
        const { idIntegracao, idEmpresa, tipoMensageiro, id_servidor, host } =
          data;

        const processFindChange = state.WebService.processos[
          tipoMensageiro
        ]?.find(
          (processo) =>
            processo.id === idIntegracao &&
            processo.id_empresa === idEmpresa &&
            (processo.id_servidor !== id_servidor || processo.host !== host)
        );

        if (processFindChange) {
          props.addMessageWarning(
            processFindChange.id,
            processFindChange.tipo_mensageiro
          );
          props.setStateChange("DISCONNECTED");
          props.removeWebsocket(
            processFindChange.id,
            processFindChange.tipo_mensageiro
          );
        }
        props.requestProcessos(idEmpresa);
      });

      callcenter.on("removeChat", (data) => {
        console.log("removeChat data =>", data);

        const idMongo = data.idMongo;
        const tabNames = data.tabNames;

        const activeChatsTab = props.activeChatsTab;
        console.log("onRemoveChat =>", { idMongo, activeChatsTab });

        tabNames.forEach((tabName) => {
          if (tabName === "allChatsTab") {
            props.removeChat(idMongo, tabName, false, activeChatsTab);
          }
          if (tabName === "myConversationsTab") {
            props.removeMyChat(idMongo, tabName, false, activeChatsTab);
          }
          if (tabName === "waitingRoomTab") {
            props.removeWaitingRoomChat(
              idMongo,
              tabName,
              false,
              activeChatsTab
            );
          }
        });
      });

      callcenter.on(`removeChats`, (data) => {
        console.log("removeChats data =>", data);

        const idsMongo = data.idsMongo;
        const tabNames = data.tabNames;

        const activeChatsTab = props.activeChatsTab;
        console.log("onRemoveChats =>", { idsMongo, activeChatsTab });

        for (const id of idsMongo) {
          tabNames.forEach((tabName) => {
            if (tabName === "allChatsTab") {
              props.removeChat(id, tabName, false, activeChatsTab);
            }
            if (tabName === "myConversationsTab") {
              props.removeMyChat(id, tabName, false, activeChatsTab);
            }
            if (tabName === "waitingRoomTab") {
              props.removeWaitingRoomChat(id, tabName, false, activeChatsTab);
            }
          });
        }
      });

      callcenter.on("newChat", (data) => {
        console.log("newChat data =>", data);
        if (data.chat.idEmpresa === props.user.id_empresa) {
          props.setOnNewChatEvent(data);
        }
      });

      callcenter.on("conversationUpdateInDatabase", (data) => {
        props.setOnConversationUpdateInDatabase(data);
      });
    });
  };

  const activeTab = props.activeTab;

  return (
    <ErrorBoundary>
      <div className="side-menu flex-lg-column">
        <BackLogoutModal
          show={showBackLogoutModal}
          setShow={(value) => setShowBackLogoutModal(value)}
        />
        {/* LOGO */}
        {visibleLogo && (
          <div className="navbar-brand-box">
            {props.logoImg && (
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={props.logoImg} alt="logo" height="30" />
                </span>
              </Link>
            )}

            {props.logoImg && (
              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={props.logoImg} alt="logo" height="30" />
                </span>
              </Link>
            )}
          </div>
        )}
        {/* end navbar-brand-box  */}

        {/* Start side-menu nav */}
        <div className="flex-lg-column">
          <Nav
            pills
            className="side-menu-nav justify-content-center"
            role="tablist"
          >
            {/* <NavItem id="profile">
              <NavLink id="pills-user-tab" className={classnames({ active: activeTab === 'profile' })} onClick={() => { toggleTab('profile'); }}>
                <i className="ri-user-2-line"></i>
              </NavLink>
            </NavItem>
            <UncontrolledTooltip target="profile" placement="top">
              Perfil
            </UncontrolledTooltip> */}

            <NavItem id="chat">
              <NavLink
                id="pills-chat-tab"
                className={classnames({ active: activeTab === "chat" })}
                onClick={() => {
                  toggleTab("chat", "/central-atendimento");
                }}
              >
                <div style={{ height: "0px", width: "60px" }}>
                  <NotificationBadge
                    count={props.count_waiting_room}
                    style={{
                      backgroundColor: "red",
                      position: "absolute",

                      top: "0px",
                      height: "20px",
                      width: "30px",
                    }}
                  />
                </div>
                <Unicons.UilCommentMessage />
              </NavLink>
            </NavItem>
            <UncontrolledTooltip target="chat" placement="right">
              Central de atendimento
            </UncontrolledTooltip>

            <NavItem
              id="email"
              style={{
                display:
                  props.emailAccess &&
                  idEmpresaAcessEmailtab.includes(+props.user?.id_empresa)
                    ? "block"
                    : "none",
              }}
            >
              <NavLink
                id="pills-contacts-tab"
                className={classnames({ active: activeTab === "email" })}
                onClick={() => {
                  toggleTab("email", "/email");
                }}
              >
                <Unicons.UilEnvelope />
              </NavLink>
            </NavItem>
            <UncontrolledTooltip target="email" placement="right">
              Email
            </UncontrolledTooltip>

            <NavItem id="contacts">
              <NavLink
                id="pills-contacts-tab"
                className={classnames({ active: activeTab === "contacts" })}
                onClick={() => {
                  toggleTab("contacts", "/central-atendimento");
                }}
              >
                <Unicons.UilUserPlus />
              </NavLink>
            </NavItem>
            <UncontrolledTooltip target="contacts" placement="right">
              Novo Lead
            </UncontrolledTooltip>

            {/* <NavItem id="clients">
              <NavLink id="pills-clients-tab" 
                className={classnames({ active: activeTab === 'clients' })}
                onClick={() => toggleTab('clients', '/clients')}
                >
                  <Unicons.UilUserSquare />
              </NavLink>
            </NavItem>
            <UncontrolledTooltip target="clients" placement="right">
              Clientes
            </UncontrolledTooltip> */}

            {/* <NavItem id="tools">
              <NavLink
                id="pills-tools-tab"
                className={classnames({ active: activeTab === "tools" })}
                onClick={() => {
                  toggleTab("tools", "/central-atendimento");
                }}
              >
                <i className="ri-tools-line"></i>
              </NavLink>
            </NavItem>
            <UncontrolledTooltip target="tools" placement="right">
              Configurações
            </UncontrolledTooltip> */}

            <NavItem
              id="reports"
              style={{
                display: props.user?.id_permissao === 1 ? "block" : "none",
              }}
            >
              <NavLink
                id="pills-reports-tab"
                className={classnames({ active: activeTab === "reports" })}
                onClick={() => {
                  toggleTab("reports", "/reports");
                }}
              >
                <Unicons.UilChartGrowth />
              </NavLink>
            </NavItem>
            <UncontrolledTooltip target="reports" placement="right">
              Relatórios
            </UncontrolledTooltip>
            {/* 
            {((props.user?.id_permissao === 1 &&
              props.enterprise?.has_bot === 1) ||
              [12276, 10929, 5, 20195, 14849, 20182].includes(
                +props.user?.id_empresa
              )) && (
              <> */}
            <NavItem id="configs">
              <NavLink
                id="pills-configs-tab"
                className={classnames({ active: activeTab === "configs" })}
                onClick={() => {
                  toggleTab("configs", "/configs/call-center/channels");
                  // toggleTab('configs', '/configs/account/users')
                }}
              >
                <Unicons.UilSetting />
              </NavLink>
            </NavItem>
            <UncontrolledTooltip target="configs" placement="right">
              Configurações
            </UncontrolledTooltip>

            <NavItem
              id="back"
              onClick={() => {
                setShowBackLogoutModal(!showBackLogoutModal);
              }}
            >
              <Unicons.UilSignout />
            </NavItem>
            <UncontrolledTooltip target="back" placement="right">
              Voltar para o CRM
            </UncontrolledTooltip>

            <Dropdown
              nav
              isOpen={dropdownOpenMobile}
              toggle={toggleMobile}
              className="profile-user-dropdown d-inline-block d-lg-none"
            >
              <DropdownToggle nav>
                {props.logoImg && (
                  <img src={props.logoImg} alt="logo" height="30" />
                )}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    toggleTab("profile");
                  }}
                >
                  Profile{" "}
                  <i className="ri-profile-line float-end text-muted"></i>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    toggleTab("settings");
                  }}
                >
                  Setting{" "}
                  <i className="ri-settings-3-line float-end text-muted"></i>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="/logout">
                  Log out{" "}
                  <i className="ri-logout-circle-r-line float-end text-muted"></i>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </div>
        {/* end side-menu nav */}

        {/* Side menu user */}
      </div>
      <AlertMessage />
      <ModalInfo />
      <ModalWhatsapp />
      <SyncConversations />
    </ErrorBoundary>
  );
}

const mapStatetoProps = (state) => {
  const { count_waiting_room } = state.Chat;
  const { enterprise } = state.Config;
  const {
    emailSocketStart,
    active_email_id,
    emails,
    emailAccess,
    emailConfig,
  } = state.Email;
  const { user } = state.Auth;
  const { wsCentral } = state.WebService;
  return {
    user,
    emails,
    wsCentral,
    enterprise,
    emailConfig,
    emailAccess,
    active_email_id,
    emailSocketStart,
    count_waiting_room,
    ...state.Layout,
  };
};

export default connect(mapStatetoProps, {
  setLogo,
  setNewEmail,
  setActiveTab,
  setChatInit,
  setNewEmails,
  openModalInfo,
  setStartSocket,
  getReportHistory,
  setStartEmailSocket,
  requestEmailList,
  setAlertMessage,
  setQueryParamsURL,
  requestValidCode,
  setBootstrap,
  setEmailSocket,
  setCentralSocket,
  openModalTestMode,
  requestEmailConfig,
  requestConfigCentral,
  setEmailStatusUpdated,
  requestCheckEmailLead,
  removeEmailConversation,
  fitchWaitingRoomChatsInDb,
  fitchMyChatsInDb,
  fitchChatsInDb,
  setEmailLeadUpdated,
  loginUserSuccess,
  setSidebarFixed,
  setCentralLoaded,
  removeWebsocket,
  requestProcessos,
  removeChats,
  addMessageWarning,
  setStateChange,
  setChartsCarfsTop,
  setChartFonte,
  setTableDesempenho,
  setChartGraph,
  removeChat,
  removeMyChat,
  removeWaitingRoomChat,
  setOnNewChatEvent,
  setOnConversationUpdateInDatabase,
  setCentralAlert,
})(LeftSidebarMenu);
