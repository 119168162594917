import { current, produce } from "immer";
import {
  SET_ACTIVE_TAB,
  FILTER_TAB_CHAT,
  OPEN_USER_PROFILE_SIDEBAR,
  CLOSE_USER_PROFILE_SIDEBAR,
  OPEN_USER_PROFILE_SIDEBAR_TASKS,
  CLOSE_USER_PROFILE_SIDEBAR_TASKS,
  OPEN_USER_PROFILE_SIDEBAR_SCHEDULE,
  CLOSE_USER_PROFILE_SIDEBAR_SCHEDULE,
  SET_CONVERSATION_NAME_IN_OPEN_CHAT,
  SET_HEIGHT_CHAT_CONVERSATION_PX,
  OPEN_MODAL_WHATSAPP,
  CLOSE_MODAL_WHATSAPP,
  LOADING_FILE,
  CURRENT_MESSAGE_ID_DOWNLOADING,
  SERVER_IS_CONNECTED,
  SET_STATUS_WS,
  ADD_QRCODE,
  QRCODE_ERROR,
  QRCODE_LOADING,
  OPEN_MODAL_PREVIEW_FILE,
  CLOSE_MODAL_PREVIEW_FILE,
  OPEN_MODAL_RECONNECT,
  CLOSE_MODAL_RECONNECT,
  OPEN_MODAL_USE_HERE,
  CLOSE_MODAL_USE_HERE,
  CONNECTION_WS_OPEN,
  CONNECTION_WS_CLOSED,
  OPEN_MODAL_CONFLIT,
  CLOSE_MODAL_CONFLIT,
  OPEN_MODAL_MESSENGER,
  CLOSE_MODAL_MESSENGER,
  LOADING_MESSENGER,
  HAS_24H_MESSENGER,
  MESSENGER_TYPE,
  ADD_FILTER,
  FILTERING,
  ADD_PAGINATION,
  LOADING_CHAT_ID_DB,
  ENABLE_NOTIFICATION,
  ADD_QUERY_FILTER,
  LOADING_REQUEST,
  OPEN_MODAL_END_CONVERSATION,
  CLOSE_MODAL_END_CONVERSATION,
  OPEN_MODAL_DELETE_MESSEGE,
  CLOSE_MODAL_DELETE_MESSEGE,
  SET_ALERT_MESSAGE,
  OPEN_MODAL_INFO,
  CLOSE_MODAL_INFO,
  SIDEBAR_FIXED,
  SCROLL_TO_BOTTOM,
  SET_LIGHTBOX_IMAGE,
  OPEN_MODAL_END_CONVERSATION_BOT,
  CLOSE_MODAL_END_CONVERSATION_BOT,
  OPEN_MODAL_DEVICE_NOT_CONNECTED,
  CLOSE_MODAL_DEVICE_NOT_CONNECTED,
  OPEN_MODAL_LOAD_ALL_MESSAGES,
  CLOSE_MODAL_LOAD_ALL_MESSAGES,
  ADD_PAGINATION_TABS,
  CLEAR_FILTER,
  SET_IS_OPEN_MORE_MENU,
  SET_DISCONNECT,
  OPEN_MODAL_DROPZONE,
  CLOSE_MODAL_DROPZONE,
  SET_TEMPLETE_TYPE,
  SET_TEMPLETE_MESSAGES,
  SET_SELECTED_TEMPLETE_TYPE,
  SET_CHARTS_CARDS_TOP,
  SET_CHART_FONTE,
  SET_CHART_GRAPH,
  SET_TABLE_DESEMPENHO,
  SET_REPORT_HISTORY,
  SET_REPORT_WEBSOCKET_START,
  SET_REPORT_DATE_INIT,
  SET_REPORT_DATE_ENDEND,
  SET_REPORT_DATE,
  OPEN_LOADING_ANEXO,
  CLOSE_LOADING_ANEXO,
  SET_WS_FINISHED_LOADING,
  OPEN_MODAL_WIDGET,
  CLOSE_MODAL_WIDGET,
  OPEN_MODAL_INSTAGRAM,
  CLOSE_MODAL_INSTAGRAM,
  SET_QUERY_PARAMS_URL,
  LOADING_CONVERSATION,
  SENDING_MESSAGE,
  SET_ANSWER_TYPE,
  SET_BOOTSTRAP,
  SET_CHAT_BOT_FLOW_NODES,
  SET_CHAT_BOT_FLOW_EDGES,
  SET_CHAT_BOT_FLOW_DELETED_NODES,
  SET_LOGO,
  OPEN_MODAL_TEST_MODE,
  START_MODAL_TEST_MODE,
  FINISH_MODAL_TEST_MODE,
  CLOSE_MODAL_TEST_MODE,
  OPEN_MODAL_EMAIL_EDITOR,
  CLOSE_MODAL_EMAIL_EDITOR,
  SET_CHAT_BOT_FLOW_FILES,
  CENTRAL_LOADED,
  SET_MODAL_SYNC_CONVERSATIONS,
  ACTIVEATE_BATCH_ACTION,
  UPDATE_FILTER,
  MODAL_LOADING_REQUEST,
  OPEN_MODAL_CONFLIT_NEW_DEVICE_CONNECTED,
  CLOSE_MODAL_CONFLIT_NEW_DEVICE_CONNECTED,
  SET_CENTRAL_ALERT,
} from "./constants";

const FILTER_ACTIVES_INIT = {
  origin: {
    whatsapp: "",
    instagram: "",
    messenger: "",
    jivo: "",
    telegram: "",
    widget: "",
  },
  attendants: {
    active: false,
    value: "",
  },
  team: {
    active: false,
    value: "",
  },
  linha: {
    active: false,
    value: "",
  },
  isOpen: {
    active: true,
    value: true,
  },
  dateInit: {
    active: false,
    value: null,
  },
  dateEnd: {
    active: false,
    value: null,
  },
  tags: [],
  unanswered: null,
  name: null,
};

const CHAT_BOT_FLOW_INIT = {
  nodes: [],
  edges: [],
  deletedNodes: [],
  files: [],
  flowStyles: {
    border: "1px black solid",
    "border-radius": "5px",
    height: "80%",
  },
};

const REPORTS_INIT = {
  dateInit: null,
  dateEndend: new Date(),
  socketStart: false,
  histories: {
    total: 0,
    page: 1,
    perPage: 30,
    lastPage: 1,
    data: [],
  },
  chartsCardsTop: {
    newAttendances: 0,
    averageResponse: 0,
    acquiredAttendances: 0,
    leadReceveid: 0,
    finalized: 0,
    countBot: 0,
  },
  chartFonte: {
    messenger: 0,
    whatsapp: 0,
    instagram: 0,
    widget: 0,
  },
  chartGraph: {
    labels: [],
    datasets: [
      {
        label: "Performance",
        data: [],
      },
    ],
  },
  tableDesempenho: {
    atendentes: [],
  },
};

const INIT_STATE = {
  bootstrap: true,
  activeTab: "chat",
  answerType: "response",
  logoImg: "",
  isOpenMoreMenu: "",
  filterTabChat: {
    allChatsTab: false,
    myConversationsTab: true,
    waitingRoomTab: false,
  },
  activeChatsTab: "my_chats",
  paginationsTabChat: {
    allChats: {
      pagination: {
        total: 0,
      },
      hasMoreChat: false,
    },
    myConversations: {
      pagination: {
        total: 0,
      },
      hasMoreChat: false,
    },
    waitingRoom: {
      pagination: {
        total: 0,
      },
      hasMoreChat: false,
    },
  },
  templatesTypes: [],
  templatesMessages: {},
  selectedTemplateType: "",
  sidebarFixed: false,
  userSidebar: false,
  userSidebarTasks: false,
  userSidebarSchedule: false,
  modalWhatsapp: false,
  modalMessenger: false,
  modalInstagram: false,
  modalWidget: false,
  modalReconnect: false,
  modalUseHere: false,
  modalConflit: false,
  modalDropzone: false,
  modalEmailEditor: false,
  modalDeviceNotConnected: false,
  modalConflictNewDeviceConnected: false,
  modalEndConversation: false,
  modalEndConversationBot: false,
  modalDeleteMessege: false,
  modalLoadAllMessage: false,
  modelTestMode: false,
  modalSyncConversations: false,
  testMode: false,
  loadingAnexo: false,
  messegeToDelete: null,
  idIntegracaoConflit: "",
  idIntegracaoDisconnect: "",
  idEmpresaDisconnect: "",
  statusWs: "Carregando",
  wsFinishedLoading: false,
  queryParamsUrl: {},
  qrCode: "",
  qrCodeErro: false,
  centralLoaded: false,
  modalLoadingRequest: false,
  loadingMessenger: false,
  loadingWidget: false,
  loadingQrCode: false,
  loadingFile: false,
  loadingConversation: false,
  loadingChatInDb: false,
  loadingRequest: false,
  sendingMessage: false,
  serverIsConnected: true,
  closedConnection: false,
  currentMessageIdDownloading: null,
  conversationName: "Doris Brown",
  lightboxImage: false,
  modalPreviewFile: false,
  filePreview: null,
  has24hMessage: false,
  heightChatConversationPx: 0,
  messengerType: "",
  notification_enabled: true,
  activeFilters: FILTER_ACTIVES_INIT,
  chatBotFlow: CHAT_BOT_FLOW_INIT,
  report: REPORTS_INIT,
  query_filter: "",
  filtering: false,
  pagination: null,
  hasMoreChat: true,
  scrollToBottom: false,
  activeateBatchActive: false,
  centralAlert: { open: false, message: "" },
};

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SERVER_IS_CONNECTED:
      return {
        ...state,
        qrCode: "",
        qrCodeErro: false,
        loadingQrCode: false,
        serverIsConnected: true,
        modalUseHere: false,
        activeTab: "chat",
      };
    case SET_BOOTSTRAP: {
      return {
        ...state,
        bootstrap: action.payload,
      };
    }

    case SET_LOGO: {
      return {
        ...state,
        logoImg: action.payload,
      };
    }
    case CONNECTION_WS_OPEN:
      return {
        ...state,
        closedConnection: false,
      };
    case SET_WS_FINISHED_LOADING:
      return {
        ...state,
        wsFinishedLoading: action.payload,
      };
    case CONNECTION_WS_CLOSED:
      return {
        ...state,
        closedConnection: true,
      };
    case SET_STATUS_WS:
      return {
        ...state,
        statusWs: action.payload,
      };

    case ADD_QRCODE:
      return {
        ...state,
        qrCode: action.payload,
        loadingQrCode: false,
      };

    case QRCODE_LOADING:
      return {
        ...state,
        loadingQrCode: true,
        qrCodeErro: false,
      };

    case QRCODE_ERROR:
      return {
        ...state,
        qrCode: "",
        qrCodeErro: true,
        loadingQrCode: false,
      };

    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case SET_IS_OPEN_MORE_MENU:
      return {
        ...state,
        isOpenMoreMenu: action.payload,
      };
    case SET_REPORT_HISTORY:
      return produce(state, (draft) => {
        draft.report.histories = action.payload;
      });
    case SET_REPORT_WEBSOCKET_START:
      return produce(state, (draft) => {
        draft.report.socketStart = action.payload;
      });
    case SET_CHARTS_CARDS_TOP:
      return produce(state, (draft) => {
        draft.report.chartsCardsTop = action.payload;
      });
    case SET_CHART_FONTE:
      return produce(state, (draft) => {
        draft.report.chartFonte = action.payload;
      });
    case SET_CHART_GRAPH:
      return produce(state, (draft) => {
        draft.report.chartGraph = action.payload;
      });
    case SET_TABLE_DESEMPENHO:
      return produce(state, (draft) => {
        draft.report.tableDesempenho = action.payload;
      });
    case SET_REPORT_DATE_INIT:
      return produce(state, (draft) => {
        draft.report.dateInit = action.payload;
      });
    case SET_REPORT_DATE_ENDEND:
      return produce(state, (draft) => {
        draft.report.dateEndend = action.payload;
      });
    case SET_REPORT_DATE:
      return produce(state, (draft) => {
        draft.report.dateInit = action.payload.dateInit;
        draft.report.dateEndend = action.payload.dateEndend;
      });
    case FILTER_TAB_CHAT:
      return produce(state, (draft) => {
        switch (action.payload.name) {
          case "waitingRoomTab":
            draft.filterTabChat.waitingRoomTab = true;
            draft.filterTabChat.myConversationsTab = false;
            draft.filterTabChat.allChatsTab = false;
            draft.activeChatsTab = "waiting_room";
            break;
          case "myConversationsTab":
            draft.filterTabChat.waitingRoomTab = false;
            draft.filterTabChat.myConversationsTab = true;
            draft.filterTabChat.allChatsTab = false;
            draft.activeChatsTab = "my_chats";
            break;
          case "allChatsTab":
            draft.filterTabChat.waitingRoomTab = false;
            draft.filterTabChat.myConversationsTab = false;
            draft.filterTabChat.allChatsTab = true;
            draft.activeChatsTab = "users";
            break;
          default:
            break;
        }
      });

    case LOADING_FILE:
      return {
        ...state,
        loadingFile: action.payload,
      };

    case SET_CHAT_BOT_FLOW_NODES:
      return produce(state, (draft) => {
        draft.chatBotFlow.nodes = action.payload;
      });

    case SET_CHAT_BOT_FLOW_DELETED_NODES:
      return produce(state, (draft) => {
        draft.chatBotFlow.deletedNodes = action.payload;
      });

    case SET_CHAT_BOT_FLOW_FILES:
      return produce(state, (draft) => {
        draft.chatBotFlow.files = action.payload;
      });

    case SET_CHAT_BOT_FLOW_EDGES:
      return produce(state, (draft) => {
        draft.chatBotFlow.edges = action.payload;
      });

    case LOADING_CONVERSATION:
      return {
        ...state,
        loadingConversation: action.payload,
      };

    case LOADING_CHAT_ID_DB:
      return {
        ...state,
        loadingChatInDb: action.payload,
      };

    case LOADING_MESSENGER:
      return {
        ...state,
        loadingMessenger: action.payload,
      };

    case LOADING_REQUEST:
      return {
        ...state,
        loadingRequest: action.payload,
      };

    case CENTRAL_LOADED:
      return produce(state, (draft) => {
        draft.centralLoaded = action.payload;
      });
    case SENDING_MESSAGE:
      return {
        ...state,
        sendingMessage: action.payload,
      };

    case SET_ANSWER_TYPE: {
      return produce(state, (draft) => {
        draft.answerType = action.payload;
      });
    }

    case CURRENT_MESSAGE_ID_DOWNLOADING:
      return {
        ...state,
        currentMessageIdDownloading: action.payload,
      };

    case MODAL_LOADING_REQUEST:
      return {
        ...state,
        modalLoadingRequest: action.payload,
      };

    case OPEN_MODAL_WHATSAPP:
      return {
        ...state,
        qrCode: "",
        qrCodeErro: false,
        loadingQrCode: false,
        modalWhatsapp: true,
      };
    case OPEN_MODAL_MESSENGER:
      return {
        ...state,
        loadingMessenger: false,
        modalMessenger: true,
      };
    case CLOSE_MODAL_MESSENGER:
      return {
        ...state,
        loadingMessenger: false,
        modalMessenger: false,
      };

    case OPEN_MODAL_INSTAGRAM:
      return {
        ...state,
        modalInstagram: true,
      };
    case CLOSE_MODAL_INSTAGRAM:
      return {
        ...state,
        modalInstagram: false,
      };
    case OPEN_MODAL_WIDGET:
      return {
        ...state,
        loadingWidget: false,
        modalWidget: true,
      };
    case CLOSE_MODAL_WIDGET:
      return {
        ...state,
        loadingWidget: false,
        modalWidget: false,
      };
    case CLOSE_MODAL_WHATSAPP:
      return {
        ...state,
        qrCode: "",
        qrCodeErro: false,
        loadingQrCode: false,
        modalWhatsapp: false,
        idEmpresaDisconnect: "",
        idIntegracaoDisconnect: "",
      };
    case OPEN_MODAL_TEST_MODE:
      return {
        ...state,
        modelTestMode: true,
      };

    case CLOSE_MODAL_TEST_MODE:
      return {
        ...state,
        modelTestMode: false,
      };

    case START_MODAL_TEST_MODE:
      return {
        ...state,
        testMode: true,
      };

    case FINISH_MODAL_TEST_MODE:
      return {
        ...state,
        testMode: false,
      };
    case OPEN_MODAL_RECONNECT:
      return {
        ...state,
        modalReconnect: true,
      };
    case CLOSE_MODAL_RECONNECT:
      return {
        ...state,
        modalReconnect: false,
      };
    case OPEN_MODAL_USE_HERE:
      return {
        ...state,
        modalUseHere: true,
      };
    case CLOSE_MODAL_USE_HERE:
      return {
        ...state,
        modalUseHere: false,
      };
    case OPEN_MODAL_CONFLIT:
      return {
        ...state,
        idIntegracaoConflit: action.payload,
        modalConflit: true,
      };
    case CLOSE_MODAL_CONFLIT:
      return {
        ...state,
        idIntegracaoConflit: "",
        modalConflit: false,
      };

    case OPEN_MODAL_DEVICE_NOT_CONNECTED:
      return {
        ...state,
        modalDeviceNotConnected: true,
      };
    case CLOSE_MODAL_DEVICE_NOT_CONNECTED:
      return {
        ...state,
        modalDeviceNotConnected: false,
      };
    case OPEN_MODAL_CONFLIT_NEW_DEVICE_CONNECTED:
      return {
        ...state,
        modalConflictNewDeviceConnected: true,
      };
    case CLOSE_MODAL_CONFLIT_NEW_DEVICE_CONNECTED:
      return {
        ...state,
        modalConflictNewDeviceConnected: false,
      };
    case OPEN_MODAL_PREVIEW_FILE:
      return {
        ...state,
        filePreview: action.payload,
        modalPreviewFile: true,
      };
    case CLOSE_MODAL_PREVIEW_FILE:
      return {
        ...state,
        modalPreviewFile: false,
        filePreview: null,
      };

    case SIDEBAR_FIXED:
      return {
        ...state,
        sidebarFixed: action.payload,
      };

    case OPEN_USER_PROFILE_SIDEBAR:
      return {
        ...state,
        userSidebar: true,
        userSidebarTasks: false,
        userSidebarSchedule: false,
      };

    case CLOSE_USER_PROFILE_SIDEBAR:
      return {
        ...state,
        userSidebar: false,
      };

    case OPEN_USER_PROFILE_SIDEBAR_TASKS:
      return {
        ...state,
        userSidebar: false,
        userSidebarTasks: true,
        userSidebarSchedule: false,
      };

    case CLOSE_USER_PROFILE_SIDEBAR_TASKS:
      return {
        ...state,
        userSidebarTasks: false,
      };

    case OPEN_USER_PROFILE_SIDEBAR_SCHEDULE:
      return {
        ...state,
        userSidebar: false,
        userSidebarTasks: false,
        userSidebarSchedule: true,
      };

    case CLOSE_USER_PROFILE_SIDEBAR_SCHEDULE:
      return {
        ...state,
        userSidebarSchedule: false,
      };
    case OPEN_MODAL_END_CONVERSATION:
      return {
        ...state,
        modalEndConversation: true,
      };

    case CLOSE_MODAL_END_CONVERSATION:
      return {
        ...state,
        modalEndConversation: false,
      };
    case OPEN_MODAL_DROPZONE:
      return {
        ...state,
        modalDropzone: true,
        loadingAnexo: false,
      };

    case CLOSE_MODAL_DROPZONE:
      return {
        ...state,
        modalDropzone: false,
      };

    case OPEN_MODAL_EMAIL_EDITOR:
      return {
        ...state,
        modalEmailEditor: true,
      };

    case CLOSE_MODAL_EMAIL_EDITOR:
      return {
        ...state,
        modalEmailEditor: false,
      };
    case OPEN_MODAL_END_CONVERSATION_BOT:
      return {
        ...state,
        modalEndConversationBot: true,
      };

    case CLOSE_MODAL_END_CONVERSATION_BOT:
      return {
        ...state,
        modalEndConversationBot: false,
      };
    case OPEN_MODAL_DELETE_MESSEGE:
      return {
        ...state,
        modalDeleteMessege: true,
        messegeToDelete: action.payload,
      };

    case CLOSE_MODAL_DELETE_MESSEGE:
      return {
        ...state,
        modalDeleteMessege: false,
        messegeToDelete: null,
      };

    case OPEN_MODAL_INFO:
      return {
        ...state,
        modalInfo: true,
        modalInfoData: {
          type: action.payload.type,
          title: action.payload.title,
          msg: action.payload.msg,
        },
      };
    case OPEN_LOADING_ANEXO:
      return {
        ...state,
        loadingAnexo: true,
      };
    case CLOSE_MODAL_INFO:
      return {
        ...state,
        modalInfo: false,
        modalInfoData: null,
      };
    case CLOSE_LOADING_ANEXO:
      return {
        ...state,
        loadingAnexo: false,
      };
    case OPEN_MODAL_LOAD_ALL_MESSAGES:
      return {
        ...state,
        modalLoadAllMessage: true,
      };

    case CLOSE_MODAL_LOAD_ALL_MESSAGES:
      return {
        ...state,
        modalLoadAllMessage: false,
      };

    case SET_CONVERSATION_NAME_IN_OPEN_CHAT:
      return {
        ...state,
        conversationName: action.payload,
      };

    case SET_LIGHTBOX_IMAGE:
      return {
        ...state,
        lightboxImage: action.payload,
      };
    case HAS_24H_MESSENGER:
      return {
        ...state,
        has24hMessage: action.payload,
      };
    case SET_HEIGHT_CHAT_CONVERSATION_PX:
      return {
        ...state,
        heightChatConversationPx: action.payload,
      };

    case SET_QUERY_PARAMS_URL:
      return {
        ...state,
        queryParamsUrl: action.payload,
      };
    case MESSENGER_TYPE:
      return {
        ...state,
        messengerType: action.payload,
      };
    case ADD_FILTER:
      return produce(state, (draft) => {
        const filter_type = action.payload.filter_type;
        const name = action.payload.name;
        const value = action.payload.value;

        if (value === null) {
          if (filter_type === "unanswered") {
            draft.activeFilters.unanswered = value;
          } else if (filter_type === "isOpen") {
            draft.activeFilters[filter_type].active = true;
            draft.activeFilters[filter_type].value = value;
          } else {
            draft.activeFilters[filter_type].active = false;
            draft.activeFilters[filter_type].value = "";
          }
        } else if (filter_type === "origin") {
          draft.activeFilters.origin[name] = value;
        } else if (filter_type === "unanswered") {
          draft.activeFilters.unanswered = value;
        } else if (filter_type === "tags") {
          const _tags = value.map((tag) => parseInt(tag.value));
          draft.activeFilters.tags = _tags;
        } else if (filter_type === "attendants") {
          draft.activeFilters.attendants = value.map((a) => parseInt(a.value));
        } else if (filter_type === "search") {
          draft.activeFilters.name = value;
        } else {
          draft.activeFilters[filter_type].active = true;
          draft.activeFilters[filter_type].value = value;
        }
      });
    case UPDATE_FILTER:
      return produce(state, (draft) => {
        const filter_type = action.payload.filter_type;
        const value = action.payload.value;
        const active = action.payload.active;

        console.log("UPDATE_FILTER", {
          filter_type,
          value,
          active,
        });

        if (filter_type === "attendants") {
          draft.activeFilters[filter_type] = value;
        } else {
          draft.activeFilters[filter_type].active = active;
          draft.activeFilters[filter_type].value = value;
        }
      });
    case CLEAR_FILTER:
      return produce(state, (draft) => {
        draft.activeFilters = {
          ...FILTER_ACTIVES_INIT,
          name: "",
        };
        draft.query_filter = "";
      });
    case ADD_QUERY_FILTER:
      return produce(state, (draft) => {
        draft.query_filter = action.payload;
      });

    case FILTERING:
      return produce(state, (draft) => {
        draft.filtering = action.payload;
      });

    case ADD_PAGINATION:
      return produce(state, (draft) => {
        const pagination = action.payload;
        if (pagination.page < pagination.lastPage) {
          draft.hasMoreChat = true;
        } else {
          draft.hasMoreChat = false;
        }
        draft.pagination = action.payload;
      });

    case ADD_PAGINATION_TABS:
      return produce(state, (draft) => {
        const pagination = action.payload.data;
        let hasMoreChat = false;
        if (pagination.page < pagination.lastPage) {
          hasMoreChat = true;
        }
        switch (action.payload.name) {
          case "waitingRoom":
            draft.paginationsTabChat.waitingRoom = { pagination, hasMoreChat };
            break;
          case "myConversations":
            draft.paginationsTabChat.myConversations = {
              pagination,
              hasMoreChat,
            };
            break;
          case "allChats":
            draft.paginationsTabChat.allChats = { pagination, hasMoreChat };
            break;
          default:
            break;
        }
      });

    case ENABLE_NOTIFICATION:
      return produce(state, (draft) => {
        draft.notification_enabled = action.payload;
        localStorage.setItem("notification_enabled", action.payload);
      });

    case SET_ALERT_MESSAGE:
      return {
        ...state,
        alertMessage: action.payload,
      };
    case SCROLL_TO_BOTTOM:
      return produce(state, (draft) => {
        draft.scrollToBottom = action.payload;
      });
    case SET_DISCONNECT:
      return produce(state, (draft) => {
        draft.idIntegracaoDisconnect = action.payload.idIntegracaoDisconnect;
        draft.idEmpresaDisconnect = action.payload.idEmpresaDisconnect;
      });

    case SET_TEMPLETE_TYPE:
      return produce(state, (draft) => {
        const hasTemplateType = draft.templatesTypes.find(
          (t) => t.value === action.payload.value
        );

        if (!hasTemplateType) {
          draft.templatesTypes.push(action.payload);
        }
      });
    case SET_TEMPLETE_MESSAGES:
      return produce(state, (draft) => {
        draft.templatesMessages[action.payload.type] = action.payload.data;
      });
    case SET_SELECTED_TEMPLETE_TYPE:
      return produce(state, (draft) => {
        draft.selectedTemplateType = action.payload;
      });
    case SET_MODAL_SYNC_CONVERSATIONS:
      return produce(state, (draft) => {
        draft.modalSyncConversations = action.payload;
      });
    case ACTIVEATE_BATCH_ACTION:
      return produce(state, (draft) => {
        draft.activeateBatchActive = action.payload;
      });
    case SET_CENTRAL_ALERT:
      return produce(state, (draft) => {
        draft.centralAlert = {
          open: action.payload.open,
          message: action.payload.message,
        };
      });
    default:
      return state;
  }
};

export default Layout;
