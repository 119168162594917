import { bool } from "yup";
import {
  REQUEST_EMAILS_LIST,
  SET_ACTIVE_EMAIL_ID,
  SET_ALL_EMAILS,
  SET_TYPE_ANSWER_EMAIL,
  SET_CURRENT_CONTENT_EMAIL_HTML,
  SET_EMAIL_FORM,
  SET_NEW_EMAIL,
  REQUEST_SEND_EMAIL,
  SET_SENDING_EMAIL,
  SET_NEW_REPLY_EMAIL,
  SET_EMAIL_WEBSOCKET_START,
  FILTER_TAB_EMAIL,
  REQUEST_EMAILS_CONFIG,
  SET_EMAIL_CONFIG,
  SET_EMAIL_UPDATE_STATUS,
  SET_NEW_EMAILS,
  SET_EMAIL_UPDATE_LEAD,
  ACTIVE_EMAIL_INBOX,
  ACTIVE_OPTIONS_EMAIL_PROVIDER,
  SET_EMAIL_ACCESS,
  REMOVE_EMAIL_CONVERSATION,
} from "./constants";

export const setAllEmails = (emails) => ({
  type: SET_ALL_EMAILS,
  payload: emails,
});

export const setActiveEmailId = (id) => ({
  type: SET_ACTIVE_EMAIL_ID,
  payload: id,
});

export const requestEmailConfig = () => ({
  type: REQUEST_EMAILS_CONFIG,
});

export const requestEmailList = () => ({
  type: REQUEST_EMAILS_LIST,
});

export const requestSendEmail = () => ({
  type: REQUEST_SEND_EMAIL,
});

export const setEmailConfig = (emailConfig) => ({
  type: SET_EMAIL_CONFIG,
  payload: emailConfig,
});

export const setSendingEmail = (bool) => ({
  type: SET_SENDING_EMAIL,
  payload: bool,
});

export const setNewEmail = ({ emailConversation, createdMail }) => ({
  type: SET_NEW_EMAIL,
  payload: { emailConversation, createdMail },
});

export const setNewEmails = (emails) => ({
  type: SET_NEW_EMAILS,
  payload: emails,
});

export const setEmailStatusUpdated = (email) => ({
  type: SET_EMAIL_UPDATE_STATUS,
  payload: email,
});

export const setEmailLeadUpdated = (emailData) => ({
  type: SET_EMAIL_UPDATE_LEAD,
  payload: emailData,
});
export const setNewReplyEmail = (email) => ({
  type: SET_NEW_REPLY_EMAIL,
  payload: email,
});

export const setTypeAnswerEmail = (type) => ({
  type: SET_TYPE_ANSWER_EMAIL,
  payload: type,
});

export const setCurrentContentEmailHTML = (content) => ({
  type: SET_CURRENT_CONTENT_EMAIL_HTML,
  payload: content,
});

export const setEmailForm = ({
  to,
  cc,
  cco,
  subject,
  reply_to,
  email_conversation_id,
  oportunity_id,
}) => ({
  type: SET_EMAIL_FORM,
  payload: {
    to,
    cc,
    cco,
    subject,
    reply_to,
    email_conversation_id,
    oportunity_id,
  },
});

export const setStartEmailSocket = (start) => ({
  type: SET_EMAIL_WEBSOCKET_START,
  payload: start,
});

export const setFilterTabEmail = (name, bool) => ({
  type: FILTER_TAB_EMAIL,
  payload: {
    name,
    bool,
  },
});

export const setActiveEmailInbox = (email) => ({
  type: ACTIVE_EMAIL_INBOX,
  payload: email,
});

export const setOptionsEmailProvider = (emailProvider) => ({
  type: ACTIVE_OPTIONS_EMAIL_PROVIDER,
  payload: emailProvider,
});

export const setEmailAccess = (bool) => ({
  type: SET_EMAIL_ACCESS,
  payload: bool,
});

export const removeEmailConversation = (emailConversation) => ({
  type: REMOVE_EMAIL_CONVERSATION,
  payload: emailConversation,
});
