import Bugsnag from "@bugsnag/js";
import { getUnixTime } from "date-fns";
import { setNewChat } from "../redux/actions";
import store from "../redux/store";

const checkUserRestrictions = (typeChat, chat) => {
  const state = store.getState();

  const user = state.Auth.user;
  const { config } = state.Config;

  let canSee = false;

  /*
  Permições
  1 - Global
  2 - Restrito
  3 - Equipe
  */
  switch (typeChat) {
    case "MY_CHATS":
      if (chat.idResponsavel === user.id_usuario) {
        canSee = true;
      }
      break;
    case "WAITING_ROOM":
      switch (user.id_permissao) {
        case 1:
          if (chat.idResponsavel === null) {
            canSee = true;
          }
          break;
        case 2:
          if (
            config.exibir_conversas_nao_atribuidas_todos === "S" &&
            chat.idResponsavel === null
          ) {
            canSee = true;
          }
          break;
        case 3:
          if (
            config.exibir_conversas_nao_atribuidas_todos === "S" &&
            chat.idResponsavel === null &&
            chat.idEquipe === null
          ) {
            canSee = true;
          } else if (
            chat.idResponsavel === null &&
            checkTeamRestrictions(chat)
          ) {
            canSee = true;
          }
          break;
        default:
          canSee = false;
          break;
      }
      break;
    case "ALL":
    case "USERS":
      switch (user.id_permissao) {
        case 1:
          canSee = true;
          break;
        case 2:
          if (
            config.exibir_conversas_nao_atribuidas_todos === "S" &&
            (chat.idResponsavel === null ||
              chat.idResponsavel === user.id_usuario ||
              chat.id.includes("@g.us"))
          ) {
            canSee = true;
          } else if (
            chat.idResponsavel === user.id_usuario ||
            chat.id.includes("@g.us")
          ) {
            canSee = true;
          }
          break;
        case 3:
          if (
            config.exibir_conversas_nao_atribuidas_todos === "S" &&
            (chat.idResponsavel === null ||
              chat.idResponsavel === user.id_usuario ||
              chat.id.includes("@g.us") ||
              checkTeamRestrictions(chat))
          ) {
            canSee = true;
          } else if (
            chat.idResponsavel === user.id_usuario ||
            chat.id.includes("@g.us") ||
            checkTeamRestrictions(chat)
          ) {
            canSee = true;
          }
          break;
        default:
          canSee = false;
          break;
      }
      break;
    default:
      canSee = false;
      break;
  }

  if (chat.tipoMensageiro === "whatsapp") {
    if (chat.id.includes("@g.us")) {
      canSee = checkWhatsappTeam(chat);
    } else if (checkWhatsappAttendants(chat)) {
      canSee = true;
    }
  }

  return canSee;
};

const checkTeamRestrictions = (chat) => {
  const state = store.getState();

  const user = state.Auth.user;
  const { teams } = state.Config;

  const hasTeam = teams.find((team) => team.id_equipe === chat.idEquipe);

  if (hasTeam) {
    const hasAttendantTeam = hasTeam.users.find(
      (attendant) => attendant.id_usuario === user.id_usuario
    );

    if (hasAttendantTeam) {
      return true;
    }
  }

  return false;
};

const checkWhatsappAttendants = (chat) => {
  const state = store.getState();

  const user = state.Auth.user;
  const { processos } = state.WebService;

  const whatsapp = processos.whatsapp;

  const whatsappProcess = whatsapp.find(
    (p) => p.id === chat.idIntegracao && p.phonenumber === chat.identifier
  );

  if (whatsappProcess && whatsappProcess.attendants) {
    const whatsappAttendasnt =
      whatsappProcess.attendants === ("" || "0")
        ? []
        : JSON.parse(whatsappProcess.attendants);
    const hasAttendant = whatsappAttendasnt.find(
      (attendant) => attendant.value === user.id_usuario
    );
    console.log("hasAttendant =>", hasAttendant);

    if (hasAttendant) {
      return true;
    }

    return false;
  }
};

const checkWhatsappTeam = (chat) => {
  const state = store.getState();

  const user = state.Auth.user;
  const { teams } = state.Config;
  const { processos } = state.WebService;

  const whatsapp = processos.whatsapp;

  if (user.id_permissao === 1) {
    return true;
  }

  const whatsappProcess = whatsapp.find(
    (p) => p.id === chat.idIntegracao && p.phonenumber === chat.identifier
  );

  if (
    whatsappProcess &&
    whatsappProcess.id_equipe &&
    whatsappProcess.id_equipe !== 0
  ) {
    const hasTeam = teams.find(
      (team) => team.id_equipe === whatsappProcess.id_equipe
    );

    if (hasTeam) {
      const hasAttendantTeam = hasTeam.users.find(
        (attendant) => attendant.id_usuario === user.id_usuario
      );

      if (hasAttendantTeam) {
        return true;
      }
    }

    return false;
  } else {
    return true;
  }
};

const checkFilterActives = (chat) => {
  if (!chat || !Object.values(chat)?.length > 0) {
    return [];
  }
  const state = store.getState();

  const { activeFilters, activeChatsTab } = state.Layout;

  const validations = [];

  try {
    if (activeFilters.name && activeFilters.name !== "") {
      const name = activeFilters.name.toLocaleLowerCase();
      let hasChat = false;

      if (chat.id.includes(name)) hasChat = true;
      if (chat.name?.toLocaleLowerCase().includes(name)) hasChat = true;

      validations.push(hasChat);
    }

    if (activeFilters?.linha.active) {
      validations.push(
        parseInt(activeFilters.linha.value) === parseInt(chat.idIntegracao)
      );
    }

    if (activeFilters?.attendants.length > 0) {
      const hasAttendant =
        activeFilters.attendants.find(
          (attendant) => chat.idResponsavel === parseInt(attendant)
        ) > 0;

      console.log("hasAttendant", hasAttendant);
      validations.push(hasAttendant > 0);
    }

    let values = [];
    if (
      activeFilters?.isOpen.active &&
      (activeFilters?.isOpen.value !== null ||
        activeFilters?.isOpen.value === "nl")
    ) {
      if (activeFilters?.isOpen.value && !chat.aberta) {
        values.push("11111111111");
        validations.push(false);
      } else if (!activeFilters?.isOpen.value && chat.aberta) {
        values.push("11111111111.0000000000");
        validations.push(false);
      } else if (activeFilters?.isOpen.value === "nl" && !chat.aberta) {
        validations.push(false);
      }
    }

    if (activeFilters?.unanswered !== null && activeFilters?.unanswered) {
      values.push("2222222222222");
      validations.push(chat.idResponsavel === null);
    }

    if (activeFilters?.unanswered !== null && !activeFilters?.unanswered) {
      values.push("3333333333333");
      validations.push(chat.idResponsavel !== null);
    }

    if (!activeFilters?.isOpen.active && activeFilters?.isOpen.value === null) {
      values.push("4444444444444");
      validations.push(chat.aberta);
    }

    if (activeFilters?.tags.length > 0) {
      values.push("5555555555555");
      validations.push(
        activeFilters.tags.find((tag) =>
          chat?.tags.find((tagChat) => tagChat === tag)
        ) > 0
      );
    }

    if (
      activeFilters?.dateInit.active &&
      activeFilters?.dateInit.value !== ""
    ) {
      values.push("66666666666");
      validations.push(
        chat.time_ultima_msg >=
          getUnixTime(new Date(`${activeFilters?.dateInit.value} 00:00:00`))
      );
    }

    if (activeFilters?.dateEnd.active && activeFilters?.dateEnd.value !== "") {
      values.push("777777777777");
      validations.push(
        chat.time_ultima_msg <=
          getUnixTime(new Date(`${activeFilters?.dateEnd.value} 23:59:59`))
      );
    }
  } catch (error) {
    Bugsnag.notify(error);
    console.error("ERRO checkFilterActives => ", {
      error,
      chat,
      activeFilters,
    });
  }

  if (validations.length === 0) {
    return true;
  }

  return validations.find((valid) => valid === false) === undefined;
};

const checkFilterTab = (chat) => {
  const state = store.getState();
  const user = state.Auth.user;
  const userConversations = [chat].filter(
    (c) =>
      checkUserRestrictions("MY_CHATS", c) ||
      checkUserRestrictions("WAITING_ROOM", c) ||
      checkUserRestrictions("USERS", c)
  );

  let isSuccess = false;

  if (user.id_permissao === 1) {
    isSuccess = true;
  } else {
    isSuccess = userConversations.length > 0;
  }

  let filterTabName = "allChatsTab";

  if (isSuccess) {
    if (userConversations.length > 0) {
      console.log("userConversations", userConversations);

      if (userConversations[0].idResponsavel === user.id_usuario) {
        store.dispatch(setNewChat("my_chats", chat, false));
        filterTabName = "myConversationsTab";
      }

      if (userConversations[0].idResponsavel === null) {
        store.dispatch(setNewChat("waiting_room", chat, false));
        filterTabName = "waitingRoomTab";
      }

      store.dispatch(setNewChat("users", chat, false));
    }
  }

  return {
    ...chat,
    filterTabName: filterTabName,
    isSuccess,
  };
};

export { checkUserRestrictions, checkFilterActives, checkFilterTab };
