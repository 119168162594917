import store from '../redux/store'

const getInfoResponsibleAndTeam = (chat) => {
    const state = store.getState()

    let responsibleName = null
    let teamName = null

    const { user } = state.Auth
    const { teams, attendants } = state.Config

    const hasTeam = teams.find((team) => team.id_equipe === chat.idEquipe)
    if (hasTeam) {
        const hasAttendantTeam = hasTeam.users.find(
        (attendant) => attendant.id_usuario === user.id_usuario,
        )

        if (hasAttendantTeam) {
          teamName = hasAttendantTeam.descricao
        }
    }

    const hasAttendant = attendants.find((attendant) => parseInt(attendant.id_usuario) === chat.idResponsavel)
    if (hasAttendant) {
      responsibleName = hasAttendant.nome
    }

    return {
      responsibleName,
      teamName
    }
}

export default getInfoResponsibleAndTeam