import { current, produce } from 'immer'

import {
  ADD_WEBSOCKET,
  ADD_CHANNEL,
  SET_CHANNEL_INDEX,
  REQUEST_PROCESSOS_SUCESS,
  REQUEST_PROCESSOS,
  PROCESS_TO_RECONNECT,
  REQUEST_DELETE_PROCESSOS_SUCESS,
  ADD_CONNECTION_STATUS,
  REMOVE_WEBSOCKET,
  ADD_SERVER,
  SET_PROCESSOS,
  SET_EMAIL_SOCKET,
  SET_CENTRAL_SOCKET,
  ADD_MESSAGE_WARNING,
  REMOVE_MESSAGE_WARNING,
} from './constants'
const INIT_STATE = {
  servers: [],
  websockets: [],
  wsChannel: [],
  wsEmail: null,
  wsCentral: null,
  channelIndex: null,
  connectionsStatus: [],
  messageWarning: [],
  new_request_processos: false,
  reconnected: false,
  processos: [],
  process_to_reconnect: null,
  loadingProcessos: false,
}

const WebService = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_SERVER:
      return produce(state, (draft) => {
        const id = action.payload.id
        const tipo_mensageiro = action.payload.tipo_mensageiro

        const servers = draft.servers

        if (servers.length > 0) {
          const wsIndex = draft.servers.findIndex((serverProcess) => {
            return (
              serverProcess.id === id &&
              serverProcess.tipo_mensageiro === tipo_mensageiro
            )
          })

          if (wsIndex === -1) {
            draft.servers.push(action.payload)
            console.log('Novo server process adicionado')
          } else {
            draft.servers[wsIndex] = {
              ...draft.servers[wsIndex],
              ...action.payload,
            }
            console.log('Server process atualizado', action.payload)
          }
        } else {
          draft.servers.push(action.payload)
          console.log('Novo server process adicionado')
        }
      })
    case ADD_WEBSOCKET:
      return produce(state, (draft) => {
        const id_integracao = action.payload.options.query.id_integracao
        const tipo_mensageiro = action.payload.options.query.tipo_mensageiro
        const id_empresa = action.payload.options.query.id_empresa

        console.log('ADD_WEBSOCKET => ', {
          id_integracao,
          tipo_mensageiro,
          id_empresa,
        })

        const wsIndex = draft.websockets.findIndex((socket) => {
          return (
            socket.options.query.id_integracao === +id_integracao &&
            socket.options.query.id_empresa  === +id_empresa &&
            socket.options.query.tipo_mensageiro === tipo_mensageiro
          )
        })

        if (wsIndex === -1) {
          draft.websockets.push(action.payload)
          console.log('Novo websocket adicionado')
        } else {
          draft.websockets[wsIndex] = action.payload
          console.log('Websocket atualizado', action.payload)
        }
      })
    case ADD_CHANNEL:
      return produce(state, (draft) => {
        const id_integracao = action.payload.id_integracao
        const tipo_mensageiro = action.payload.tipo_mensageiro
        const id_empresa = action.payload.id_empresa

        const channelIndex = draft.websockets.findIndex((socket) => {
          return (
            socket.options.query.id_integracao === +id_integracao &&
            socket.options.query.id_empresa === +id_empresa &&
            socket.options.query.tipo_mensageiro === tipo_mensageiro
          )
        })

        if (channelIndex === -1) {
          draft.wsChannel.push(action.payload.ws_channel)
          console.log('Novo wsChannel adicionado')
        } else {
          draft.wsChannel[channelIndex] = action.payload.ws_channel
          console.log(
            `wsChannel com id integração ${id_integracao} atualizado`,
          )
        }
      })

    case REMOVE_WEBSOCKET:
      return produce(state, (draft) => {
        const idIntegracao = action.payload.idIntegracao
        const tipo_mensageiro = action.payload.tipo_mensageiro

        const indexWebsocket = draft.websockets.findIndex(
          (socket) =>
            socket.options.query.id_integracao === +idIntegracao &&
            socket.options.query.tipo_mensageiro === tipo_mensageiro 
        )

        console.log('Removendo indexWebsocket => ', indexWebsocket)
        if (indexWebsocket !== -1) {
          const indexProcesso = draft.processos[tipo_mensageiro].findIndex((processo) => processo.id === +idIntegracao)
          if (indexProcesso !== -1 && tipo_mensageiro === 'widget') {
            draft.processos[tipo_mensageiro][indexProcesso].ativado = 'N'
          } else if (indexProcesso !== -1) {
            draft.processos[tipo_mensageiro][indexProcesso].ativo = 'N'
          }
          const indexServer = draft.servers.findIndex(
            (server) =>
            server.id === +idIntegracao &&
            server.tipo_mensageiro === tipo_mensageiro
          )

          if (indexServer !== -1) {
            draft.servers.splice(indexServer, 1)
          }
          draft.websockets.splice(indexWebsocket, 1)
          draft.wsChannel.splice(indexWebsocket, 1)
          console.log('Removido websocket')
        }
      })

    case ADD_CONNECTION_STATUS:
      return produce(state, (draft) => {
        const id_integracao = action.payload.id_integracao
        const tipo_mensageiro = action.payload.tipo_mensageiro

        const connectionsStatus = draft.connectionsStatus

        if (connectionsStatus.length > 0) {
          const connectionIndex = draft.connectionsStatus.findIndex(
            (connection) => {
              return (
                connection.id_integracao === parseInt(id_integracao) &&
                connection.tipo_mensageiro === tipo_mensageiro
              )
            },
          )

          if (connectionIndex === -1) {
            draft.connectionsStatus.push(action.payload)
            console.log('Novo connection status adicionado')
          } else {
            draft.connectionsStatus[connectionIndex] = action.payload
            console.log('Connection status atualizado', action.payload)
          }
        } else {
          draft.connectionsStatus.push(action.payload)
          console.log('Novo connection status adicionado')
        }
      })

    case SET_CHANNEL_INDEX:
      return produce(state, (draft) => {
        const idIntegracao = action.payload.id_integracao
        const tipoMensageiro = action.payload.tipo_mensageiro

        console.log('SET_CHANNEL_INDEX', { idIntegracao, tipoMensageiro })
        const channelIndex = draft.websockets.findIndex((socket) => {
          return (
            socket.options.query.id_integracao === parseInt(idIntegracao) &&
            socket.options.query.tipo_mensageiro === tipoMensageiro
          )
        })

        if (channelIndex === -1) {
          console.log('channelIndex não encontrado')
        } else {
          draft.channelIndex = channelIndex
        }
      })
    case PROCESS_TO_RECONNECT:
      return produce(state, (draft) => {
        const reconnected = draft.reconnected

        if (!reconnected) {
          const idIntegracao = action.payload.id

          const channelIndex = draft.websockets.findIndex(
            (socket) =>
              socket.options.query.id_integracao === parseInt(idIntegracao),
          )

          if (channelIndex !== -1) {
            console.log('Removendo channelIndex => ', channelIndex)
            draft.servers.splice(channelIndex, 1)
            draft.websockets.splice(channelIndex, 1)
            draft.wsChannel.splice(channelIndex, 1)
            draft.connectionsStatus.splice(channelIndex, 1)
            console.log(
              'Removido servers, websocket, wsChannel e connectionsStatus con sucesso',
            )
          }
        }

        draft.process_to_reconnect = action.payload
      })
    case REQUEST_PROCESSOS:
      return {
        ...state,
        loadingProcessos: true,
      }
    case REQUEST_PROCESSOS_SUCESS:
      return {
        ...state,
        new_request_processos: false,
        processos: action.payload,
        loadingProcessos: false,
      }

    case SET_PROCESSOS:
      return {
        ...state,
        processos: action.payload.processos
      }

    case REQUEST_DELETE_PROCESSOS_SUCESS:
      return produce(state, (draft) => {
        const idIntegracao = action.payload.id_integracao
        const tipoMensageiro = action.payload.tipo_mensageiro

        const channelIndex = draft.websockets.findIndex((socket) => {
          return (
            socket.options.query.id_integracao === parseInt(idIntegracao) &&
            socket.options.query.tipo_mensageiro === tipoMensageiro
          )
        })

        console.log('channelIndex para deletar -> ', channelIndex)

        if (channelIndex !== -1) {
          draft.servers.splice(channelIndex, 1)
          draft.websockets.splice(channelIndex, 1)
          draft.wsChannel.splice(channelIndex, 1)
          draft.connectionsStatus.splice(channelIndex, 1)
        }
      })
    case SET_EMAIL_SOCKET:
      return produce(state, (draft) => {
        draft.wsEmail = action.payload
      })
    case SET_CENTRAL_SOCKET:
      return produce(state, (draft) => {
        draft.wsCentral = action.payload
      })
    case ADD_MESSAGE_WARNING:
      return produce(state, (draft) => {
        const idIntegracao = action.payload.idIntegracao
        const tipoMensageiro = action.payload.tipoMensageiro

        const processo = draft.processos[tipoMensageiro].find(
          processo => processo.id === +idIntegracao
        )

        if (processo) {
          const descricao = processo.descricao ?? processo.nome_pagina ?? processo.description
          const indexMessageWarning = draft.messageWarning.findIndex(
            messageWarning => messageWarning === descricao
          )
          if (indexMessageWarning !== -1) draft.messageWarning.splice(indexMessageWarning, 1)
          draft.websockets.forEach(socket => {
            if (socket._connectionState === 'reconnect' || socket._pingTimer === null) {
              draft.messageWarning.push(descricao)
            }
          })
  
          draft.messageWarning = [...new Set(draft.messageWarning)];
        }
      })
    case REMOVE_MESSAGE_WARNING:
      return produce(state, (draft) => {
        const idIntegracao = action.payload.idIntegracao
        const tipoMensageiro = action.payload.tipoMensageiro

        const processo = draft.processos[tipoMensageiro].find(
          processo => processo.id === +idIntegracao
        )

        if (processo) {
          const descricao = processo.descricao ?? processo.nome_pagina ?? processo.description
          draft.messageWarning = draft.messageWarning.filter(
            messageWarning => messageWarning !== descricao
          )

          draft.messageWarning = [...new Set(draft.messageWarning)];
        }
      })
    default:
      return { ...state }
  }
}

export default WebService
