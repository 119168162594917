export const SET_ALL_EMAILS = 'SET_ALL_EMAILS'
export const SET_ACTIVE_EMAIL_ID = 'SET_ACTIVE_EMAIL_ID'

export const REQUEST_EMAILS_CONFIG = 'REQUEST_EMAILS_CONFIG'
export const REQUEST_EMAILS_LIST = 'REQUEST_EMAILS_LIST'
export const REQUEST_SEND_EMAIL = 'REQUEST_SEND_EMAIL'
export const SET_NEW_EMAIL = 'SET_NEW_EMAIL'
export const SET_NEW_EMAILS = 'SET_NEW_EMAILS'
export const SET_EMAIL_UPDATE_STATUS = 'SET_EMAIL_UPDATE_STATUS'
export const SET_EMAIL_UPDATE_LEAD = 'SET_EMAIL_UPDATE_LEAD'
export const SET_NEW_REPLY_EMAIL = 'SET_NEW_REPLY_EMAIL'

export const SET_EMAIL_CONFIG= 'SET_EMAIL_CONFIG'
export const SET_SENDING_EMAIL = 'SET_SENDING_EMAIL'

export const SET_TYPE_ANSWER_EMAIL = 'SET_TYPE_ANSWER_EMAIL'
export const SET_CURRENT_CONTENT_EMAIL_HTML = 'SET_CURRENT_CONTENT_EMAIL_HTML'
export const SET_EMAIL_FORM = 'SET_EMAIL_FORM'

export const SET_EMAIL_WEBSOCKET_START = 'SET_EMAIL_WEBSOCKET_START'

export const FILTER_TAB_EMAIL = 'FILTER_TAB_EMAIL'

export const ACTIVE_EMAIL_INBOX = 'ACTIVE_EMAIL_INBOX'

export const ACTIVE_OPTIONS_EMAIL_PROVIDER = 'ACTIVE_OPTIONS_EMAIL_PROVIDER'

export const SET_EMAIL_ACCESS = 'SET_EMAIL_ACCESS'

export const REMOVE_EMAIL_CONVERSATION = 'REMOVE_EMAIL_CONVERSATION'
