import store from "../redux/store";

export const getAttendantName = (idAttendant) => {
  const state = store.getState();
  const { attendants } = state.Config;
  return (
    attendants.find(
      (attendant) => parseInt(attendant.id_usuario) === idAttendant
    )?.nome ?? "sem atendente"
  );
};

export const getTeamName = (idTeam) => {
  console.log("idTeam", idTeam);
  const state = store.getState();
  const { teams } = state.Config;
  if (!idTeam) {
    console.log("sem equip");
    return "sem equipe";
  }
  return teams.find((item) => parseInt(item.id_equipe) === idTeam).descricao;
};

export const getMessageNote = ({
  attendantUpdated,
  idAttendant,
  teamUpdated,
  chatActive,
  idTeam,
}) => {
  if (attendantUpdated) {
    return `Conversa transferida de "${getAttendantName(
      chatActive.idResponsavel
    )}" para "${getAttendantName(idAttendant)}"`;
  } else if (teamUpdated) {
    return `Conversa transferida de "${getTeamName(
      chatActive.idEquipe
    )}" para "${getTeamName(idTeam)}"`;
  }
};
