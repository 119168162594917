import React from "react";
import * as Unicons from "@iconscout/react-unicons";
import { NavLink  } from "react-router-dom";

function BackLogoutModal(props) {
  if (!props.show) {
    return null;
  }

  const closeModal = () => {
    props.setShow(false);
  };


  return (
    <div className="modals-dafault-bot">
      <div className="modal-content w-50">
        <div className="modal-header">
          <b>O que você deseja fazer?</b>
          <button
            onClick={() => closeModal()}
            className="btn-transfer bg-white text-dark border-0"
          >
            <Unicons.UilTimes />
          </button>
        </div>
        <div className="modal-footer">
          <div className="d-flex flex-column w-100">
            <p>Deseja voltar para o CRM ou fazer o logout da central?</p>
            <div className="d-flex justify-content-between">
            <a
            className="btn w-25 align-self-end btn-success"
              href={window.location.origin + "/dashboard"}>
                Voltar para o CRM
              </a>
              <NavLink
            className="btn w-25 align-self-end btn-success"
              to="/central-atendimento/logout"
                  >
                Fazer logout

              </NavLink>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BackLogoutModal;
