import axios from 'axios'
import jwtDecode from 'jwt-decode'
import {
  api,
  apiNova,
  setAuthorization,
  setCRMAuthorization,
} from '../services/api'

/**
 * Checks if user is authenticated
 */
async function isUserAuthenticated() {
  const auth = getLoggedInAuth()
  if (!auth) {
    return false
  }

  try {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth.token
    const res = await axios.get(`${process.env.REACT_APP_API_NOVA}auth/me`)
    apiNova.defaults.headers.common['Authorization'] = 'Bearer ' + auth.token
    
    setAuthorization(auth.token)

    const user = res.data?.data ? res.data?.data : res.data

    setCRMAuthorization(user.access_key)
    return user
  } catch (e) {
    console.warn('access token expired')
    return false
  }
}

async function checkToken(token) {
  console.log('token 1', token)
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
  axios
    .get(`${process.env.REACT_APP_API_NOVA}auth/me`)
    .then((res) => {
      console.log('token 2', token)
      setAuthorization(token)
      return res.data
    })
    .catch((error) => {
      console.log('error =>', error)
      throw new Error('Token invalido')
    })
}

const setLoggedInUser = (token) => {
  const decoded = jwtDecode(token)
  localStorage.setItem('auth', JSON.stringify({ ...decoded.data, token }))
}

const getLoggedInAuth = () => {
  const auth = localStorage.getItem('auth')
  return auth ? (typeof auth == 'object' ? auth : JSON.parse(auth)) : null
}

export { isUserAuthenticated, setLoggedInUser, getLoggedInAuth, checkToken }
