import { all, put, takeEvery, call, fork } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { api, apiCRM } from '../../services/api'
import parseFilterQuery from '../../utils/parseFilterQuery'
import {
  CHECK_QRCODE,
  FILTER_TAB_CHAT,
  SET_ALERT_MESSAGE,
  SET_REPORT_DATE,
  REQUEST_REPORT_HISTORY,
} from './constants'
import { isUserAuthenticated } from '../../helpers/authUtils'
import store from '../store'
import {
  clearFilter,
  qrCodeError,
  addQueryFilter,
  setReportHistory,
  setChartFonte,
  setChartGraph,
  setChartsCarfsTop,
  setTableDesempenho,
  openModalTestMode,
  finishModalTestMode,
} from './actions'
import { fitchChatsInDb, loginUserSuccess } from '../actions'
import { handleOpenModalTestMode } from '../../components/Modals/TestMode'

function* toastEmitter({ payload: { messege, type, options } }) {
  let config = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    ...options,
  }

  switch (type) {
    case 'info':
      yield call(toast.info, messege, config)
      break
    case 'success':
      yield call(toast.success, messege, config)
      break
    case 'warning':
      yield call(toast.warn, messege, config)
      break
    case 'error':
      yield call(toast.error, messege, config)
      break
    case 'dark':
      yield call(toast.dark, messege, config)
      break
    case 'server':
      config = {
        position: 'top-right',
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // onClose: () => window.location.reload(),
      }
      yield call(toast.error, messege, config)
      break
    case 'test-mode':
      config = {
        position: 'top-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        closeButton: false,
        onClick: () => handleOpenModalTestMode(),
      }
      yield call(toast.warn, messege, config)
      break
    case 'default':
      yield call(toast, messege, config)
      break
    default:
      break
  }
}

function* getReportHistory({ payload: { page } }) {
  const state = store.getState()
  let id_empresa = +state.Auth.user.id_empresa
  let id_permissao = +state.Auth.user.id_permissao

  if (Object.keys(state.Auth.user).length === 0) {
    const user = yield call(() => isUserAuthenticated())
    yield put(loginUserSuccess(user))
    id_empresa = +user.id_empresa
    id_permissao = +user.id_permissao
  }

  if (id_permissao !== 1) {
    return (window.location.href = '/central-atendimento')
  }

  var { data } = yield call(api.get, `/report/getReportHistory`, {
    params: {
      id_empresa: id_empresa,
      page: page,
    },
  })

  yield put(setReportHistory(data))
}

function* checkQrCodeStatus() {
  const state = store.getState()

  const { qrCode, loadingQrCode, qrCodeErro } = state.Layout

  if (qrCode === '' && loadingQrCode && !qrCodeErro) {
    yield put(qrCodeError())
  }
}

function* filterTab() {
  const state = store.getState()

  const { activeChatsTab, activeFilters } = state.Layout

  let hasFilter = false

  for (const [key, item] of Object.entries(activeFilters)) {
  
    if (key !== 'origin') {
      if (typeof item === 'object' && item !== null) {
        if (item.active) {
          hasFilter = true
        }

        if (item.length > 0) {
       
          hasFilter = true
        }
      } else {
  
        if (key !== 'name' && item) {
          hasFilter = true
        }
      }
    }
  }



  let query = ''

  if (activeFilters.name) {
    query = `unanswered=null&name=${activeFilters.name}`
  }

  if (activeChatsTab !== 'users' && hasFilter) {
    yield put(clearFilter())
    yield put(addQueryFilter(query))
    yield put(fitchChatsInDb())
  }
}

export function* watchToastEmitter() {
  yield takeEvery(SET_ALERT_MESSAGE, toastEmitter)
}

export function* watchCheckQrCode() {
  yield takeEvery(CHECK_QRCODE, checkQrCodeStatus)
}

export function* watchFilterTab() {
  yield takeEvery(FILTER_TAB_CHAT, filterTab)
}

export function* watchGetReportHistory() {
  yield takeEvery(REQUEST_REPORT_HISTORY, getReportHistory)
}

function* layoutSaga() {
  yield all([
    fork(watchToastEmitter),
    fork(watchCheckQrCode),
    fork(watchFilterTab),
    fork(watchGetReportHistory),
  ])
}

export default layoutSaga
