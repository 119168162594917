import { combineReducers } from 'redux'

import Auth from './auth/reducers'
import Chat from './chat/reducers'
import Config from './config/reducers'
import Email from './email/reducers'
import Sms from './sms/reducers'
import Layout from './layout/reducers'
import WebService from './ws/reducers'
import Integrations from './integrations/reducers'

export default combineReducers({
  Auth,
  Chat,
  Sms,
  Email,
  Config,
  Layout,
  WebService,
  Integrations,
})
