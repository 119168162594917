export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_IS_OPEN_MORE_MENU = "SET_IS_OPEN_MORE_MENU";
export const FILTER_TAB_CHAT = "FILTER_TAB_CHAT";
export const SERVER_IS_CONNECTED = "SERVER_IS_CONNECTED";
export const SET_BOOTSTRAP = "SET_BOOTSTRAP";
export const SIDEBAR_FIXED = "SIDEBAR_FIXED";
export const OPEN_USER_PROFILE_SIDEBAR = "OPEN_USER_PROFILE_SIDEBAR";
export const CLOSE_USER_PROFILE_SIDEBAR = "CLOSE_USER_PROFILE_SIDEBAR";
export const OPEN_USER_PROFILE_SIDEBAR_TASKS =
  "OPEN_USER_PROFILE_SIDEBAR_TASKS";
export const OPEN_USER_PROFILE_SIDEBAR_SCHEDULE =
  "OPEN_USER_PROFILE_SIDEBAR_SCHEDULE";
export const CLOSE_USER_PROFILE_SIDEBAR_TASKS =
  "CLOSE_USER_PROFILE_SIDEBAR_TASKS";
export const SET_CONVERSATION_NAME_IN_OPEN_CHAT =
  "SET_CONVERSATION_NAME_IN_OPEN_CHAT";

export const CLOSE_USER_PROFILE_SIDEBAR_SCHEDULE =
  "CLOSE_USER_PROFILE_SIDEBAR_SCHEDULE";

export const SET_LOGO = "SET_LOGO";

export const MODAL_LOADING_REQUEST = "MODAL_LOADING_REQUEST";
export const OPEN_MODAL_WHATSAPP = "OPEN_MODAL_WHATSAPP";
export const CLOSE_MODAL_WHATSAPP = "CLOSE_MODAL_WHATSAPP";
export const OPEN_MODAL_MESSENGER = "OPEN_MODAL_MESSENGER";
export const CLOSE_MODAL_MESSENGER = "CLOSE_MODAL_MESSENGER";
export const OPEN_MODAL_INSTAGRAM = "OPEN_MODAL_INSTAGRAM";
export const CLOSE_MODAL_INSTAGRAM = "CLOSE_MODAL_INSTAGRAM";
export const OPEN_MODAL_WIDGET = "OPEN_MODAL_WIDGET";
export const CLOSE_MODAL_WIDGET = "CLOSE_MODAL_WIDGET";
export const OPEN_MODAL_RECONNECT = "OPEN_MODAL_RECONNECT";
export const CLOSE_MODAL_RECONNECT = "CLOSE_MODAL_RECONNECT";
export const OPEN_MODAL_USE_HERE = "OPEN_MODAL_USE_HERE";
export const CLOSE_MODAL_USE_HERE = "CLOSE_MODAL_USE_HERE";
export const OPEN_MODAL_CONFLIT = "OPEN_MODAL_CONFLIT";
export const CLOSE_MODAL_CONFLIT = "CLOSE_MODAL_CONFLIT";
export const OPEN_MODAL_DEVICE_NOT_CONNECTED =
  "OPEN_MODAL_DEVICE_NOT_CONNECTED";
export const CLOSE_MODAL_DEVICE_NOT_CONNECTED =
  "CLOSE_MODAL_DEVICE_NOT_CONNECTED";

export const OPEN_MODAL_CONFLIT_NEW_DEVICE_CONNECTED =
  "OPEN_MODAL_CONFLIT_NEW_DEVICE_CONNECTED";
export const CLOSE_MODAL_CONFLIT_NEW_DEVICE_CONNECTED =
  "CLOSE_MODAL_CONFLIT_NEW_DEVICE_CONNECTED";

export const OPEN_MODAL_INFO = "OPEN_MODAL_INFO";
export const CLOSE_MODAL_INFO = "CLOSE_MODAL_INFO";

export const OPEN_MODAL_TEST_MODE = "OPEN_MODAL_TEST_MODE";
export const CLOSE_MODAL_TEST_MODE = "CLOSE_MODAL_TEST_MODE";

export const START_MODAL_TEST_MODE = "START_MODAL_TEST_MODE";
export const FINISH_MODAL_TEST_MODE = "FINISH_MODAL_TEST_MODE";

export const OPEN_LOADING_ANEXO = "OPEN_LOADING_ANEXO";
export const CLOSE_LOADING_ANEXO = "CLOSE_LOADING_ANEXO";

export const OPEN_MODAL_LOAD_ALL_MESSAGES = "OPEN_MODAL_LOAD_ALL_MESSAGES";
export const CLOSE_MODAL_LOAD_ALL_MESSAGES = "CLOSE_MODAL_LOAD_ALL_MESSAGES";

export const LOADING_FILE = "LOADING_FILE";
export const LOADING_CONVERSATION = "LOADING_CONVERSATION";
export const CENTRAL_LOADED = "CENTRAL_LOADED";
export const CURRENT_MESSAGE_ID_DOWNLOADING = "CURRENT_MESSAGE_ID_DOWNLOADING";
export const SET_STATUS_WS = "SET_STATUS_WS";

export const ADD_QRCODE = "ADD_QRCODE";
export const CHECK_QRCODE = "CHECK_QRCODE";
export const QRCODE_LOADING = "QRCODE_LOADING";
export const QRCODE_ERROR = "QRCODE_ERROR";

export const LOADING_MESSENGER = "LOADING_MESSENGER";
export const LOADING_CHAT_ID_DB = "LOADING_CHAT_ID_DB";
export const LOADING_REQUEST = "LOADING_REQUEST";
export const SENDING_MESSAGE = "SENDING_MESSAGE";
export const SET_ANSWER_TYPE = "SET_ANSWER_TYPE";

export const SET_QUERY_PARAMS_URL = "SET_QUERY_PARAMS_URL";

export const OPEN_MODAL_PREVIEW_FILE = "OPEN_MODAL_PREVIEW_FILE";
export const CLOSE_MODAL_PREVIEW_FILE = "CLOSE_MODAL_PREVIEW_FILE";

export const OPEN_MODAL_DELETE_MESSEGE = "OPEN_MODAL_DELETE_MESSEGE";
export const CLOSE_MODAL_DELETE_MESSEGE = "CLOSE_MODAL_DELETE_MESSEGE";

export const OPEN_MODAL_END_CONVERSATION = "OPEN_MODAL_END_CONVERSATION";
export const CLOSE_MODAL_END_CONVERSATION = "CLOSE_MODAL_END_CONVERSATION";

export const OPEN_MODAL_END_CONVERSATION_BOT =
  "OPEN_MODAL_END_CONVERSATION_BOT";
export const CLOSE_MODAL_END_CONVERSATION_BOT =
  "CLOSE_MODAL_END_CONVERSATION_BOT";

export const OPEN_MODAL_DROPZONE = "OPEN_MODAL_DROPZONE";
export const CLOSE_MODAL_DROPZONE = "CLOSE_MODAL_DROPZONE";

export const OPEN_MODAL_EMAIL_EDITOR = "OPEN_MODAL_EMAIL_EDITOR";
export const CLOSE_MODAL_EMAIL_EDITOR = "CLOSE_MODAL_EMAIL_EDITOR";

export const CONNECTION_WS_OPEN = "CONNECTION_WS_OPEN";
export const CONNECTION_WS_CLOSED = "CONNECTION_WS_CLOSED";

export const HAS_24H_MESSENGER = "HAS_24H_MESSENGER";

export const SET_ALERT_MESSAGE = "SET_ALERT_MESSAGE";

export const SET_HEIGHT_CHAT_CONVERSATION_PX =
  "SET_HEIGHT_CHAT_CONVERSATION_PX";

export const MESSENGER_TYPE = "MESSENGER_TYPE";

export const ADD_FILTER = "ADD_FILTER";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const ADD_QUERY_FILTER = "ADD_QUERY_FILTER";
export const FILTERING = "FILTERING";
export const ADD_PAGINATION = "ADD_PAGINATION";
export const ADD_PAGINATION_TABS = "ADD_PAGINATION_TABS";

export const SET_MESSENGER_TYPE = "SET_MESSENGER_TYPE";

export const ENABLE_NOTIFICATION = "ENABLE_NOTIFICATION";

export const SCROLL_TO_BOTTOM = "SCROLL_TO_BOTTOM";

export const SET_LIGHTBOX_IMAGE = "SET_LIGHTBOX_IMAGE";

export const SET_DISCONNECT = "SET_DISCONNECT";

export const SET_TEMPLETE_TYPE = "SET_TEMPLETE_TYPE";
export const SET_TEMPLETE_MESSAGES = "SET_TEMPLETE_MESSAGES";
export const SET_SELECTED_TEMPLETE_TYPE = "SET_SELECTED_TEMPLETE_TYPE";

export const REQUEST_REPORT_HISTORY = "REQUEST_REPORT_HISTORY";
export const SET_REPORT_HISTORY = "SET_REPORT_HISTORY";
export const SET_REPORT_DATE = "SET_REPORT_DATE";
export const SET_REPORT_DATE_INIT = "SET_REPORT_DATE_INIT";
export const SET_REPORT_DATE_ENDEND = "SET_REPORT_DATE_ENDEND";
export const SET_CHARTS_CARDS_TOP = "SET_CHARTS_CARDS_TOP";
export const SET_CHART_FONTE = "SET_CHART_FONTE";
export const SET_CHART_GRAPH = "SET_CHART_GRAPH";
export const SET_TABLE_DESEMPENHO = "SET_TABLE_DESEMPENHO";

export const SET_WS_FINISHED_LOADING = "SET_WS_FINISHED_LOADING";

export const SET_CHAT_BOT_FLOW_NODES = "SET_CHAT_BOT_FLOW_NODES";
export const SET_CHAT_BOT_FLOW_EDGES = "SET_CHAT_BOT_FLOW_EDGES";
export const SET_CHAT_BOT_FLOW_DELETED_NODES =
  "SET_CHAT_BOT_FLOW_DELETED_NODES";
export const SET_CHAT_BOT_FLOW_FILES = "SET_CHAT_BOT_FLOW_FILES";

export const SET_REPORT_WEBSOCKET_START = "SET_REPORT_WEBSOCKET_START";

export const SET_MODAL_SYNC_CONVERSATIONS = "SET_MODAL_SYNC_CONVERSATIONS";

export const ACTIVEATE_BATCH_ACTION = "ACTIVEATE_BATCH_ACTION";
export const UPDATE_FILTER = "UPDATE_FILTER";

export const SET_CENTRAL_ALERT = "SET_CENTRAL_ALERT";
