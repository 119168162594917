import React from "react";
import { Redirect } from "react-router-dom";

// lazy load all the views
const Dashboard = React.lazy(() => import("../pages/dashboard/index"));
const Reports = React.lazy(() => import("../pages/dashboard/reports"));
const Clients = React.lazy(() => import("../pages/Clients"));
const FormClients = React.lazy(() => import("../pages/Clients/form"));
const Historico = React.lazy(() => import("../pages/Clients/historico"));
const FormLead = React.lazy(() => import("../pages/Clients/form"));
const Email = React.lazy(() => import("../pages/Email"));
const EmailView = React.lazy(() => import("../pages/Email/EmailView"));

const Geral = React.lazy(() => import("../pages/Configs/Geral"));

const MyPlan = React.lazy(() => import("../pages/Configs/Account/MyPlan"));
const FormMyPlan = React.lazy(() =>
  import("../pages/Configs/Account/MyPlan/form")
);

const Users = React.lazy(() => import("../pages/Configs/Account/Users"));
const FormUsers = React.lazy(() =>
  import("../pages/Configs/Account/Users/form")
);
const Usuario = React.lazy(() =>
  import("../pages/Configs/Account/Users/usuario")
);

const Oportunidades = React.lazy(() => import("../pages/Oportunidades"));
const Negocio = React.lazy(() => import("../pages/Oportunidades/negocio"));
const FormOportunidades = React.lazy(() =>
  import("../pages/Oportunidades/form")
);

const Team = React.lazy(() => import("../pages/Configs/Account/Team"));
const FormTeam = React.lazy(() => import("../pages/Configs/Account/Team/form"));

const Tags = React.lazy(() => import("../pages/Configs/Account/Tags"));
const FormTags = React.lazy(() => import("../pages/Configs/Account/Tags/form"));

const Origem = React.lazy(() => import("../pages/Configs/Account/Origem"));

const TimeSystem = React.lazy(() =>
  import("../pages/Configs/CallCenter/TimeSystem")
);

const TimeSystemForm = React.lazy(() =>
  import("../pages/Configs/CallCenter/TimeSystem/Form")
);

const FormOrigem = React.lazy(() =>
  import("../pages/Configs/Account/Origem/form")
);

const Atuacao = React.lazy(() => import("../pages/Configs/Account/Atuacao"));
const FormAtuacao = React.lazy(() =>
  import("../pages/Configs/Account/Atuacao/form")
);

const MotivoPerda = React.lazy(() =>
  import("../pages/Configs/Account/MotivoPerda")
);
const FormMotivoPerda = React.lazy(() =>
  import("../pages/Configs/Account/MotivoPerda/form")
);

const Importacao = React.lazy(() =>
  import("../pages/Configs/Account/Importacao")
);
const FormImportacao = React.lazy(() =>
  import("../pages/Configs/Account/Importacao/form")
);

const ProdutosServicos = React.lazy(() =>
  import("../pages/Configs/Account/ProdutosServicos")
);
const FormProdutosServicos = React.lazy(() =>
  import("../pages/Configs/Account/ProdutosServicos/form")
);

const ChatBot = React.lazy(() => import("../pages/Configs/CallCenter/ChatBot"));
const FormChatBot = React.lazy(() =>
  import("../pages/Configs/CallCenter/ChatBot/form")
);
const ChatBotFlow = React.lazy(() =>
  import("../pages/Configs/CallCenter/ChatBot/flow")
);

const ChatBotSettings = React.lazy(() =>
  import("../pages/Configs/CallCenter/ChatBotSettings")
);
const FormChatBotSettings = React.lazy(() =>
  import("../pages/Configs/CallCenter/ChatBotSettings/form")
);

const SettingsEmail = React.lazy(() =>
  import("../pages/Configs/CallCenter/SettingsEmail")
);
const FormSettingsEmail = React.lazy(() =>
  import("../pages/Configs/CallCenter/SettingsEmail/form")
);

const Channels = React.lazy(() =>
  import("../pages/Configs/CallCenter/Channels")
);
const FormChannels = React.lazy(() =>
  import("../pages/Configs/CallCenter/Channels/form")
);

const Widget = React.lazy(() =>
  import("../pages/Configs/CallCenter/Channels/Widget")
);
const FormWidget = React.lazy(() =>
  import("../pages/Configs/CallCenter/Channels/Widget/form")
);

const SmsConfig = React.lazy(() =>
  import("../pages/Configs/CallCenter/Channels/Sms")
);
const FormSmsConfig = React.lazy(() =>
  import("../pages/Configs/CallCenter/Channels/Sms/form")
);

const Whatsapp = React.lazy(() =>
  import("../pages/Configs/CallCenter/Channels/Whatsapp")
);
const FormWhatsapp = React.lazy(() =>
  import("../pages/Configs/CallCenter/Channels/Whatsapp/form")
);

const WhatsappApi = React.lazy(() =>
  import("../pages/Configs/CallCenter/Channels/WhatsappApi")
);
const FormWhatsappApi = React.lazy(() =>
  import("../pages/Configs/CallCenter/Channels/WhatsappApi/form")
);

const Instagram = React.lazy(() =>
  import("../pages/Configs/CallCenter/Channels/Instagram")
);
const FormInstagram = React.lazy(() =>
  import("../pages/Configs/CallCenter/Channels/Instagram/form")
);

const Messenger = React.lazy(() =>
  import("../pages/Configs/CallCenter/Channels/Messenger")
);
const FormMessenger = React.lazy(() =>
  import("../pages/Configs/CallCenter/Channels/Messenger/form")
);

const Telegram = React.lazy(() =>
  import("../pages/Configs/CallCenter/Channels/Telegram")
);
const FormTelegram = React.lazy(() =>
  import("../pages/Configs/CallCenter/Channels/Telegram/form")
);

const Origin = React.lazy(() => import("../pages/Configs/CallCenter/Origin"));
const FormOrigin = React.lazy(() =>
  import("../pages/Configs/CallCenter/Origin/form")
);

//novas configs


const General = React.lazy(() =>
  import("../pages/Configs/CallCenter/General")
);

const Settings = React.lazy(() =>
  import("../pages/Configs/CallCenter/Settings")
);
const FormSettings = React.lazy(() =>
  import("../pages/Configs/CallCenter/Settings/form")
);

const TimerSystemSettings = React.lazy(() =>
  import("../pages/Configs/CallCenter/TimeSystem/Form")
);

const Clock = React.lazy(() => import("../pages/Configs/CallCenter/Clock"));
const FormClock = React.lazy(() =>
  import("../pages/Configs/CallCenter/Clock/form")
);

const Messenge = React.lazy(() =>
  import("../pages/Configs/CallCenter/Messenge")
);

const FormStandardMessenge = React.lazy(() =>
  import("../pages/Configs/CallCenter/Messenge/form/standard")
);

const FormFacebookMessenge = React.lazy(() =>
  import("../pages/Configs/CallCenter/Messenge/form/facebook")
);

const Gatilhos = React.lazy(() => import("../pages/Configs/Business/Gatilhos"));
const FormGatilhos = React.lazy(() =>
  import("../pages/Configs/Business/Gatilhos/form")
);

const StarterPage = React.lazy(() => import("../pages/StarterPage/index"));

// auth
const Login = React.lazy(() => import("../pages/Auth/Login"));
const Logout = React.lazy(() => import("../pages/Auth/Logout"));
const AuthSSO = React.lazy(() => import("../pages/Auth/AuthSSO"));
const ForgetPassword = React.lazy(() => import("../pages/Auth/ForgetPassword"));
const Register = React.lazy(() => import("../pages/Auth/Register"));
const LockScreen = React.lazy(() => import("../pages/Auth/LockScreen"));

// declare all routes
const authProtectedRoutes = [
  { path: "/central-atendimento", component: Dashboard },
  { path: "/email", exact: true, component: Email },
  { path: "/email/view/:id", component: EmailView },
  { path: "/reports", component: Reports },
  { path: "/clients", exact: true, component: Clients },
  { path: "/configs/geral", exact: true, component: Geral },
  { path: "/configs/account/my-plan", exact: true, component: MyPlan },
  { path: "/configs/account/users/form", exact: true, component: FormMyPlan },
  { path: "/configs/account/users", exact: true, component: Users },
  { path: "/configs/account/users/form", exact: true, component: FormUsers },
  { path: "/configs/account/team", exact: true, component: Team },
  { path: "/configs/account/team/form", exact: true, component: FormTeam },
  { path: "/configs/account/tags", exact: true, component: Tags },
  { path: "/configs/account/tags/form", exact: true, component: FormTags },
  { path: "/configs/account/origem", exact: true, component: Origem },
  { path: "/configs/account/origem/form", exact: true, component: FormOrigem },
  { path: "/configs/account/atuacao", exact: true, component: Atuacao },
  {
    path: "/configs/account/atuacao/form",
    exact: true,
    component: FormAtuacao,
  },
  {
    path: "/configs/account/motivo-perda",
    exact: true,
    component: MotivoPerda,
  },
  {
    path: "/configs/account/motivo-perda/form",
    exact: true,
    component: FormMotivoPerda,
  },
  { path: "/configs/account/importacao", exact: true, component: Importacao },
  {
    path: "/configs/account/importacao/form",
    exact: true,
    component: FormImportacao,
  },
  {
    path: "/configs/account/produtos-servicos",
    exact: true,
    component: ProdutosServicos,
  },
  {
    path: "/configs/account/produtos-servicos/form",
    exact: true,
    component: FormProdutosServicos,
  },
  { path: "/configs/call-center/chatBot", exact: true, component: ChatBot },
  {
    path: "/configs/call-center/chatBot/form",
    exact: true,
    component: FormChatBot,
  },
  {
    path: "/configs/call-center/chatBot/flow",
    exact: true,
    component: ChatBotFlow,
  },
  {
    path: "/configs/call-center/settings-chatBot",
    exact: true,
    component: ChatBotSettings,
  },
  {
    path: "/configs/call-center/settings-chatBot/form",
    exact: true,
    component: FormChatBotSettings,
  },
  {
    path: "/configs/call-center/settings-email",
    exact: true,
    component: SettingsEmail,
  },
  {
    path: "/configs/call-center/settings-email/form",
    exact: true,
    component: FormSettingsEmail,
  },
  { path: "/configs/call-center/channels", exact: true, component: Channels },
  {
    path: "/configs/call-center/channels/form",
    exact: true,
    component: FormChannels,
  },
  {
    path: "/configs/call-center/channels/widget",
    exact: true,
    component: Widget,
  },
  {
    path: "/configs/call-center/channels/widget/form",
    exact: true,
    component: FormWidget,
  },
  {
    path: "/configs/call-center/channels/sms-config",
    exact: true,
    component: SmsConfig,
  },
  {
    path: "/configs/call-center/channels/sms-config/form",
    exact: true,
    component: FormSmsConfig,
  },
  {
    path: "/configs/call-center/channels/sms-config/form/:id",
    exact: true,
    component: FormSmsConfig,
  },
  {
    path: "/configs/call-center/channels/whatsapp",
    exact: true,
    component: Whatsapp,
  },
  {
    path: "/configs/call-center/channels/whatsapp/form",
    exact: true,
    component: FormWhatsapp,
  },
  {
    path: "/configs/call-center/channels/whatsapp-api",
    exact: true,
    component: WhatsappApi,
  },
  {
    path: "/configs/call-center/channels/whatsapp-api/form",
    exact: true,
    component: FormWhatsappApi,
  },
  {
    path: "/configs/call-center/channels/instagram",
    exact: true,
    component: Instagram,
  },
  {
    path: "/configs/call-center/channels/messenger/form",
    exact: true,
    component: FormMessenger,
  },
  {
    path: "/configs/call-center/channels/messenger",
    exact: true,
    component: Messenger,
  },
  {
    path: "/configs/call-center/channels/telegram/form",
    exact: true,
    component: FormTelegram,
  },
  {
    path: "/configs/call-center/channels/telegram",
    exact: true,
    component: Telegram,
  },
  { path: "/configs/call-center/origin", exact: true, component: Origin },
  {
    path: "/configs/call-center/origin/form",
    exact: true,
    component: FormOrigin,
  },
  { path: "/configs/call-center/settings", exact: true, component: Settings },
  {
    path: "/configs/call-center/settings/form",
    exact: true,
    component: FormSettings,
  },
  { path: "/configs/call-center/clock", exact: true, component: Clock },
  {
    path: "/configs/call-center/clock/form",
    exact: true,
    component: FormClock,
  },
  {
    path: "/configs/call-center/template-messages",
    exact: true,
    component: Messenge,
  },
  {
    path: "/configs/call-center/template-messages/form/standard",
    exact: true,
    component: FormStandardMessenge,
  },
  {
    path: "/configs/call-center/template-messages/form/standard/:id",
    component: FormStandardMessenge,
  },
  {
    path: "/configs/call-center/template-messages/form/facebook",
    exact: true,
    component: FormFacebookMessenge,
  },
  {
    path: "/configs/call-center/template-messages/form/facebook/:id",
    component: FormFacebookMessenge,
  },
  { path: "/configs/business/gatilhos", exact: true, component: Gatilhos },
  {
    path: "/configs/business/gatilhos/form",
    exact: true,
    component: FormGatilhos,
  },

  { path: "/pages-starter", component: StarterPage },

  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/central-atendimento" />,
  },
  // {
  //   path: '/reports',
  //   component: () => <Redirect to="/central-atendimento/reports" />,
  // },

  //Horários
  {
    path: "/configs/call-center/general",
    component: General,
  },
  {
    path: "/configs/call-center/time-system",
    exact: true,
    component: TimeSystem,
  },
  {
    path: "/configs/call-center/time-system/settings",
    exact: true,
    component: TimeSystemForm,
  },
];

const publicRoutes = [
  { path: "/central-atendimento/logout", component: Logout },
  { path: "/central-atendimento/login", component: Login },
  { path: "/central-atendimento/auth-sso", component: AuthSSO },
  { path: "/central-atendimento/forget-password", component: ForgetPassword },
  { path: "/central-atendimento/register", component: Register },
  { path: "/central-atendimento/lock-screen", component: LockScreen },
];

const routes = [...authProtectedRoutes, ...publicRoutes];

export { authProtectedRoutes, publicRoutes, routes };
