import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  CardBody,
  ModalFooter,
  Card,
  Button,
  Spinner,
  Row,
  CardHeader,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import {
  closeModalWhatsapp,
  requestProcessosSucess,
  requestStartProcessos,
  setProcessToReconnect,
} from "../../redux/actions";

import * as Unicons from "@iconscout/react-unicons";

import iconPlus from "../../assets/images/plus.png";
import logoWhatsapp from "../../assets/images/logos/Logo_Whatsapp.png";
import { connect } from "react-redux";
import { api } from "../../services/api";
import Bugsnag from "@bugsnag/js";

function ModalWhatsapp(props) {
  const [descricao, setDescricao] = useState("");
  const [loadingRequest, setLoadingRequest] = useState(false);
  async function handleEnableProcess() {
    const processo = props.process_to_reconnect;

    const processoData = {
      tipo_mensageiro: processo.tipo_mensageiro,
      id_integracao: processo.id,
      id_empresa: processo.id_empresa,
      id_usuario: parseInt(props.user.id_usuario),
      id_bot_dialogflow: processo.id_bot_dialogflow,
      limite_importacao: processo.limite_importacao,
      token: processo.tipo_mensageiro === "messenger" ? processo.token : null,
      id_pagina:
        processo.tipo_mensageiro === "messenger" ? processo.id_pagina : null,
      reconnect: "false",
    };

    props.requestStartProcessos(processoData);
  }

  async function createNewWppProcess() {
    try {
      setLoadingRequest(true);
      await api.post(`/db/crm/processos/whatsapp/${props.user.id_empresa}`, {
        descricao,
      });

      const res = await api.get(`/central/processos/${props.user.id_empresa}`);
      const processos = res.data;

      props.setProcessToReconnect(processos.whatsapp[0]);

      props.requestProcessosSucess(processos);
      setLoadingRequest(false);
    } catch (error) {
      console.log(error);
      setLoadingRequest(false);
      Bugsnag.notify(error)
    }
  }

  return (
    <Modal backdrop="static" isOpen={props.modalWhatsapp} centered>
      <ModalBody style={{ backgroundColor: "#e4e1e1" }}>
        {/* <CardHeader className='bg-transparent'>
           <Row className='align-items-center'>
              <div className='col'>
                <h6 className='text-uppercase text-center ls-1 mb-1'>
                  Ativar linha Meets
                </h6>
              </div>
            </Row>
          </CardHeader> */}
        <CardBody className="p-2">
          <SimpleBar>
            <div className="p-4 border-bottom">
              <div className="text-center mb-4">
                <img
                  src={props.logoImg}
                  alt="chatvia"
                  style={{ width: 60, height: "100%" }}
                />
                <img
                  src={iconPlus}
                  alt="chatvia"
                  style={{ width: 20, height: "100%", margin: 25 }}
                />
                <img
                  src={logoWhatsapp}
                  alt="chatvia"
                  style={{ width: 60, height: "100%" }}
                />
              </div>
              {props?.processos?.whatsapp?.length === 0 && (
                <>
                  <p className="font-size-12 mb-2 text-truncate">
                    1º Passo: Coloque uma descrição para sua linha(Ex.: Vendas))
                  </p>
                  <FormGroup className="d-flex justify-content-between align-items-center  mb-2">
                    <Input
                      type="text"
                      name="descricao"
                      id="descricao"
                      value={descricao}
                      onChange={(e) => setDescricao(e.target.value)}
                      placeholder="Descrição"
                    />
                  </FormGroup>
                  <div className="d-grid">
                    <Button
                      onClick={createNewWppProcess}
                      disabled={descricao === ""}
                      color="primary"
                      block
                      className=" waves-effect waves-light"
                      type="submit"
                    >
                      {loadingRequest ? (
                        <Spinner color="light" name="mySpinner">
                          {""}
                        </Spinner>
                      ) : (
                        " Criar linha"
                      )}
                    </Button>
                  </div>
                </>
              )}
              {props?.processos?.whatsapp?.length > 0 && (
                <>
                  {props.idEmpresaDisconnect && props.idIntegracaoDisconnect ? (
                    <h5 className="font-size-18 mb-3 text-truncate">
                      {
                        props.processos.whatsapp.find(
                          (processo) =>
                            processo.id_empresa === props.idEmpresaDisconnect &&
                            processo.id === props.idIntegracaoDisconnect
                        ).descricao
                      }{" "}
                      - Desconectado
                    </h5>
                  ) : (
                    <></>
                  )}
                  <h5 className="font-size-18 mb-3 text-truncate">
                    Para usar WhatsApp:
                  </h5>

                  <p className="font-size-12 mb-2 text-truncate">
                    1. Abra o WhatsApp no seu celular;
                  </p>

                  <p className="font-size-12 mb-2 text-truncate">
                    2. Toque em <strong>Mais opções</strong>{" "}
                    <span className="_3cZ5X">
                      <svg height="24px" viewBox="0 0 24 24" width="24px">
                        <rect
                          fill="#f2f2f2"
                          height="24"
                          rx="3"
                          width="24"
                        ></rect>
                        <path
                          d="m12 15.5c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5-1.5-.675-1.5-1.5.675-1.5 1.5-1.5zm0-2c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5 1.5.675 1.5 1.5-.675 1.5-1.5 1.5zm0-5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5 1.5.675 1.5 1.5-.675 1.5-1.5 1.5z"
                          fill="#818b90"
                        ></path>
                      </svg>
                    </span>
                    ;
                  </p>
                  <p className="font-size-12 mb-2 text-truncate">
                    3. Selecione a opção <strong>Aparelhos conectados;</strong>
                  </p>
                  <p className="font-size-12 mb-2 text-truncate">
                    4. Clique em <strong>Conectar um aparelho;</strong>
                  </p>
                  <p className="font-size-12 mb-2 text-truncate">
                    5. Clique no botão abaixo <strong>GERAR QRCODE;</strong>
                  </p>
                  <p className="font-size-12 mb-2 text-truncate">
                    6. Aponte seu celular para essa tela para capturar o código.
                  </p>
                  <br></br>
                  {/* <h5 className="font-size-24 mb-3 text-truncate text-light">{props.statusWs}</h5> */}

                  {props.loadingChatInDb ? (
                    <Spinner color="dark" name="mySpinner">
                      {""}
                    </Spinner>
                  ) : props.qrCode &&
                    !props.qrCodeErro &&
                    !props.loadingQrCode ? (
                    <div className="text-center mb-4">
                      <img
                        src={props.qrCode}
                        alt="chatvia"
                        style={{ width: 264, height: 264 }}
                      />
                    </div>
                  ) : (
                    <div className="d-grid">
                      <Button
                        onClick={handleEnableProcess}
                        disabled={props.loadingQrCode}
                        color="primary"
                        block
                        className=" waves-effect waves-light"
                        type="submit"
                      >
                        {props.loadingQrCode ? (
                          <Spinner color="light" name="mySpinner">
                            {""}
                          </Spinner>
                        ) : props.qrCodeErro ? (
                          "REGARREGAR QRCODE"
                        ) : (
                          "GERAR QRCODE"
                        )}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </SimpleBar>
          <ModalFooter className="border-0">
            <Button
              disabled={props.loadingQrCode}
              color="primary"
              onClick={() => props.closeModalWhatsapp()}
            >
              Fechar
            </Button>
          </ModalFooter>
        </CardBody>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  const { user } = state.Auth;
  const {
    modalWhatsapp,
    qrCode,
    logoImg,
    qrCodeErro,
    loadingQrCode,
    loadingChatInDb,
    idEmpresaDisconnect,
    idIntegracaoDisconnect,
  } = state.Layout;
  const { processos, process_to_reconnect } = state.WebService;
  return {
    user,
    logoImg,
    processos,
    modalWhatsapp,
    loadingChatInDb,
    qrCode,
    qrCodeErro,
    loadingQrCode,
    idEmpresaDisconnect,
    process_to_reconnect,
    idIntegracaoDisconnect,
  };
};

export default connect(mapStateToProps, {
  closeModalWhatsapp,
  requestStartProcessos,
  requestProcessosSucess,
  setProcessToReconnect,
})(ModalWhatsapp);
