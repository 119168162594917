import {
  SET_ACTIVE_TAB,
  OPEN_USER_PROFILE_SIDEBAR,
  OPEN_USER_PROFILE_SIDEBAR_TASKS,
  CLOSE_USER_PROFILE_SIDEBAR,
  CLOSE_USER_PROFILE_SIDEBAR_TASKS,
  SET_CONVERSATION_NAME_IN_OPEN_CHAT,
  OPEN_MODAL_WHATSAPP,
  CLOSE_MODAL_WHATSAPP,
  OPEN_MODAL_MESSENGER,
  CLOSE_MODAL_MESSENGER,
  LOADING_FILE,
  CURRENT_MESSAGE_ID_DOWNLOADING,
  SET_HEIGHT_CHAT_CONVERSATION_PX,
  SERVER_IS_CONNECTED,
  SET_STATUS_WS,
  ADD_QRCODE,
  CHECK_QRCODE,
  LOADING_MESSENGER,
  QRCODE_LOADING,
  QRCODE_ERROR,
  MODAL_LOADING_REQUEST,
  OPEN_MODAL_PREVIEW_FILE,
  CLOSE_MODAL_PREVIEW_FILE,
  OPEN_MODAL_RECONNECT,
  CLOSE_MODAL_RECONNECT,
  CLOSE_MODAL_USE_HERE,
  OPEN_MODAL_USE_HERE,
  OPEN_MODAL_INFO,
  CLOSE_MODAL_INFO,
  CONNECTION_WS_OPEN,
  CONNECTION_WS_CLOSED,
  OPEN_MODAL_CONFLIT,
  CLOSE_MODAL_CONFLIT,
  HAS_24H_MESSENGER,
  MESSENGER_TYPE,
  ADD_FILTER,
  FILTERING,
  ADD_PAGINATION,
  LOADING_CHAT_ID_DB,
  ENABLE_NOTIFICATION,
  ADD_QUERY_FILTER,
  LOADING_REQUEST,
  CENTRAL_LOADED,
  OPEN_MODAL_END_CONVERSATION,
  CLOSE_MODAL_END_CONVERSATION,
  CLOSE_MODAL_DELETE_MESSEGE,
  OPEN_MODAL_DELETE_MESSEGE,
  SET_ALERT_MESSAGE,
  OPEN_USER_PROFILE_SIDEBAR_SCHEDULE,
  CLOSE_USER_PROFILE_SIDEBAR_SCHEDULE,
  SIDEBAR_FIXED,
  SCROLL_TO_BOTTOM,
  SET_LIGHTBOX_IMAGE,
  CLOSE_MODAL_END_CONVERSATION_BOT,
  OPEN_MODAL_END_CONVERSATION_BOT,
  CLOSE_MODAL_DEVICE_NOT_CONNECTED,
  OPEN_MODAL_DEVICE_NOT_CONNECTED,
  OPEN_MODAL_LOAD_ALL_MESSAGES,
  CLOSE_MODAL_LOAD_ALL_MESSAGES,
  FILTER_TAB_CHAT,
  ADD_PAGINATION_TABS,
  CLEAR_FILTER,
  SET_IS_OPEN_MORE_MENU,
  SET_DISCONNECT,
  OPEN_MODAL_DROPZONE,
  CLOSE_MODAL_DROPZONE,
  SET_TEMPLETE_TYPE,
  SET_TEMPLETE_MESSAGES,
  SET_SELECTED_TEMPLETE_TYPE,
  SET_CHART_FONTE,
  SET_CHARTS_CARDS_TOP,
  SET_CHART_GRAPH,
  SET_TABLE_DESEMPENHO,
  REQUEST_REPORT_HISTORY,
  SET_REPORT_HISTORY,
  SET_REPORT_WEBSOCKET_START,
  SET_REPORT_DATE_INIT,
  SET_REPORT_DATE_ENDEND,
  SET_REPORT_DATE,
  CLOSE_LOADING_ANEXO,
  OPEN_LOADING_ANEXO,
  SET_WS_FINISHED_LOADING,
  OPEN_MODAL_WIDGET,
  CLOSE_MODAL_WIDGET,
  CLOSE_MODAL_INSTAGRAM,
  OPEN_MODAL_INSTAGRAM,
  SET_QUERY_PARAMS_URL,
  LOADING_CONVERSATION,
  SENDING_MESSAGE,
  SET_ANSWER_TYPE,
  SET_BOOTSTRAP,
  SET_CHAT_BOT_FLOW_EDGES,
  SET_CHAT_BOT_FLOW_NODES,
  SET_CHAT_BOT_FLOW_DELETED_NODES,
  SET_LOGO,
  OPEN_MODAL_TEST_MODE,
  START_MODAL_TEST_MODE,
  CLOSE_MODAL_TEST_MODE,
  FINISH_MODAL_TEST_MODE,
  CLOSE_MODAL_EMAIL_EDITOR,
  OPEN_MODAL_EMAIL_EDITOR,
  SET_CHAT_BOT_FLOW_FILES,
  SET_MODAL_SYNC_CONVERSATIONS,
  ACTIVEATE_BATCH_ACTION,
  UPDATE_FILTER,
  CLOSE_MODAL_CONFLIT_NEW_DEVICE_CONNECTED,
  OPEN_MODAL_CONFLIT_NEW_DEVICE_CONNECTED,
  SET_CENTRAL_ALERT,
} from "./constants";

export const setStatusWs = (message) => ({
  type: SET_STATUS_WS,
  payload: message,
});

export const addQrCode = (qrcode) => ({
  type: ADD_QRCODE,
  payload: qrcode,
});

export const checkQrCode = () => ({
  type: CHECK_QRCODE,
});

export const qrCodeLoading = () => ({
  type: QRCODE_LOADING,
});

export const qrCodeError = () => ({
  type: QRCODE_ERROR,
});

export const loadingMessenger = (bool) => ({
  type: LOADING_MESSENGER,
  payload: bool,
});

export const setWsFinished = (bool) => ({
  type: SET_WS_FINISHED_LOADING,
  payload: bool,
});

export const setServerConnected = () => ({
  type: SERVER_IS_CONNECTED,
});

export const setBootstrap = (bool) => ({
  type: SET_BOOTSTRAP,
  payload: bool,
});

export const setChatBotFlowNodes = (nodes) => ({
  type: SET_CHAT_BOT_FLOW_NODES,
  payload: nodes,
});

export const setChatBotFlowDeletedNodes = (data) => ({
  type: SET_CHAT_BOT_FLOW_DELETED_NODES,
  payload: data,
});

export const setChatBotFlowFiles = (data) => ({
  type: SET_CHAT_BOT_FLOW_FILES,
  payload: data,
});

export const setChatBotFlowEdges = (edges) => ({
  type: SET_CHAT_BOT_FLOW_EDGES,
  payload: edges,
});

export const setLogo = (image) => ({
  type: SET_LOGO,
  payload: image,
});

export const setActiveTab = (tabId) => ({
  type: SET_ACTIVE_TAB,
  payload: tabId,
});

export const setIsOpenMoreMenu = (idMongo) => ({
  type: SET_IS_OPEN_MORE_MENU,
  payload: idMongo,
});

export const setFilterTabChat = (name, bool) => ({
  type: FILTER_TAB_CHAT,
  payload: {
    name,
    bool,
  },
});

export const setLoadingFile = (bool) => ({
  type: LOADING_FILE,
  payload: bool,
});

export const setLoadingConversation = (bool) => ({
  type: LOADING_CONVERSATION,
  payload: bool,
});

export const setLoadingChatInDb = (bool) => ({
  type: LOADING_CHAT_ID_DB,
  payload: bool,
});

export const setLoadingRequest = (bool) => ({
  type: LOADING_REQUEST,
  payload: bool,
});

export const setCentralLoaded = (bool) => ({
  type: CENTRAL_LOADED,
  payload: bool,
});

export const setSendingMessage = (bool) => ({
  type: SENDING_MESSAGE,
  payload: bool,
});

export const setAnswerType = (type) => ({
  type: SET_ANSWER_TYPE,
  payload: type,
});

export const setCurrentMessageIdDownloading = (messageId) => ({
  type: CURRENT_MESSAGE_ID_DOWNLOADING,
  payload: messageId,
});

export const setSidebarFixed = (bool) => ({
  type: SIDEBAR_FIXED,
  payload: bool,
});

export const setQueryParamsURL = (query) => ({
  type: SET_QUERY_PARAMS_URL,
  payload: query,
});

export const openUserSidebar = () => ({
  type: OPEN_USER_PROFILE_SIDEBAR,
});

export const setModalLoagindResquet = (bool) => ({
  type: MODAL_LOADING_REQUEST,
  payload: bool,
});

export const openModalWhatsapp = () => ({
  type: OPEN_MODAL_WHATSAPP,
});

export const openModalMessenger = () => ({
  type: OPEN_MODAL_MESSENGER,
});

export const openModalInstagram = () => ({
  type: OPEN_MODAL_INSTAGRAM,
});

export const openModalWidget = () => ({
  type: OPEN_MODAL_WIDGET,
});

export const openModalReconnect = () => ({
  type: OPEN_MODAL_RECONNECT,
});

export const openModalUseHere = () => ({
  type: OPEN_MODAL_USE_HERE,
});

export const openModalConflit = (idIntegracao) => ({
  type: OPEN_MODAL_CONFLIT,
  payload: idIntegracao,
});

export const openModalDeviceNotConnected = () => ({
  type: OPEN_MODAL_DEVICE_NOT_CONNECTED,
});

export const openModalConflitNewDeviceConnected = () => ({
  type: OPEN_MODAL_CONFLIT_NEW_DEVICE_CONNECTED,
});

export const openModalInfo = (title, msg, type = "success") => ({
  type: OPEN_MODAL_INFO,
  payload: {
    type,
    title,
    msg,
  },
});

export const openModalTestMode = () => ({
  type: OPEN_MODAL_TEST_MODE,
});

export const startModalTestMode = () => ({
  type: START_MODAL_TEST_MODE,
});

export const openLoadingAnexo = () => ({
  type: OPEN_LOADING_ANEXO,
});

export const openModalLoadAllMessages = () => ({
  type: OPEN_MODAL_LOAD_ALL_MESSAGES,
});

export const openUserSidebarTasks = () => ({
  type: OPEN_USER_PROFILE_SIDEBAR_TASKS,
});

export const closeUserSidebarTasks = () => ({
  type: CLOSE_USER_PROFILE_SIDEBAR_TASKS,
});

export const openUserSidebarSchedule = () => ({
  type: OPEN_USER_PROFILE_SIDEBAR_SCHEDULE,
});

export const closeUserSidebarSchedule = () => ({
  type: CLOSE_USER_PROFILE_SIDEBAR_SCHEDULE,
});

export const openModalEndConversation = () => ({
  type: OPEN_MODAL_END_CONVERSATION,
});

export const openModalEndConversationBot = () => ({
  type: OPEN_MODAL_END_CONVERSATION_BOT,
});

export const openModalDropzone = () => ({
  type: OPEN_MODAL_DROPZONE,
});

export const openModalEmailEditor = () => ({
  type: OPEN_MODAL_EMAIL_EDITOR,
});

export const openModalDeleteMessege = (
  idMongoChat,
  chatId,
  messagesIdArray
) => ({
  type: OPEN_MODAL_DELETE_MESSEGE,
  payload: {
    idMongoChat,
    chatId,
    messagesIdArray,
  },
});

export const closeUserSidebar = () => ({
  type: CLOSE_USER_PROFILE_SIDEBAR,
});

export const closeModalWhatsapp = () => ({
  type: CLOSE_MODAL_WHATSAPP,
});

export const closeModalMessenger = () => ({
  type: CLOSE_MODAL_MESSENGER,
});

export const closeModalInstagram = () => ({
  type: CLOSE_MODAL_INSTAGRAM,
});

export const closeModalWidget = () => ({
  type: CLOSE_MODAL_WIDGET,
});

export const closeModalReconnect = () => ({
  type: CLOSE_MODAL_RECONNECT,
});

export const closeModalUseHere = () => ({
  type: CLOSE_MODAL_USE_HERE,
});

export const closeModalConflit = () => ({
  type: CLOSE_MODAL_CONFLIT,
});

export const closeModalDeviceNotConnected = () => ({
  type: CLOSE_MODAL_DEVICE_NOT_CONNECTED,
});

export const closeModalConflitNewDeviceConnected = () => ({
  type: CLOSE_MODAL_CONFLIT_NEW_DEVICE_CONNECTED,
});

export const closeModalInfo = () => ({
  type: CLOSE_MODAL_INFO,
});

export const closeModalTestMode = () => ({
  type: CLOSE_MODAL_TEST_MODE,
});

export const finishModalTestMode = () => ({
  type: FINISH_MODAL_TEST_MODE,
});

export const closeLoadingAnexo = () => ({
  type: CLOSE_LOADING_ANEXO,
});

export const closeModalLoadAllMessages = () => ({
  type: CLOSE_MODAL_LOAD_ALL_MESSAGES,
});

export const setconversationNameInOpenChat = (conversationName) => ({
  type: SET_CONVERSATION_NAME_IN_OPEN_CHAT,
  payload: conversationName,
});

export const openModalPreviewFile = (file) => ({
  type: OPEN_MODAL_PREVIEW_FILE,
  payload: file,
});

export const closeModalPreviewFile = () => ({
  type: CLOSE_MODAL_PREVIEW_FILE,
});

export const closeModalEndConversation = () => ({
  type: CLOSE_MODAL_END_CONVERSATION,
});

export const closeModalDropzone = () => ({
  type: CLOSE_MODAL_DROPZONE,
});

export const closeModalEmailEditor = () => ({
  type: CLOSE_MODAL_EMAIL_EDITOR,
});

export const closeModalEndConversationBot = () => ({
  type: CLOSE_MODAL_END_CONVERSATION_BOT,
});

export const closeModalDeleteMessege = () => ({
  type: CLOSE_MODAL_DELETE_MESSEGE,
});

export const setConnectionWsOpen = () => ({
  type: CONNECTION_WS_OPEN,
});

export const setConnectionWsClosed = () => ({
  type: CONNECTION_WS_CLOSED,
});

export const setHeightChatConversationPx = (value) => ({
  type: SET_HEIGHT_CHAT_CONVERSATION_PX,
  payload: value,
});

export const setHas24hMessage = (bool) => ({
  type: HAS_24H_MESSENGER,
  payload: bool,
});

export const setMessengerType = (messengerType) => ({
  type: MESSENGER_TYPE,
  payload: messengerType,
});

export const addFilter = (filter_type, value, name = "") => ({
  type: ADD_FILTER,
  payload: {
    filter_type,
    value,
    name,
  },
});

export const updateFilter = (filter_type, value, active) => ({
  type: UPDATE_FILTER,
  payload: { filter_type, value, active },
});

export const clearFilter = () => ({
  type: CLEAR_FILTER,
});

export const addQueryFilter = (query) => ({
  type: ADD_QUERY_FILTER,
  payload: query,
});

export const setFiltering = (bool) => ({
  type: FILTERING,
  payload: bool,
});

export const setPagination = (data) => ({
  type: ADD_PAGINATION,
  payload: data,
});

export const setPaginationsTabs = (name, data) => ({
  type: ADD_PAGINATION_TABS,
  payload: { name, data },
});

export const setEnableNotification = (bool) => ({
  type: ENABLE_NOTIFICATION,
  payload: bool,
});

export const setAlertMessage = (messege, type, options) => ({
  type: SET_ALERT_MESSAGE,
  payload: {
    messege,
    type,
    options,
  },
});

export const scrollToBottom = (bool) => ({
  type: SCROLL_TO_BOTTOM,
  payload: bool,
});

export const setLightboxImage = (bool) => ({
  type: SET_LIGHTBOX_IMAGE,
  payload: bool,
});

export const setDisconnect = (idIntegracaoDisconnect, idEmpresaDisconnect) => ({
  type: SET_DISCONNECT,
  payload: {
    idIntegracaoDisconnect,
    idEmpresaDisconnect,
  },
});

export const setTemplateType = (data) => ({
  type: SET_TEMPLETE_TYPE,
  payload: data,
});

export const setTemplateMessages = (data, type) => ({
  type: SET_TEMPLETE_MESSAGES,
  payload: { data, type },
});

export const setSelectedTemplateType = (value) => ({
  type: SET_SELECTED_TEMPLETE_TYPE,
  payload: value,
});

export const setChartsCarfsTop = (value) => ({
  type: SET_CHARTS_CARDS_TOP,
  payload: value,
});

export const setChartFonte = (value) => ({
  type: SET_CHART_FONTE,
  payload: value,
});

export const setChartGraph = (value) => ({
  type: SET_CHART_GRAPH,
  payload: value,
});

export const setTableDesempenho = (value) => ({
  type: SET_TABLE_DESEMPENHO,
  payload: value,
});

export const getReportHistory = (page = 1) => ({
  type: REQUEST_REPORT_HISTORY,
  payload: { page },
});

export const setReportHistory = (value) => ({
  type: SET_REPORT_HISTORY,
  payload: value,
});

export const setReportDate = (value) => ({
  type: SET_REPORT_DATE,
  payload: value,
});

export const setReportDateInit = (value) => ({
  type: SET_REPORT_DATE_INIT,
  payload: value,
});

export const setReportDateEnded = (value) => ({
  type: SET_REPORT_DATE_ENDEND,
  payload: value,
});

export const setStartSocket = (start) => ({
  type: SET_REPORT_WEBSOCKET_START,
  payload: start,
});

export const setModalSyncConversations = (bool) => ({
  type: SET_MODAL_SYNC_CONVERSATIONS,
  payload: bool,
});

export const setActiveateBatchAction = (bool) => ({
  type: ACTIVEATE_BATCH_ACTION,
  payload: bool,
});

export const setCentralAlert = (open, message = "") => ({
  type: SET_CENTRAL_ALERT,
  payload: { open, message },
});
