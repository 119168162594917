import React from "react";
import "react-tabs/style/react-tabs.css";
import {
  Button,
  ButtonGroup,
  Container,
  Row,
  Col,
  Badge,
  UncontrolledTooltip,
  Alert,
} from "reactstrap";

import { connect } from "react-redux";

function CentralAlert(props) {
  return (
    <>
      {props.centralAlert.open && (
        <div
          style={{
            display: "flex",
            backgroundColor: "#000",

            marginBottom: 0,
            padding: 1,
            justifyContent: "center",
            alignItems: "center",
            height: 56,
          }}
        >
          <p className="mb-0" style={{ color: "#fff" }}>
            Central de Atendimento foi atualizada, por favor{" "}
            <a
              className="alert-link"
              href={window.location.href}
              rel="noreferrer"
              style={{ color: "#fff" }}
            >
              <strong>clique aqui</strong>
            </a>{" "}
            para atualizar ou aperto a tecla <strong>F5</strong>.
          </p>
          {props.centralAlert.message && <p>{props.centralAlert.message}</p>}
        </div>
      )}
    </>
  );
}

const mapStatetoProps = (state) => {
  const { centralAlert } = state.Layout;

  return {
    centralAlert,
  };
};

export default connect(mapStatetoProps, {})(CentralAlert);
