import produce from "immer";
import { SET_SMS_CONFIG } from "./constants";

const INIT_STATE = {
  smsProvider: [],
  smsMessages: [],
};

const Sms = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SMS_CONFIG:
      return produce(state, (draft) => {
        draft.smsProvider = action.payload ?? [];
      });
    default:
      return state;
  }
};

export default Sms;
