import { all, put, takeEvery, call, fork } from "redux-saga/effects";
import { api, apiCRM, apiNova, setCRMAuthorization } from "../../services/api";

import {
  REQUEST_CONFIG,
  REQUEST_CONFIG_SUCESS,
  REQUEST_CONFIG_ERROR,
  LOAD_TEMPLATE_MESSAGE,
} from "./constants";

import {
  setConfigCentral,
  requestProcessos,
  setTemplateType,
  setTemplateMessages,
  setSelectedTemplateType,
  setTemplateMessagesSucess,
  loadTemplateMessage,
  requestSmsConfig,
  countWaitingRoomChatsInDb,
} from "../actions";
import store from "../store";
import { setCentralLoaded, setStatusWs } from "../layout/actions";
import useInterval from "../../utils/useInteval";
import Bugsnag from "@bugsnag/js";

function* loadConfigCentral({ payload: { idEmpresa, reconnect } }) {
  const state = store.getState();

  const user = state.Auth.user;

  try {
    yield call(setCRMAuthorization, user.access_key);
    yield put(setStatusWs("Carregando configuração..."));

    const enterprise = yield call(apiNova.get, `/user/enterprise`);

    yield put(requestSmsConfig())

    const teams = yield call(apiNova.get, `/user/teams`);

    const resConfig = yield call(api.get, `/central/config/${idEmpresa}`);
    const config = resConfig.data;

    const resTags = yield call(api.get, `/db/crm/tags?id_empresa=${idEmpresa}`);
    const tags = resTags.data;

    yield put(setStatusWs("Carregando atendentes..."));
    const resAttendants = yield call(
      apiCRM.get,
      `/componente/pegar-responsavel-por-empresa?id_empresa=${idEmpresa}`
    );
    const attendants = resAttendants.data;

    const resAnexos = yield call(
      apiCRM.get,
      "/arquivo/listar?pageAtual=1&filtro_arquivo=&filtro_periodo1=&filtro_periodo2=&filtro_responsavel=0&rp=100&tudo=1"
    );

    const attachments = resAnexos.data.dados.map((item) => {
      return {
        url: item.url,
        label: item.nome,
        value: parseInt(item.id_oportunidade_arquivo),
      };
    });

    const resTaskType = yield call(apiCRM.post, `/relatorios/get-historicos`);
    const taskType = resTaskType.data;

    const resDialogFlow = yield call(
      apiCRM.post,
      `/dialog-flow/get-config-by-enterprise`,
      { id_empresa: idEmpresa }
    );
    const dialogFlow = resDialogFlow.data;

    const resFunnels = yield call(
      apiCRM.get,
      `/componente/get-funnel-stage?id_empresa=${idEmpresa}`
    );
    const funnels = resFunnels.data;

    const resStatus = yield call(apiNova.get, `/calendar/status`);
    const status = resStatus.data.data;

    yield put(setStatusWs("Carregando produtos..."));
    const resProducts = yield call(apiNova.get, `/calendar/products`);
    const products = resProducts.data.data;

    const resEmployees = yield call(apiNova.get, `/calendar/employees`);
    const employees = resEmployees.data.data;

    const resConfigSchedule = yield call(apiNova.get, `/calendar/settings`);
    const configSchedule = resConfigSchedule.data.data;

    const resVoip = yield call(
      apiNova.get,
      `/voip/enterprise/${idEmpresa}?mode=restrict`
    );
    const voip = resVoip.data.data;

    if (user.id_empresa === 20946) {
      setInterval(() => {
        console.log("countWaitingRoomChatsInDb");
        store.dispatch(countWaitingRoomChatsInDb());
      }, 15000);
    }

    yield put(
      setConfigCentral({
        config,
        configSchedule,
        tags,
        attendants,
        teams: teams.data.data,
        enterprise: enterprise.data.data,
        attachments,
        taskType,
        funnels,
        status,
        products,
        employees,
        dialogFlow,
        voip,
      })
    );

    yield put(requestProcessos(idEmpresa, reconnect));
    yield put(loadTemplateMessage({ type: "W", active: 1 }));
    yield put(setCentralLoaded(true));
  } catch (error) {
    Bugsnag.notify(error)
    console.log("Error loadConfigCentral: ", error);
  }
}

function* requestLoadTemplateMessage({
  payload: { search, active, type, template_type },
}) {
  const state = store.getState();

  const { id_empresa } = state.Auth;
  const { attachments } = state.Config;

  try {
    const bodyStandardMessage = {
      params: {
        template_type,
        id_empresa,
        search,
        active,
        type,
      },
    };

    const resStandardMessages = yield call(
      api.get,
      `/central/template-messages`,
      bodyStandardMessage
    );

    const standardMessages = resStandardMessages.data
      .filter((item) => item.template_type === "meets")
      .map((item) => {
        const _anexos = attachments.filter(
          (anexo) => anexo.value === item.anexo
        );

        return {
          value: item.mensagem_texto,
          label: item.titulo,
          anexos: _anexos,
          template_type: item.template_type,
          template_data: item.template_data,
          integration: item.integration,
        };
      });

    const templateMessages = resStandardMessages.data.map((item) => {
      const _anexos = attachments.filter((anexo) => anexo.value === item.anexo);

      return {
        ...item,
        anexos: _anexos,
      };
    });

    const defaltTemplateType = {
      label: "Mensagem padrão",
      value: "template_padrao",
    };
    yield put(setTemplateMessages(standardMessages, "template_padrao"));
    yield put(setTemplateMessagesSucess(templateMessages));
    yield put(setTemplateType(defaltTemplateType));
    yield put(setSelectedTemplateType(defaltTemplateType));
  } catch (error) {
    Bugsnag.notify(error)
  }
}

export function* watchLoadConfig() {
  yield takeEvery(REQUEST_CONFIG, loadConfigCentral);
}

export function* watchLoadTemplateMessage() {
  yield takeEvery(LOAD_TEMPLATE_MESSAGE, requestLoadTemplateMessage);
}

function* configSaga() {
  yield all([fork(watchLoadConfig), fork(watchLoadTemplateMessage)]);
}

export default configSaga;
