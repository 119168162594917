import {
  REQUEST_TEMPLATES_WHATSAPP,
  REQUEST_TEMPLATES_WHATSAPP_SUCESS,
  CHECK_USER_ACTIVE,
} from "./constants";

export const requestTemplatesWhatasapp = ({
  identifier,
  description,
  partner_integration,
}) => ({
  type: REQUEST_TEMPLATES_WHATSAPP,
  payload: { identifier, description, partner_integration },
});

export const requestTemplatesWhatasappSucess = (identifier, data) => ({
  type: REQUEST_TEMPLATES_WHATSAPP_SUCESS,
  payload: { identifier, data },
});

export const checkUserActive = () => ({
  type: CHECK_USER_ACTIVE,
});
